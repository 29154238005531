/* eslint-disable react/prop-types */

import React, { useState } from "react";

export const Context = React.createContext();

export const LoginContext = (props) => {
  const [Login, setLogin] = useState(() => {
    if (localStorage.getItem("TypeLogin")) {
      return localStorage.getItem("TypeLogin");
    } else {
      return "escola";
    }
  });
  function setTypeLogin() {
    let typeLogin = localStorage.getItem("TypeLogin");
    if (typeLogin == null || undefined) {
      localStorage.setItem("TypeLogin", "escola");
    }
  }
  let [auth, setAuth] = useState(() =>
    localStorage.getItem("auth") ? Boolean(localStorage.getItem("auth")) : false
  );
  let [dataSchool, setDataSchool] = useState(() =>
    localStorage.getItem("dataUserSchool")
      ? JSON.parse(Array(localStorage.getItem("dataUserSchool")))
      : null
  );
  let [dataServerLogin, setDataServerLogin] = useState(() =>
    localStorage.getItem("dataServerLogin")
      ? JSON.parse(Array(localStorage.getItem("dataServerLogin")))
      : undefined
  );
  let [isAdmin, setAdmin] = useState(() =>
    dataServerLogin ? dataServerLogin.data.userLogin.isAdmin : undefined
  );
  return (
    <Context.Provider
      value={{
        Login,
        setLogin,
        setTypeLogin,
        auth,
        setAuth,
        dataSchool,
        setDataSchool,
        dataServerLogin,
        setDataServerLogin,
        isAdmin,
        setAdmin,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
