import styled from "styled-components";
import { Button as ButtonPrime } from 'primereact/button'

const Button = styled(ButtonPrime)`
  background-color: ${(props) => props.bgcolor};
  color: ${(props) => props.color};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: 1.1rem;
  border-radius: ${(props) => props.borderRadius};
  border: none;
  padding: ${(props) => props.padding};
  box-shadow: ${(props) => props.shadow};
  @media (max-height: 900px) {
    height: ${(props) => props.heightResp};
    width: ${(props) => props.widthResp};
    font-size: 1rem;
  }
  &:hover.p-button.p-component  {
    background-color: ${(props) => props.bgcolorhover};
    color: ${(props) => props.colorhover};
    transition: 100ms ease-in-out;
    box-shadow: ${(props) => props.hoverShadow};
  }
`;

export default Button;
