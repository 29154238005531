import 'primereact/resources/primereact.min.css';

import React from "react";

import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { Router } from "react-router-dom";
import history from "./history";
import Routes from "./routes/routes";

import { ThemeProvider } from "styled-components";
import usePeristedState from "./utils/usePersistedState";

import light from "./styles/themes/light";
import dark from "./styles/themes/dark";

import { LoginContext } from "./context/loginContext";
import { ContextPage } from "./context/pageContext";

const engine = new Styletron();

function App() {
  const [theme, setTheme] = usePeristedState("theme", light);

  const toggleTheme = React.useCallback((title, font) => {
    setTheme(
      title === "dark"
        ? { ...dark, font: { ...dark.font, size: font } }
        : { ...light, font: { ...light.font, size: font } }
    );
  }, []);

  return (
    <StyletronProvider value={engine}>
      <ThemeProvider theme={theme}>
        <BaseProvider theme={LightTheme}>
          <LoginContext>
            <ContextPage>
              <Router history={history}>
                <Routes toggleTheme={toggleTheme} />
              </Router>
            </ContextPage>
          </LoginContext>
        </BaseProvider>
      </ThemeProvider>
    </StyletronProvider>
  );
}

export default App;
