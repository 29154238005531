// import React from 'react'
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.templateColumns};
  grid-template-rows: ${(props) => props.templateRows};
  place-items: ${(props) => props.placeItens};
  align-items: ${(props) => props.alignItens};
  justify-items: ${(props) => props.justifyItens};
  place-self: ${(props) => props.self};
  justify-self: ${(props) => props.justify};
  align-self: ${(props) => props.align};
  padding: ${(props) => props.padding};
  min-height: ${(props) => props.minHeight};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  gap: ${(props) => props.gap};
  font-size: ${(props) => props.fontSize};
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  border-bottom: ${(props) => props.borderB};
  background-color: ${(props) => props.bgcolor};
  transition: 200ms ease-in-out;
  box-shadow: ${(props) =>
    props.shadow ? `-1px 0px 4px rgba(0, 0, 0, 0.92)` : null};
  -webkit-box-shadow: ${(props) =>
    props.shadow ? `-1px 0px 4px rgba(0, 0, 0, 0.92)` : null};
  z-index: ${(props) => props.zIndex};
  @media (max-height: 800px) {
    font-size: ${(props) => props.fontSizeResp};
    height: ${(props) => props.heightResp};
  }
  .desactive {
    height: 0px;
    opacity: 0;
    display: none;
  }
  .active: {
    height: max-height;
  }
  &:hover {
    transform: ${(props) => (props.transY ? "translateY(-2px)" : null)};
  }
`;

export default Container;
