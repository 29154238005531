export default class get_api {
  async getOcurrences() {
    const typeLogin = localStorage.getItem("TypeLogin")
      ? localStorage.getItem("TypeLogin")
      : null;
    const userId =
      typeLogin == "escola"
        ? JSON.parse(localStorage.getItem("dataUserSchool")).data.userLogin.id
        : null;
    return await fetch(
      `${process.env.REACT_APP_API_URL}/occurrence?Event&Id_school${
        typeLogin == "escola" ? `=${userId}` : ""
      }&limit&Date_initial&Date_final&page`
    )
      .then((res) => res.json())
      .then((d) => d.data);
  }
  async getSchools() {
    return await fetch(`${process.env.REACT_APP_API_URL}/school?NameSchool`)
      .then((res) => res.json())
      .then((d) => d.data);
  }
  async getPartner() {
    return await fetch(`${process.env.REACT_APP_API_URL}/partner?Name`)
      .then((res) => res.json())
      .then((d) => d.data);
  }
}
