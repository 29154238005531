export default {
  title: "light",

  font: {
    family: "Roboto",
    size: "14",
  },

  colors: {
    primary: "#36B37E",
    secundary: "#2684FF",

    background: "#F8F9FC",
    container: "#FFF",
    border: "#CCC",
    stripedRow: "#F5F9FF",
    row: "#FFF",
    text: "#6E7781",
    icon: "#36B37E",
    btn_color: "#E0E0E0",
    icon_visu: "#2684FF",
    card: "#f5f5f5",
  },
};
