/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from "react";
import { Context } from "../context/loginContext";

import api from "../services/api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { setAuth } = React.useContext(Context);
  const [loading, setLoading] = useState(false);
  const [dataLogin, setDataLogin] = useState(() => {
    const token = localStorage.getItem("@project:token");
    const user = localStorage.getItem("@project:user");

    if (token && user) {
      return { token, user: JSON.parse(user) };
    }

    return undefined;
  });

  let signed;
  let usersOn = localStorage.getItem("usersOn")
    ? +localStorage.getItem("usersOn")
    : null;

  dataLogin
    ? (signed = useMemo(
        () => !!dataLogin.token && !!dataLogin.user,
        [dataLogin]
      ))
    : null;

  const signIn = useCallback(async (data, typeLogin) => {
    try {
      setLoading(true);
      let { email_login, cpf_login, password } = data;
      // let response
      if (typeLogin === "escola") {
        let response = await api
          .post(`/auth/signinschool`, {
            email_login,
            password,
          })
          .then((request) => {
            setAuth(true);
            window.location.href = "/dashboard";
          });
      }
      if (typeLogin === "usuarios") {
       let response = await api
          .post(`/auth/signinuser`, {
            cpf_login,
            password,
          })
          .then((request) => {
            setAuth(true);
            window.location.href = "/dashboard";
          });
      }

      const { auth, user } = response.data;

      localStorage.setItem("@project:token", auth);
      localStorage.setItem("@project:user", JSON.stringify(user));

      // // @ts-ignore
      api.defaults.headers.authorization = `Bearer ${auth.token}`;
      setDataLogin({ token: auth.token, user });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      localStorage.removeItem("@project:token");
      localStorage.removeItem("@project:user");
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@project:token");
    localStorage.removeItem("@project:user");

    setDataLogin(AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loading,
        signed,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an authProvider");
  }

  return context;
}
