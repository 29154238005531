/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import DataTableRefact from "../components/DataTableRefact";
import InputStyled from "../components/InputDeBusca";
import Container from "../components/Container50";
import TextStyledComp from "./TextStyled";
import Pagination from "../components/Pagination";
import Text from "./Text";
import ModalComp from "./Modal";
import ModalServer from "./ModalServer";
import ModalDelete from "./ModalDelete";
import Button from "./Button";

import { Form } from "@unform/web";
import { Input, InputMask, Select } from "./Form/index";
import styled from "styled-components";

import { Context } from "../context/loginContext";
import { PageContext } from "../context/pageContext";
import api from "../services/api";
import axios from "axios";
import { toast } from "react-toastify";
import { string, object, ValidationError } from "yup";
import Loading from "./animations/loading";
import ModalReset from "./ModalReset";

const Td = styled.td`
  color: #222;
  padding: 0.8rem;
  font-size: 0.85rem;
  @media (max-height: 800px) {
    font-size: 0.7rem;
    padding: 0.5rem;
  }
`;
const Tr = styled.tr`
  &:nth-child(2n + 1) {
    background-color: #f5f9ff;
  }
  &:hover {
    background-color: rgba(38, 132, 255, 0.2);
  }
  &:hover > td {
    color: #333;
  }
`;
const StyleInputMask = {
  width: "98%",
  border: "1px #CCC solid",
  borderRadius: "4px",
  height: "36px",
  paddingLeft: "1%",
};

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    zIndex: "999",
    width: window.innerWidth >= 1780 ? "100px" : "80px",
    overflow: "none",
  }),
  menuList: (provided, state) => ({
    ...provided,
    overflow: "none",
  }),
};

const ButtonModal = styled.button`
  display: ${(props) => props.display};
  color: ${(props) => props.color};
  place-items: center;
  background-color: ${(props) => props.bgcolor};
  font-size: ${(props) => props.fontSize};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  justify-self: ${(props) => props.justify};
  border-radius: 3px;
  margin: 5px 10px;
  transition: 100ms ease-in-out;
  &:hover {
    color: ${(props) => props.colorhover};
    background-color: ${(props) => props.bgcolorhover};
  }
  @media (max-height: 800px) {
    width: ${(props) => props.widthResp};
    height: ${(props) => props.heightResp};
    font-size: ${(props) => props.fontSizeResp};
  }
`;

const Servidores = () => {
  let { atualiza, loading, setLoading } = useContext(PageContext);
  const [btnUpdateTable, setBtnUpdate] = React.useState(false);
  const [changePassword, setChangePassword] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState(null);

  let [data, setData] = React.useState((data) =>
    localStorage.getItem("dataServer")
      ? JSON.parse([localStorage.getItem("dataServer")])
      : data
      ? data
      : []
  );
  let [dataServer, setDataServer] = React.useState(undefined);
  const [openRegister, setOpenRegister] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState();
  const [paginationData, setPaginationData] = React.useState({
    currentPage: 1,
    lastPage: 1,
  });
  const [quantityRegisters, setQuantity] = React.useState({
    value: 10,
    label: 10,
  });
  const formRef = React.useRef(null);

  async function handlerSubmit(data) {
    let inputsValue = data;

    async function submitData() {
      setOpenRegister(false);

      let data = JSON.stringify(inputsValue);

      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let reqOptions = {
        url: `${process.env.REACT_APP_API_URL}/userServer`,
        method: "POST",
        data: data,
        headers: headersList,
      };

      let response;
      await toast
        .promise(axios.request(reqOptions), {
          pending: "Cadastrando Servidor",
          success: "Cadastrado com sucesso",
          error: {
            render({ data }) {
              return data.response.data.includes("<")
                ? `${data.message}`
                : `${data.response.data}`;
            },
          },
        })
        .then((responseRequest) => {
          setLoading(false);
          response = responseRequest;
        })
        .catch(() => setLoading(false));
      insertDataRequest();
    }

    try {
      const schema = object({
        name: string()
          .min(2, "No minimo 3 caracteres")
          .required("Campo Obrigatorio"),
        registration: string().required("Preencha com o número de matricula"),
        email: string()
          .email("Digite um email valido")
          .min(4, "No minimo 4 caracteres")
          .required("É obrigatorio o email"),
        telephone: string().required("Digite o Telefone"),
        cpf: string()
          .min(14, "Digite um cpf valido")
          .required("O CPF é obrigatorio"),
        password: string().required("Campo obrigatorio"),
      });

      await schema
        .validate(data)
        .then(() => submitData())
        .catch(() => null);
      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof ValidationError) {
        const errorMensages = {};

        err.inner.forEach((error) => {
          errorMensages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMensages);
      }
    }
  }

  async function insertDataRequest() {
    setLoading(true);
    setData();
    let response;

    let queryString = `?Name${search ? `=${search}` : ""}&limit=${
      quantityRegisters.value
    }&page=${page}`;

    search
      ? (response = await api
          .get(`${process.env.REACT_APP_API_URL}/server${queryString}`, {
            headers: "",
          })
          .then((request) => {
            localStorage.setItem(
              "dataPartner",
              JSON.stringify(request.data.data)
            );
            paginationData.currentPage = request.data.currentPage;
            paginationData.lastPage = request.data.lastPage;
            setData(request.data.data);
          })
          .catch((error) => {
            setData([]);
          }))
      : (response = await api
          .get(
            `${process.env.REACT_APP_API_URL}/server?page=${page}&limit=${quantityRegisters.value}`,
            {
              headers: "",
            }
          )
          .then((request) => {
            localStorage.setItem(
              "dataServer",
              JSON.stringify(request.data.data)
            );
            paginationData.currentPage = request.data.currentPage;
            paginationData.lastPage = request.data.lastPage;
            setData(request.data.data);
          })
          .catch((error) => {
            setData([]);
          }));
    setLoading(false);
  }

  React.useEffect(() => {
    insertDataRequest();
  }, [page, search, quantityRegisters]);

  return (
    <Container
      zIndex={"0"}
      width={"100%"}
      height={"100%"}
      position={"relative"}
      bgcolor={"#f7f7f7"}
    >
      <ModalReset
        setChangePassword={setChangePassword}
        changePassword={changePassword}
        newPassword={newPassword}
      />
      <Container
        justifyItens={"center"}
        height={"100%"}
        templateRows={"100px 1fr"}
        bgcolor={"#F7f7f7"}
      >
        <Container width={"100%"}>
          <Container
            style={{ boxShadow: "5px 7px 7px 2px rgba(0,0,0,0.07)" }}
            width={"90%"}
            height={"86%"}
            justify={"center"}
            align={"center"}
            bgcolor={"#fff"}
            alignItems={"start"}
          >
            <Container height={"70px"} align={"center"}>
              <Container
                fontSizeResp={"0.8rem"}
                fontSize={"0.9rem"}
                // height={"80pc"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "1.5rem",
                  position: "relative",
                }}
              >
                <TextStyledComp fontSize={".9rem"} fontSizeResp={".7rem"}>
                  Mostrar
                </TextStyledComp>
                <Form style={{ display: "flex" }}>
                  <Select
                    name={"limit"}
                    styles={customStyles}
                    options={[
                      { value: 10, label: 10 },
                      { value: 20, label: 20 },
                      { value: 30, label: 30 },
                      { value: 40, label: 40 },
                    ]}
                    placeholder={quantityRegisters.value}
                    onChange={(e) => {
                      setPage(1);
                      setQuantity(e);
                    }}
                  />
                </Form>
                <TextStyledComp fontSize={".9rem"} fontSizeResp={".7rem"}>
                  Registros
                </TextStyledComp>
                <div
                  style={{
                    display: "flex",
                    width: "500px",
                    fontSize: "1.3rem",
                  }}
                >
                  <TextStyledComp
                    tag="i"
                    fontSizeResp={"15px"}
                    style={{
                      translate: "3rem",
                      color: "#9E9E9E",
                      alignSelf: "center",
                    }}
                    className="fa-solid fa-magnifying-glass"
                  ></TextStyledComp>
                  <InputStyled
                    fontSizeResp={"12px"}
                    placeholder="Nome do Servidor"
                    onKeyUp={(e) => {
                      if (e.key == "Enter") {
                        setPage(1);
                        setSearch(e.target.value);
                      }
                      if (e.target.value.length === 0) {
                        setPage(1);
                        setSearch(e.target.value);
                      }
                    }}
                  />
                </div>
                <div style={{ position: "absolute", right: "20px" }}>
                  <Button
                    bgcolor="#2684FF"
                    padding={
                      window.innerWidth >= 1780 || window.innerHeight >= 800
                        ? ".6rem"
                        : "0.3rem"
                    }
                    style={{ borderRadius: "5px", marginRight: "1rem" }}
                    shadow="2px 2px 5px rgba(0,0,0,0.26)"
                    hoverShadow="none"
                    onMouseLeave={() => setBtnUpdate(false)}
                    onClick={() => {
                      setPage(1);
                      setBtnUpdate(true);
                      insertDataRequest();
                    }}
                  >
                    <i
                      style={{
                        color: "#FFF",
                        fontSize: "1.3rem",
                        cursor: "pointer",
                        transition: "500ms ease-in-out",
                        WebkitTransform: `rotate(${
                          btnUpdateTable ? "360deg" : "0deg"
                        })`,
                      }}
                      className="fa-solid fa-arrow-rotate-right"
                    ></i>
                  </Button>
                  <ModalComp
                    fontSize="15px"
                    bgColor="#2684FF"
                    color="#FFF"
                    padding=".7rem"
                    open={openRegister}
                    setOpen={setOpenRegister}
                    placeholderBtn="Cadastrar Servidor"
                  >
                    <TextStyledComp borderB="3.5px #36B37E solid" tag="h3">
                      <Container
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                          cursor: "pointer",
                        }}
                        position="fixed"
                        right="1rem"
                        top=".5rem"
                        onClick={() => setOpenRegister(false)}
                      >
                        X
                      </Container>
                      Cadastro Servidor
                    </TextStyledComp>
                    <Container>
                      <Form ref={formRef} onSubmit={handlerSubmit}>
                        <Container gap="15px">
                          <div>
                            <Text
                              tag="label"
                              style={{ fontSize: "1rem", fontWeight: "600" }}
                            >
                              Nome
                            </Text>
                            <Input
                              height="40px"
                              fontSize="15px"
                              name="name"
                              placeholder="Nome do servidor"
                            />
                          </div>
                          <div>
                            <TextStyledComp tag="label" fontWeight="bold">
                              N° Matrícula
                            </TextStyledComp>
                            <Input
                              height="40px"
                              fontSize="15px"
                              name="registration"
                              placeholder="Digite o número de matricula"
                            />
                          </div>
                          <div>
                            <TextStyledComp tag="label" fontWeight="bold">
                              Email
                            </TextStyledComp>
                            <Input
                              height="40px"
                              fontSize="15px"
                              name="email"
                              placeholder="EmailDoServidor@outlook.com"
                            />
                          </div>
                          <div>
                            <TextStyledComp tag="label" fontWeight="bold">
                              Telefone
                            </TextStyledComp>
                            <InputMask
                              style={{
                                height: "40px",
                                width: "97%",
                                paddingLeft: ".5rem",
                                border: "1px #CCC solid",
                                borderRadius: "4px",
                              }}
                              name="telephone"
                              mask="(99) 9999-9999"
                              placeholder="(00) 0000-0000"
                            />
                          </div>
                          <div>
                            <TextStyledComp tag="label" fontWeight="bold">
                              Número de CPF
                            </TextStyledComp>
                            <InputMask
                              style={{
                                height: "40px",
                                width: "97%",
                                paddingLeft: ".5rem",
                                border: "1px #CCC solid",
                                borderRadius: "4px",
                              }}
                              name="cpf"
                              mask="999.999.999-99"
                              placeholder="000.000.000-00"
                            />
                          </div>
                          <Container>
                            <TextStyledComp tag="label" fontWeight="bold">
                              Crie uma senha
                            </TextStyledComp>
                            <Container position="relative">
                              <Input
                                type="text"
                                textSize="15px"
                                height="40px"
                                name="password"
                                placeholder="Digite sua senha"
                              />
                            </Container>
                          </Container>
                        </Container>
                        <Container templateColumns="1fr 200px" mt="1rem">
                          <ButtonModal
                            justify={"end"}
                            bgcolor={"transparent"}
                            color={"#2684FF"}
                            fontSize={"1rem"}
                            colorhover={"#b33539"}
                            onClick={() => setOpenRegister(false)}
                          >
                            Voltar
                          </ButtonModal>
                          <ButtonModal
                            type="submit"
                            display={"grid"}
                            justify={"start"}
                            bgcolor={"#2684FF"}
                            color={"white"}
                            height={"35px"}
                            heightResp={"30px"}
                            width={"92%"}
                            fontSize={"1rem"}
                            bgcolorhover={"#36B37E"}
                          >
                            Cadastrar
                          </ButtonModal>
                        </Container>
                      </Form>
                    </Container>
                  </ModalComp>
                </div>
              </Container>
            </Container>
            <Container style={{ boxShadow: "5px 7px 7px 2px rgba(0,0,0,0.1)" }}>
              <DataTableRefact
                category={["Nome", "Matrícula", "Email", "Opções"]}
              >
                {loading && (
                  <Container
                    position={"absolute"}
                    width={"98%"}
                    height={"50vh"}
                    placeItens={"center"}
                    bgcolor={"transparent"}
                  >
                    <Loading
                      width={"50px"}
                      height={"50px"}
                      borderSize={"6px"}
                      widthResp={"30px"}
                      heightResp={"30px"}
                    />
                  </Container>
                )}
                {!loading &&
                  Array.isArray(data) &&
                  data.map((item, index) => {
                    return (
                      <Tr key={item + index}>
                        <Td>{item.name}</Td>

                        <Td>{item.registration}</Td>

                        <Td>{item.email}</Td>
                        <Td
                          style={{
                            display: "flex",
                            gap: "8px",
                            placeContent: "center",
                          }}
                        >
                          <ModalServer
                            fontSize="20px"
                            bgColor="transparent"
                            color="#4d6c94"
                            setChangePassword={setChangePassword}
                            setNewPassword={setNewPassword}
                            btnHoverBg="transparent"
                            btnHoverColor="#2684FF"
                            insertData={insertDataRequest}
                            padding=".2rem"
                            placeholderBtn={
                              <i className="fa-solid fa-pen-to-square"></i>
                            }
                            item={item}
                          />
                          <ModalDelete
                            fontSize="20px"
                            bgColor="transparent"
                            btnHoverBg="transparent"
                            intentionDelete="Servidor"
                            insertData={insertDataRequest}
                            btnHoverColor="#e6394f"
                            color="#8f4d55"
                            rota="server"
                            placeholderBtn={
                              <i className="fa-solid fa-trash"></i>
                            }
                            item={item}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
              </DataTableRefact>
              <Container bgcolor={"#fff"}>
                <Pagination
                  lastPage={paginationData.lastPage}
                  setPage={setPage}
                  page={paginationData.currentPage}
                />
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default Servidores;
