/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import styled from "styled-components";
import style from './Form.module.css'

const IconInput = styled.span`
  border: none;
  background-color: transparent;
`

const InputTextStyle = styled(InputText)`
  font-size: ${props => props.fontSize};
  border: none;
  &:hover.p-inputtext:enabled {
    border: none
  }
  &:focus.p-inputtext:enabled {
    box-shadow: none;
  }
  height: ${(props) => props.height};
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  display: ${props => props.display};
  width: 97.5%;
  border-radius: 8px;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
`;

const InputMaskStyle = styled(InputMaskComp)`
  color: ${props => props.color}
  font-size: ${props => props.fontSize};
  border: none;
  &:hover.p-inputtext:enabled {
    border: none
  }
  &:focus.p-inputtext:enabled {
    box-shadow: none;
  }
`

export const Input = ({ name, icon, iconChange, type, conditionIcon, handleViewPass, rightIcon, hide, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <div style={{display: `${hide ? "none" : "block"}`}}>
    <div className={`p-inputgroup ${style["p-inputgroup"]} ${error ? style.invalid : null}`}>
      {icon && !rightIcon &&
      <IconInput className="p-inputgroup-addon"><i onClick={handleViewPass} className={()=> conditionIcon ? icon : iconChange} /></IconInput>
      }
      <InputTextStyle
      ref={inputRef}
      onClick={(e) => {
        clearError()
      }}
      onFocus={(e) => {
        e.currentTarget.parentElement.classList.add(style.active)
      }}
      onBlur={(e) => {
        e.currentTarget.parentElement.classList.remove(style.active)
      }}
      fontSize="16px"
      type={type}
      value={defaultValue}
      {...rest}
      />
      {icon && rightIcon &&
      <IconInput className="p-inputgroup-addon"><i onClick={handleViewPass} className={`${conditionIcon ? iconChange : icon}`} /></IconInput>
      }
    </div>
      {error && (
        <span style={{ color: "#ef3a5d", display: "block", fontSize: "15px" }}>
          {error}
        </span>
      )}
    </div>
    // <div>
    //   <InputStyle
    //     ref={inputRef}
    //     onClick={() => {
    //       clearError();
    //     }}
    //     defaultValue={defaultValue}
    //     {...rest}
    //   />
    //   {error && (
    //     <span style={{ color: "#ef3a5d", display: "block", fontSize: "15px" }}>
    //       {error}
    //     </span>
    //   )}
    // </div>
  );
};

// Select-React with unform

import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from "react-select";
import { getValue } from "@testing-library/user-event/dist/utils";

export function Select({ name, colors, ...rest }) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.getValue()) {
            return [];
          }
          if (ref.getValue().length === 1) {
            return ref.getValue()[0].value;
          } else {
            return ref.getValue().map((option) => option.value);
          }
        }
        if (!ref.getValue()) {
          return "";
        } else {
          return String(ref.getValue().map((option) => option.value));
        }
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <div onClick={() => clearError()}>
      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        onClick={() => clearError()}
        styles={{
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: colors?.text || "#222",
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: colors?.background || "#fff",
            color: colors?.text || "#222",
          }),
          menuList: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: colors?.background || "#fff",
            color: colors?.text || "#222",
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isFocused
              ? colors?.container
              : colors.background,
            color: colors?.text || "#222",
          }),
        }}
        classNamePrefix="react-select"
        {...rest}
      />
      {error && (
        <span style={{ color: "#ef3a5d", display: "block" }}>{error}</span>
      )}
    </div>
  );
}

export function DatePicker({ name, colors, ...rest }) {
  const InputDate = styled.input`
    border: 1px #ccc solid;
    width: 100%;
    height: 35px;
    border-radius: 4px;
    text-align: center;
  `;

  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField(name);

  const [date, setDate] = useState(defaultValue || null);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <div>
      <input
        style={{
          width: "99%",
          border: "1px #CCC solid",
          borderRadius: "4px",
          height: "36px",
          textAlign: "center",
          backgroundColor: colors?.background || "#f7f7f7",
          color: colors?.text || "#222",
        }}
        type="date"
        onClick={(e) => {
          clearError();
          e.target.max = new Date().toISOString().split("T")[0];
        }}
        ref={datepickerRef}
        defaultValue={date}
        min="1899-01-01"
        {...rest}
      />
      {error && (
        <span style={{ color: "#ef3a5d", display: "block" }}>{error}</span>
      )}
    </div>
  );
}

export function TimePicker({ name, colors, ...rest }) {
  const TimePicker = styled.input`
    border: 1px #ccc solid;
    height: 35px;
    width: 100%;
    border-radius: 4px;
    text-align: center;
  `;

  const timepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField(name);

  const [time, setTime] = useState(defaultValue || null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: timepickerRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <div>
      <input
        style={{
          width: "99%",
          border: "1px #CCC solid",
          borderRadius: "4px",
          height: "36px",
          textAlign: "center",
          backgroundColor: colors?.background || "#FFF",
          color: colors?.text || "#222",
        }}
        type="time"
        onClick={(e) => {
          clearError();
        }}
        ref={timepickerRef}
        defaultValue={time}
        // maxLength={5}
        {...rest}
      />
      {error && (
        <span style={{ color: "#ef3a5d", display: "block" }}>{error}</span>
      )}
    </div>
  );
}

import ReactInputMask, { Props as InputProps } from "react-input-mask";
import { InputMask as InputMaskComp }  from "primereact/inputmask"
import { colors } from "@mui/material";
import { InputText } from "primereact/inputtext";

export function InputMask({ name, colors, icon, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, value, error, clearError } =
    useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <div>
    <div className={`p-inputgroup ${style["p-inputgroup"]} ${error ? style.invalid : null}`}>
      {icon &&
      <IconInput className="p-inputgroup-addon"><i className={icon} /></IconInput>
      }
      <InputMaskStyle
      ref={inputRef}
      onClick={(e) => {
        clearError()
        console.log(value)
      }}
      onFocus={(e) => {
        e.currentTarget.parentElement.classList.add(style.active)
      }}
      onBlur={(e) => {
        e.currentTarget.parentElement.classList.remove(style.active)
      }}
      fontSize="16px"
      value={value}
      name={name}
      autoClear={false}
      {...rest}
      />
    </div>
    {error && (
      <span style={{ color: "#ef3a5d", display: "block", fontSize: "15px" }}>
        {error}
      </span>
    )}
    </div>
  );
}

export const Texterea = ({ name, colors, ...rest }) => {
  const textereaRef = useRef(null);
  let { fieldName, registerField, defaultValue, error, clearError } =
    useField(name);

  const Texterea = styled.textarea`
    width: 96.5%;
    border-radius: 5px;
    border: 1px #ccc solid;
    margin-top: 1rem;
    font-size: 0.9rem;
    padding: 0.5rem;
    height: 100px;
    resize: none;
    &:focus {
      border: 2px #2684ff solid;
    }
  `;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textereaRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <div>
      <textarea
        style={{
          width: "97.5%",
          border: "1px #CCC solid",
          borderRadius: "4px",
          height: "100px",
          padding: "1%",
          resize: "none",
          marginTop: "1rem",
          backgroundColor: colors?.background || "transparent",
          color: colors?.text || "transparent",
        }}
        onClick={() => clearError()}
        ref={textereaRef}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && (
        <span style={{ color: "#ef3a5d", display: "block" }}>{error}</span>
      )}
    </div>
  );
};

export const FileInput = ({ name, ...rest }) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "files",
      getValue(ref) {
        return ref.files;
      },
      clearValue(ref) {
        ref.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <input type="file" ref={inputRef} {...rest} />
      {error && (
        <span style={{ color: "#ef3a5d", display: "block" }}>{error}</span>
      )}
    </>
  );
};

import { InputTextarea } from 'primereact/inputtextarea'

export const TextereaStyle = ({ name, colors, ...rest }) => {
  const textereaRef = useRef(null);
  let { fieldName, registerField, defaultValue, error, clearError } =
    useField(name);

  const Texterea = styled(InputTextarea)`
    border-radius: 8px;
    border: 2px ${props => props.borderColor} solid;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    margin-top: 1rem;
    font-size: 0.9rem;
    padding: 0.5rem;
    width: ${props => props.width};
    resize: ${props => props.resize};
    &:focus {
      border: 2px #2684ff solid;
    }
    &:disabled.p-component {
      opacity: 1;
    }
    .p-disabled {
      cursor: pointer;
    }
  `;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textereaRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <div>
      <InputTextarea
        onClick={() => clearError()}
        ref={textereaRef}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && (
        <span style={{ color: "#ef3a5d", display: "block" }}>{error}</span>
      )}
    </div>
  );
};