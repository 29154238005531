/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import DataTableRefact from "../../components/DataTableRefact";
import InputStyled from "../../components/InputDeBusca";
import Container from "../../components/Container50";
import TextStyledComp from "../TextStyled";
import Pagination from "../../components/Pagination";
import Text from "../Text";
import Button from "../Button";
import styled, { ThemeContext } from "styled-components";
import "./style.css";

import { Form } from "@unform/web";
import { Input, InputMask, Select } from "../Form/index";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";

import { Context } from "../../context/loginContext";
import { PageContext } from "../../context/pageContext";
import api from "../../services/api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { string, object, ValidationError } from "yup";
import Loading from "../animations/loading";
import ModalSchool from "../ModalSchool";
import ModalDelete from "../ModalDelete";
import ModalReset from "../ModalReset";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";

const Td = styled.td`
  color: #222;
  padding: 0.8rem;
  font-size: 0.85rem;
  @media (max-height: 800px) {
    font-size: 0.7rem;
    padding: 0.5rem;
  }
`;
const Tr = styled.tr`
  &:nth-child(2n + 1) {
    background-color: #f5f9ff;
  }
  &:hover {
    background-color: rgba(38, 132, 255, 0.2);
  }
`;
const StyleInputMask = {
  width: "98%",
  border: "1px #CCC solid",
  borderRadius: "4px",
  height: "36px",
  paddingLeft: "1%",
};

const ButtonModal = styled.button`
  display: ${(props) => props.display};
  color: ${(props) => props.color};
  place-items: center;
  background-color: ${(props) => props.bgcolor};
  font-size: ${(props) => props.fontSize};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  justify-self: ${(props) => props.justify};
  border-radius: 3px;
  margin: 5px 10px;
  transition: 100ms ease-in-out;
  &:hover {
    color: ${(props) => props.colorhover};
    background-color: ${(props) => props.bgcolorhover};
  }
  @media (max-height: 800px) {
    width: ${(props) => props.widthResp};
    height: ${(props) => props.heightResp};
    font-size: ${(props) => props.fontSizeResp};
  }
`;

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    zIndex: "999",
    width: window.innerWidth >= 1780 ? "100px" : "80px",
    overflow: "none",
  }),
  menuList: (provided, state) => ({
    ...provided,
    overflow: "none",
  }),
};

const DataTableStyle = styled(DataTable)`
  .p-paginator.p-component.p-paginator-bottom {
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    justify-content: flex-start;
  }
  .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: ${(props) => props.colorIcon} !important;
  }
  .p-sortable-column .p-sortable-column-icon {
    color: #555;
  }
  .p-datatable-tbody tr {
    background-color: ${(props) => props.rowsColor};
  }
  .p-datatable-tbody tr.p-row-odd {
    background-color: ${(props) => props.rowStripedColor} !important;
  }
  .p-paginator-page.p-paginator-element.p-link {
    background-color: ${(props) => props.backgroundButtonPaginate};
    color: ${(props) => props.colorButtonPaginate};
    border-radius: 8px;
    min-width: 0rem;
    padding: 0;
    height: 31px;
    width: 31px;
  }
  .p-paginator-page.p-paginator-element.p-link.p-highlight {
    background-color: ${(props) => props.backgroundButtonPaginateActive};
    color: ${(props) => props.colorButtonPaginateActive};
  }
`;

const School = () => {
  let { atualiza, loading, setLoading } = useContext(PageContext);
  const [schoolInputs, setSchoolInputs] = useState({
    email: "",
    telephone: "",
    adress: "",
  });
  const { colors } = React.useContext(ThemeContext);
  const [erro, setErro] = useState(false);
  let [schoolsOptions, setSchoolOptions] = useState([]);
  let [schoolSelected, setSchoolSelected] = useState();
  const [btnUpdateTable, setBtnUpdate] = React.useState(false);
  let [data, setData] = React.useState((data) =>
    localStorage.getItem("dataSchool")
      ? JSON.parse([localStorage.getItem("dataSchool")])
      : data
      ? data
      : []
  );
  let [search, setSearch] = React.useState();
  let [page, setPage] = React.useState(1);
  let [dataSchool, setDataSchool] = React.useState(undefined);
  let [paginationData, setPaginationData] = React.useState({
    currentPage: 1,
    lastPage: 3,
  });
  const [openRegister, setOpenRegister] = React.useState(false);
  let [rows, setRows] = React.useState(10);
  const [changePassword, setChangePassword] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState(null);

  const formuRef = React.useRef(null);

  //Stores data schools options in select
  async function receiveDataSchoolsAPI() {
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          localStorage.getItem("@epaz:access_token")
            ? localStorage.getItem("@epaz:access_token")
            : "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNWViYWM5YjE4NjQ4NWNiOGM0ODZhYjNiN2FjNmJkYzM3ZDVmMjNmNmUyYmYyMzUwODAwOTg0Y2MzMmM1M2IzOTBiNDJlMDMzNjYyOGQwOTQiLCJpYXQiOjE3MDEyMTE4OTQuNTc4Nzk4LCJuYmYiOjE3MDEyMTE4OTQuNTc4ODAyLCJleHAiOjE3MDI1MDc4OTQuNTYwMTQ5LCJzdWIiOiI0Iiwic2NvcGVzIjpbXX0.X0r6ftSugT8yKXxu_AQb8njpkOWl4T8-C5AGVaah7QEmLW94jmFep_WlFEA5s3q-B66be-q6cDxLapA-8Qml985qwUjv_BuiZ7MAicicDySFZ9XAkWIIKRZzGmzm37tma1rUuM1wXa5wghm6nFYUWvdxCn1H73TUTjc1fY-PEoUB-ZjsKlXtfX-jdxNR4HE5obtETr0pcJbij7SOzRpW3yoMgXDzA_TLGcUeGwvaMu5ayvVsunUaYq0ANppW9x77f0KvNdc7dgnxommajZHmQ2ayaewVVu_cSjo2XrzZIcGq7FMBurlLQwuyfM0qfBa9hlaWTWNvqXtcCx8YAaHpxQ5cnwOFdgFk7kEvIYjsaqiRm7xMyw3alOAeq1TGS70yFpSJfDgyYWlXcZQ5WId4OD2CvnwUL2EGGnTIsE_gPLPCwobVEGhHwdTR3P1DyO_cIwYsy7Aa3z6rgzcPRNNt4bVOzaOzCLOkBT1V0CqmtIKq6tBI--IU_ynkEbbmw7n7fe_U8RPLJOeX-YKVJUuXnUQaZdTMfyhHd1pZfG2M5gSW4_3HK_IXeXSjxh1qxfmYpuwy0VHuUvYmiSf_rVrtyVa1IqBEjp0R3dqG0btr6yQCXciKBOoZtOoCIb89oSGnEkGir1otosX0khBEjkrAVKyk2k5Sd_7VTzQR6okmp2w"
        }`,
      },
    };
    await fetch(
      "https://sisged.seed.ap.gov.br/api/escolas?limit=500",
      options
    )
      .then((res) => res.json())
      .then((data) =>
        setSchoolOptions(
          data?.data?.map((item) => ({
            name: item.escola,
            value: {
              school: item.escola,
              adress: item.endereco,
              email: item.email,
              telephone: item.telefone,
              id: item.id_escola,
            },
          }))
        )
      )
      .catch((err) => console.log(err));
  }

  async function handlerSubmit(data) {
    let inputsValue = data;
    !schoolSelected?.school ? setErro(true) : setErro(false);
    inputsValue.nameSchool = schoolSelected?.school;
    async function submitData() {
      setOpenRegister(false);

      let data = JSON.stringify(inputsValue);

      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let reqOptions = {
        url: `${process.env.REACT_APP_API_URL}/userSchool`,
        method: "POST",
        data: data,
        headers: headersList,
      };

      let response;
      await toast
        .promise(axios.request(reqOptions), {
          pending: {
            render() {
              return "Cadastrando Escola";
            },
          },
          success: "Escola cadastrada com sucesso",
          error: {
            render({ data }) {
              return data.response.data.includes("<")
                ? `${data.message}`
                : `${data.response.data}`;
            },
          },
        })
        .then((responseRequest) => {
          setLoading(false);
          response = responseRequest;
        })
        .catch(() => setLoading(false));
      insertDataRequest();
    }

    try {
      const schema = object({
        nameSchool: string()
          .min(2, "No minimo 3 caracteres")
          .required("Preencha com nome da escola"),
        email: string()
          .email("Digite um email valido")
          .min(4, "No minimo 4 caracteres")
          .required("É obrigatorio o email"),
        telephone: string()
          .min(6, "Digite um número válido, rua/número/bairro/cidade")
          .required("Digite o Telefone"),
        address: string()
          .min(5, "Digite um endereço válido")
          .required("O endereço é obrigatorio"),
        inep: string()
          .min(8, "Digite todos os digitos")
          .required("Campo Inep é obrigatorio"),
        password: string().required("Campo obrigatorio"),
      });

      await schema
        .validate(data)
        .then(() => submitData())
        .catch(() => null);
      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      console.log(inputsValue);
      if (err instanceof ValidationError) {
        const errorMensages = {};

        err.inner.forEach((error) => {
          errorMensages[error.path] = error.message;
        });
        formuRef.current.setErrors(errorMensages);
      }
    }
  }

  async function insertDataRequest() {
    setLoading(true);
    setData();
    let response;

    // let queryString = `?NameSchool${search ? `=${search}` : ""}&limit=${
    //   quantityRegisters.value
    // }&page=${page}`;

    response = await api
      .get(`${process.env.REACT_APP_API_URL}/school?NameSchool}`, {
        headers: "",
      })
      .then((request) => {
        localStorage.setItem("dataSchool", JSON.stringify(request.data.data));
        paginationData.currentPage = request.data.currentPage;
        paginationData.lastPage = request.data.lastPage;
        setData(request.data.data);
      })
      .catch((error) => {
        setData([]);
      });

    setLoading(false);
  }

  const itemSchoolTemplate = (item, options) => {
    return <div style={{ width: "450px" }}>{item.name}</div>;
  };

  // Custom Datatable -------------------------
  // Custom Template Body

  const optionsbody = (rowData) => {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <ModalSchool
          fontSize="20px"
          bgColor="transparent"
          setChangePassword={setChangePassword}
          setNewPassword={setNewPassword}
          color={colors.icon_visu}
          btnHoverBg="transparent"
          btnHoverColor="#2684FF"
          insertData={insertDataRequest}
          padding=".2rem"
          placeholderBtn={<i className="fa-solid fa-pen-to-square"></i>}
          item={rowData}
        />
        <ModalDelete
          fontSize="20px"
          bgColor="transparent"
          btnHoverBg="transparent"
          intentionDelete="Escola"
          insertData={insertDataRequest}
          btnHoverColor="#e6394f"
          color="#e63961"
          rota="school"
          placeholderBtn={<i className="fa-solid fa-trash"></i>}
          item={rowData}
        />
      </div>
    );
  };

  // Pagination Template
  const pagination = {
    layout: "PrevPageLink PageLinks NextPageLink",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span
            className={className}
            style={{ userSelect: "none", borderRadius: "3px" }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: "10px" }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  // UseEffects
  React.useEffect(() => {
    receiveDataSchoolsAPI();
  }, []);

  React.useEffect(() => {
    insertDataRequest();
  }, [search, page]);

  return (
    <div
      style={{
        backgroundColor: colors.background,
      }}
      className="School container-full"
    >
      <div style={{ position: "fixed" }}>
        <ToastContainer theme="colored" />
      </div>
      <div className="container-datable">
        <div
          style={{ backgroundColor: colors.container }}
          className="header-datatable"
        >
          <div
            style={{ color: colors.text }}
            className="quantity-registers-container"
          >
            Mostrar
            <Dropdown
              value={rows}
              options={[
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: "Tudo", value: -1 },
              ]}
              onChange={(e) => setRows(e.value)}
              className="DropDown"
            />
            registros
          </div>
          <div
            style={{
              display: "grid",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <Button
              style={{
                backgroundColor: colors.secundary,
              }}
              className="btn_registerSchool"
              icon="fa-solid fa-plus"
              iconPos="left"
              bgcolorhover="#36B37E !important"
              label="Cadastrar Escola"
              onClick={() => setOpenRegister(true)}
            />
          </div>
        </div>
        <DataTableStyle
          value={data}
          scrollable
          className={`datatable`}
          backgroundColor={colors.container}
          bgColorHeaderFilter={colors.container}
          backgroundButtonPaginate={colors.btn_color}
          colorButtonPaginate={colors.text}
          backgroundButtonPaginateActive={colors.icon_visu}
          colorButtonPaginateActive={"#FFF"}
          rowStripedColor={colors.stripedRow}
          rowsColor={colors.row}
          colorIcon={colors.text}
          scrollDirection="horizontal"
          tableStyle={{ height: "58vh" }}
          scrollHeight="58vh"
          borderColor={colors.border}
          paginator
          paginatorTemplate={pagination}
          stripedRows
          rows={rows}
          size="small"
          dataKey="id"
          filterDisplay="menu"
          loading={loading}
          emptyMessage="Nenhum dado encontrado."
        >
          <Column
            filter
            sortable
            rowReorderIcon="pi pi-sort"
            filterMatchMode="contains"
            className="colum"
            filterMenuStyle={{
              backgroundColor: colors.container,
              color: colors.text,
            }}
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
            }}
            showFilterMenuOptions={false}
            filterPlaceholder="Escola"
            header="Escola"
            field="nameSchool"
          />
          <Column
            filter
            sortable
            filterMatchMode="contains"
            className="colum"
            filterMenuStyle={{
              backgroundColor: colors.container,
              color: colors.text,
            }}
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
            }}
            showFilterMenuOptions={false}
            filterPlaceholder="Email"
            header="Email"
            field="email"
          />
          <Column
            filter
            sortable
            filterMatchMode="contains"
            className="colum"
            showFilterMenuOptions={false}
            filterPlaceholder="Telefone"
            header="Telefone"
            field="telephone"
            filterMenuStyle={{
              backgroundColor: colors.container,
              color: colors.text,
            }}
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
            }}
            // body={progressBodyTemplate}
          />
          <Column
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
            }}
            style={{ minWith: "6rem", maxWidth: "6rem" }}
            header="Opções"
            body={optionsbody}
          />
        </DataTableStyle>
        <Dialog
          header="Cadastro Escola"
          style={{
            width: "35vw",
            minWidth: "340px",
            maxWidth: "500px",
          }}
          headerStyle={{
            backgroundColor: colors.container,
            color: colors.text,
          }}
          contentStyle={{
            backgroundColor: colors.container,
            color: colors.text,
          }}
          visible={openRegister}
          onHide={() => setOpenRegister(false)}
        >
          <div style={{ borderTop: "3px solid #2684FF" }}>
            <Form
              ref={formuRef}
              onSubmit={handlerSubmit}
              style={{ paddingTop: "1rem" }}
            >
              <Container gap="15px">
                <div>
                  <Text
                    tag="label"
                    style={{ fontSize: "1rem", fontWeight: "600" }}
                  >
                    Nome da escola
                  </Text>
                  <Dropdown
                    value={schoolSelected}
                    options={schoolsOptions}
                    style={{
                      width: "100%",
                      maxWidth: "30vw",
                      minWidth: "290px",
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    onChange={(e) => {
                      if (e.value) {
                        formuRef.current.setErrors({});
                        setErro(false);
                        console.log(e.value);
                        setSchoolInputs({
                          email: e.value.email,
                          telephone: e.value.telephone,
                          adress: e.value.adress,
                        });
                      } else {
                        setSchoolInputs({
                          email: "",
                          telephone: "",
                          adress: "",
                        });
                      }
                      setSchoolSelected(e.value);
                    }}
                    itemTemplate={itemSchoolTemplate}
                    optionLabel="name"
                    filter
                    showClear
                    filterBy="name"
                    placeholder="Selecione uma escola"
                  />
                  {erro && (
                    <span style={{ color: "#FF4444" }}>Selecione a escola</span>
                  )}
                </div>

                <div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Email da escola
                  </TextStyledComp>
                  <Input
                    height="40px"
                    fontSize="15px"
                    bgColor={colors.background}
                    color={colors.text}
                    name="email"
                    value={
                      schoolInputs?.email?.length < 11
                        ? null
                        : schoolInputs.email
                    }
                    placeholder="EmailDaEscola@outlook.com"
                    disabled={schoolInputs.email || false}
                  />
                </div>
                <div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Telefone da escola
                  </TextStyledComp>
                  <InputMask
                    style={{
                      height: "40px",
                      width: "97%",
                      paddingLeft: ".5rem",
                      borderRadius: "8px",
                      color: `${colors.text}`,
                      backgroundColor: colors.background,
                    }}
                    name="telephone"
                    mask="(99) 9999-9999"
                    placeholder="(00) 0000-0000"
                    value={schoolInputs.telephone}
                    onChange={(input) =>
                      setSchoolInputs((e) => ({
                        ...e,
                        telephone: input.value,
                      }))
                    }
                    disabled={schoolInputs.length < 8 || false}
                  />
                </div>
                <div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Endereço da escola
                  </TextStyledComp>
                  <Input
                    height="40px"
                    bgColor={colors.background}
                    color={colors.text}
                    fontSize="15px"
                    name="address"
                    placeholder="Digite o endereço da escola"
                    value={schoolInputs.adress}
                  />
                </div>
                <div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Número Inep
                  </TextStyledComp>
                  <Input
                    height="40px"
                    maxLength={8}
                    bgColor={colors.background}
                    color={colors.text}
                    fontSize="15px"
                    name="inep"
                  />
                </div>
                <Container>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Crie uma senha
                  </TextStyledComp>
                  <Container position="relative">
                    <Input
                      type="text"
                      textSize="15px"
                      bgColor={colors.background}
                      color={colors.text}
                      height="40px"
                      name="password"
                      placeholder="Digite sua senha"
                    />
                  </Container>
                </Container>
              </Container>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <Button
                  justify={"end"}
                  bgcolor={"#EFEFEF"}
                  color={"#222"}
                  fontSize={"1rem"}
                  height="40px"
                  style={{ cursor: "pointer" }}
                  colorhover={"#FFF"}
                  bgcolorhover="#2684FF"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenRegister(false);
                  }}
                >
                  Voltar
                </Button>
                <Button
                  type="submit"
                  display={"grid"}
                  justify={"start"}
                  bgcolor={"#36B37E"}
                  color={"white"}
                  height={"40px"}
                  heightResp={"30px"}
                  style={{ cursor: "pointer" }}
                  width={"115px"}
                  fontSize={"1rem"}
                  bgcolorhover={"#008453"}
                >
                  Cadastrar
                </Button>
              </div>
            </Form>
          </div>
        </Dialog>
      </div>
      <ModalReset
        changePassword={changePassword}
        setChangePassword={setChangePassword}
        newPassword={newPassword}
      />
    </div>
  );
};

export default School;
