/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import {
  FilterMatchMode,
  FilterOperator,
  locale,
  addLocale,
  FilterService,
} from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Ripple } from "primereact/ripple";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import jsPDF from "jspdf";
import { classNames } from "primereact/utils";
import { Slider } from "primereact/slider";
import get_api from "../../services/get_api";
import "./DataTableDemo.css";
import autoTable from "jspdf-autotable";
import styled, { ThemeContext } from "styled-components";

const DataTableStyle = styled(DataTable)`
  .p-datatable-header {
    background-color: ${(props) => props.backgroundHeader};
    border: none;
  }
  .p-paginator.p-component.p-paginator-bottom {
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    justify-content: flex-start;
  }
  .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: ${(props) => props.colorIcon} !important;
  }
  .p-sortable-column .p-sortable-column-icon {
    color: #555;
  }
  .p-datatable-tbody tr {
    background-color: ${(props) => props.rowsColor};
  }
  .p-datatable-tbody tr.p-row-odd {
    background-color: ${(props) => props.rowStripedColor} !important;
  }
  .p-paginator-page.p-paginator-element.p-link {
    background-color: ${(props) => props.backgroundButtonPaginate};
    color: ${(props) => props.colorButtonPaginate};
    border-radius: 8px;
    min-width: 0rem;
    padding: 0;
    height: 31px;
    width: 31px;
  }
  .p-paginator-page.p-paginator-element.p-link.p-highlight {
    background-color: ${(props) => props.backgroundButtonPaginateActive};
    color: ${(props) => props.colorButtonPaginateActive};
  }
`;

export const DataTableFilterDemo = () => {
  const [occurrences, setOccurrences] = React.useState(null);
  const [valueFiltered, setValueFiltered] = React.useState(null);
  const { colors } = useContext(ThemeContext);

  const typeLogin = localStorage.getItem("TypeLogin")
    ? localStorage.getItem("TypeLogin")
    : null;

  const [first1, setFirst1] = React.useState(0);
  const [rows1, setRows1] = React.useState(10);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageInputTooltip, setPageInputTooltip] = React.useState(
    "Pressione ENTER para ir para esta página."
  );

  FilterService.register("filterArray", (a, b) => {
    let result = [];
    if (b != null) {
      result = a.map((itemA) => {
        let verify = b.map((itemB) => itemA == itemB);
        return verify.includes(true) ? true : false;
      });
    }

    return b.length == 0 ? true : result.includes(true);
  });

  const [filters2, setFilters2] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    event: { value: null, matchMode: FilterMatchMode.IN },
    current_status: { value: null, matchMode: FilterMatchMode.IN },
    school: { value: null, matchMode: FilterMatchMode.IN },
    partner: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    progress: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    age_of_involved1: { value: null, matchMode: FilterMatchMode.BETWEEN },
    age_of_involved2: { value: null, matchMode: FilterMatchMode.BETWEEN },
    series_taken_aggressor: { value: null, matchMode: FilterMatchMode.IN },
    series_taken_assaulted: { value: null, matchMode: FilterMatchMode.IN },
    sex_aggressor: { value: null, matchMode: FilterMatchMode.IN },
    sex_assaulted: { value: null, matchMode: FilterMatchMode.IN },
    date_occurrence: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
  });

  const [globalFilterValue2, setGlobalFilterValue2] = React.useState("");

  const [loading2, setLoading2] = React.useState(true);

  const [columnsSelected, setColumnsSelected] = React.useState(
    typeLogin == "usuarios"
      ? ["Ocorrências", "Escola", "Parceiros"]
      : ["Ocorrências", "Parceiros"]
  );

  const dt = React.useRef(null);

  //Options Select Filters
  let [schools, setSchools] = React.useState([]);
  let [partner, setPartner] = React.useState([]);
  let [optionsFilter, setOptionsFilter] = React.useState({
    series_taken: [
      { value: "8° Ano", name: "8° Ano" },
      { value: "9° Ano", name: "9° Ano" },
      { value: "1° Ano (Ensino Médio)", name: "1° Ano (Ensino Médio)" },
      { value: "2° Ano (Ensino Médio)", name: "2° Ano (Ensino Médio)" },
      { value: "3° Ano (Ensino Médio)", name: "3° Ano (Ensino Médio)" },
    ],
    events: [
      { value: "Ameaça direta", name: "Ameaça direta" },
      { value: "Ameaça indireta", name: "Ameaça indireta" },
      { value: "Assédio sexual", name: "Assédio sexual" },
      { value: "Briga", name: "Briga" },
      { value: "Bullying", name: "Bullying" },
      { value: "Dano ao patrimonio", name: "Dano ao patrimonio" },
      { value: "Difamação", name: "Difamação" },
      { value: "Intimidação", name: "Intimidação" },
      { value: "Isolamento", name: "Isolamento" },
      { value: "Ferimentos recentes", name: "Ferimentos recentes" },
      { value: "Crises de pânico", name: "Crises de pânico" },
      { value: "Crises de ansiedade", name: "Crises de ansiedade" },
      { value: "Falta de ar", name: "Falta de ar" },
      { value: "Tentativa de suicídio", name: "Tentativa de suicídio" },
      {
        value: "Autolesão praticada na escola",
        name: "Autolesão praticada na escola",
      },
      {
        value: "Autolesão não praticada na escola",
        name: "Autolesão não praticada na escola",
      },
      {
        value: "Porte ou uso de arma",
        name: "Porte ou uso de arma",
      },
      {
        value: "Agressão física com brigas de menor potencial agressivo",
        name: "Agressão física com brigas de menor potencial agressivo",
      },
      {
        value: "Agressão física com lesão corporal",
        name: "Agressão física com lesão corporal",
      },
      {
        value: "Consumo ou tráfico de drogas",
        name: "Consumo ou tráfico de drogas",
      },
      {
        value:
          "Ameaças a integridade física ou psicológica a algum estudante ou profissional da educação",
        name: "Ameaças a integridade física ou psicológica a algum estudante ou profissional da educação",
      },
      {
        value: "Furtos ou roubos",
        name: "Furtos ou roubos",
      },
      {
        value: "Danos ao patrimônio",
        name: "Danos ao patrimônio",
      },
      {
        value: "Abuso sexual",
        name: "Abuso sexual",
      },
      {
        value: "Abuso físico",
        name: "Abuso físico",
      },
      {
        value: "Abandono familiar",
        name: "Abandono familiar",
      },
      {
        value: "Mudanças repentinas de humor e comportamento",
        name: "Mudanças repentinas de humor e comportamento",
      },
      {
        value: "Tristeza que perdura por vários dias ou semanas",
        name: "Tristeza que perdura por vários dias ou semanas",
      },
      {
        value: "Mudanças físicas inexplicáveis, como perda ou ganho de peso",
        name: "Mudanças físicas inexplicáveis, como perda ou ganho de peso",
      },
      {
        value: "Queda repentina no desempenho escolar",
        name: "Queda repentina no desempenho escolar",
      },
      {
        value:
          "Mudanças nos hábitos sociais, como afastamento ou isolamento de amigos e familiares",
        name: "Mudanças nos hábitos sociais, como afastamento ou isolamento de amigos e familiares",
      },
      {
        value:
          "Incapacidade de realizar tarefas diárias (como higiene e autocuidado básico);",
        name: "Incapacidade de realizar tarefas diárias (como higiene e autocuidado básico);",
      },
      {
        value: "Ameaças verbais e agressividade repentina",
        name: "Ameaças verbais e agressividade repentina",
      },
      {
        value: "Dormir muito ou pouco",
        name: "Dormir muito ou pouco",
      },
      {
        value: "Ter dores inexplicáveis e/ou constantes",
        name: "Ter dores inexplicáveis e/ou constantes",
      },
      {
        value: "Agitação, inquietação, angústia ou comportamento de pânico",
        name: "Agitação, inquietação, angústia ou comportamento de pânico",
      },
      {
        value: "Falar ou escrever sobre cometer suicídio",
        name: "Falar ou escrever sobre cometer suicídio",
      },
      {
        value:
          "Expressar sentimentos de fracasso, inutilidade ou perda de esperança",
        name: "Expressar sentimentos de fracasso, inutilidade ou perda de esperança",
      },
      {
        value: "Atrasos ou faltas frequentes",
        name: "Atrasos ou faltas frequentes",
      },
      {
        value:
          "Usar roupas que cobrem o corpo em clima quente, com o intuito de esconder lesões",
        name: "Usar roupas que cobrem o corpo em clima quente, com o intuito de esconder lesões",
      },
      {
        value: "Não querer fazer atividades físicas",
        name: "Não querer fazer atividades físicas",
      },
      {
        value:
          "Atos contínuos de incivilidade (desrespeito e/ou bullying) e/ou indisciplina",
        name: "Atos contínuos de incivilidade (desrespeito e/ou bullying) e/ou indisciplina",
      },
      {
        value: "Indução a autolesão ou ao suicídio",
        name: "Indução a autolesão ou ao suicídio",
      },
      {
        value: "Abuso psicológico",
        name: "Abuso psicológico",
      },
      {
        value: "Gravidez na adolescência",
        name: "Gravidez na adolescência",
      },
      {
        value: "Cyberbullying",
        name: "Cyberbullying",
      },
      {
        value: "Revitimização",
        name: "Revitimização",
      },
      {
        value: "Outros (que não for competência da escola)",
        name: "Outros (que não for competência da escola)",
      },
    ],
    progress: [
      { value: "Conselho tutelar", name: "Conselho tutelar" },
      { value: "Família", name: "Família" },
      { value: "CAPS", name: "CAPS" },
      { value: "CVEDUC", name: "CVEDUC" },
      { value: "Polícia", name: "Polícia" },
      { value: "SAMU", name: "SAMU" },
      { value: "Outros", name: "Outros" },
    ],
    sex: [
      { value: "Masculino", label: "Masculino" },
      { value: "Feminino", label: "Feminino" },
    ],
  });

  const columns =
    typeLogin == "usuarios"
      ? [
          { name: "Escola", value: "Escola" },
          { name: "Ocorrências", value: "Ocorrências" },
          { name: "Parceiros", value: "Parceiros" },
          { name: "Encaminhamentos", value: "Encaminhamentos" },
          { name: "Idade do envolvido 1", value: "Idade do envolvido 1" },
          { name: "Idade do envolvido 2", value: "Idade do envolvido 2" },
          { name: "Série do envolvido 1", value: "Série do envolvido 1" },
          { name: "Série do envolvido 2", value: "Série do envolvido 2" },
          { name: "Gênero do envolvido 1", value: "Gênero do envolvido 1" },
          { name: "Gênero do envolvido 2", value: "Gênero do envolvido 2" },
          { name: "Status", value: "Status" },
        ]
      : [
          { name: "Ocorrências", value: "Ocorrências" },
          { name: "Parceiros", value: "Parceiros" },
          { name: "Encaminhamentos", value: "Encaminhamentos" },
          { name: "Idade do envolvido 1", value: "Idade do envolvido 1" },
          { name: "Idade do envolvido 2", value: "Idade do envolvido 2" },
          { name: "Série do envolvido 1", value: "Série do envolvido 1" },
          { name: "Série do envolvido 2", value: "Série do envolvido 2" },
          { name: "Gênero do envolvido 1", value: "Gênero do envolvido 1" },
          { name: "Gênero do envolvido 2", value: "Gênero do envolvido 2" },
          { name: "Status", value: "Status" },
        ];

  const cols = [
    { header: "Escola", field: "school" },
    { header: "Ocorrências", field: "event" },
    { header: "Parceiros", field: "partner" },
    { header: "Encaminhamentos", field: "progress" },
    { header: "Idade do envolvido 1", field: "age_of_involved1" },
    { header: "Idade do envolvido 2", field: "age-age_of_involved2" },
    { header: "Série do envolvido 1", field: "series_taken_assaulted" },
    { header: "Série do envolvido 2", field: "series_taken_aggressor" },
    { header: "Gênero do envolvido 1", field: "sex_assaulted" },
    { header: "Gênero do envolvido 2", field: "sex_aggressor" },
    { header: "Status", field: "current_status" },
  ];

  const customerService = new get_api();

  const getSchools = React.useMemo(async function getSchools() {
    await customerService.getSchools().then((data) => {
      schools = data.map((item) => {
        return { name: item.nameSchool, value: item.nameSchool };
      });
    });
    setSchools(schools);
  }, []);

  const getPartner = React.useMemo(async function getPartner() {
    await customerService.getPartner().then((data) => {
      partner = data.map((item) => {
        return { name: item.nameOrgan, value: item.nameOrgan };
      });
    });
    setPartner(partner);
  }, []);

  function getData() {
    customerService.getOcurrences().then((data) => {
      let dados = data.map((item) => {
        function calculaIdade(nascimento) {
          let date_born = new Date(nascimento);
          let today = new Date();
          if (nascimento.length < 1) {
            return 0;
          } else {
            return Math.floor(
              Math.ceil(
                Math.abs(date_born.getTime() - today.getTime()) /
                  (1000 * 3600 * 24)
              ) / 365.25
            );
          }
        }
        let idade_do_envolvido1 = calculaIdade(item.date_birth_assaulted);
        let idade_do_envolvido2 = calculaIdade(item.date_birth_aggressor);
        return {
          event: item.event,
          school: item.School.nameSchool,
          partner: item.OcurrencePartner.map((item) => {
            return item.Partner.nameOrgan;
          }),
          progress: item.progress.split(", ").map((item) => {
            return item;
          }),
          age_of_involved1: idade_do_envolvido1,
          age_of_involved2: idade_do_envolvido2,
          series_taken_aggressor: item.series_taken_aggressor,
          series_taken_assaulted: item.series_taken_assaulted,
          sex_aggressor: item.sex_aggressor,
          sex_assaulted: item.sex_assaulted,
          date_occurrence: new Date(item.date_occurrence),
          status_id: String(item?.Status?.map((item) => item.id)),
          current_status: String(item?.Status?.map((item) => item.status)),
          history: item?.Status.map((item) => {
            return [
              {
                description: item?.not_completed,
                name: "not_completed",
                status: "Não Concluído",
              },
              {
                description: item?.difficulty_completion,
                name: "difficulty_completion",
                status: "Dificuldade na conclusão",
              },
              {
                description: item?.completed,
                name: "completed",
                status: "Concluído",
              },
            ];
          }).flat(1),
        };
      });
      setValueFiltered((e) =>
        e == null
          ? dados.map((item) => {
              return {
                ...item,
                partner: item.partner.join(" "),
                progress: item.progress.join(" "),
              };
            })
          : e
      );
      setOccurrences(dados);
      setLoading2(false);
    });
  }

  React.useEffect(() => {
    getData();
  }, []);

  const formatDate = (value) => {
    const date = value.length != 0 ? new Date(value) : new Date();
    return date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.date_occurrence);
  };

  addLocale("BR", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    today: "Hoje",
    clear: "Limpar",
    addRule: "Adicionar filtro",
    removeRule: "Remover filtro",
    matchAll: "Todos corresponderem",
    matchAny: "Qualquer um corresponder",
  });
  locale("BR");

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        locale="BR"
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const onGlobalFilterChange2 = (e) => {
    const value = e.target.value;
    let _filters2 = { ...filters2 };
    _filters2["global"].value = value;

    setFilters2(_filters2);
    setGlobalFilterValue2(value);
  };

  const renderHeader2 = () => {
    return (
      <>
        <div className="flex justify-content-start"></div>
        <div
          className="flex"
          style={{
            justifyContent: "space-between",
            backgroundColor: colors.container,
          }}
        >
          <Button
            style={{
              justifySelf: "start",
              backgroundColor: colors.secundary,
              border: "none",
            }}
            className="h-3 w-3rem flex justify-content-center"
            label={
              <i
                className={`fa-solid fa-rotate-right ${
                  loading2 ? "pi-spin" : ""
                }`}
              />
            }
            onClick={() => {
              setLoading2(true);
              getData();
            }}
          />
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue2}
              className="w-"
              onChange={onGlobalFilterChange2}
              placeholder="Ocorrência, Escolas e parceiros, encaminhamento"
            />
          </span>
        </div>
      </>
    );
  };

  const partnerBodyTemplate = (rowData) => {
    const partner = rowData.partner.join(" ");
    return (
      <React.Fragment>
        <span className="image-text">{partner}</span>
      </React.Fragment>
    );
  };

  const progressBodyTemplate = (rowData) => {
    const progress = rowData.progress.join(" ");
    return (
      <React.Fragment>
        <span className="image-text">{progress}</span>
      </React.Fragment>
    );
  };

  const partnerRowFilterTemplate = (options) => {
    if (options.filterModel.matchMode == "contains") {
      return (
        <Dropdown
          value={options.value ? options.value.join("") : ""}
          options={partner}
          panelClassName="dropdown-check"
          onChange={(e) => {
            options.filterApplyCallback([e.value]);
          }}
          optionLabel="name"
          placeholder="Parceiros"
          className="p-column-filter"
        />
      );
    } else {
      return (
        <MultiSelect
          value={options.value}
          options={partner}
          panelClassName="dropdown-check"
          onChange={(e) => {
            options.filterApplyCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Parceiros"
          className="p-column-filter"
          maxSelectedLabels={1}
        />
      );
    }
  };

  const seriesRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={optionsFilter.series_taken}
        panelClassName="dropdown-check"
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Série"
        className="p-column-filter"
        maxSelectedLabels={1}
      />
    );
  };

  const sexRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={optionsFilter.sex}
        panelClassName="dropdown-check"
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Gênero"
        className="p-column-filter"
        maxSelectedLabels={1}
      />
    );
  };

  const progressRowFilterTemplate = (options) => {
    if (options.filterModel.matchMode == "contains") {
      return (
        <Dropdown
          value={options.value ? options.value.join("") : ""}
          options={optionsFilter.progress}
          panelClassName="dropdown-check"
          onChange={(e) => {
            options.filterApplyCallback([e.value]);
          }}
          optionLabel="name"
          placeholder="Parceiros"
          className="p-column-filter"
        />
      );
    } else {
      return (
        <MultiSelect
          value={options.value}
          options={optionsFilter.progress}
          panelClassName="dropdown-check"
          onChange={(e) => {
            options.filterApplyCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Parceiros"
          className="p-column-filter"
          maxSelectedLabels={1}
        />
      );
    }
  };
  const ItemTemplate = (option) => {
    return (
      <div className="p-multiselect-representative-option">
        <span className="image-text">{option.name}</span>
      </div>
    );
  };

  const schoolRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={schools}
        panelClassName="dropdown-check"
        itemTemplate={ItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Escola"
        className="p-column-filter"
        maxSelectedLabels={1}
      />
    );
  };

  const eventRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={optionsFilter.events}
        panelClassName="dropdown-check"
        itemTemplate={ItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Ocorrências"
        className="p-column-filter"
        maxSelectedLabels={1}
      />
    );
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={[
          {
            name: "Dificuldade na conclusão",
            value: "Dificuldade na conclusão",
          },
          { name: "completo", value: "completo" },
          { name: "Não Concluído", value: "Não Concluído" },
        ]}
        panelClassName="dropdown-check"
        itemTemplate={ItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Status"
        className="p-column-filter"
        maxSelectedLabels={1}
      />
    );
  };

  const ageInvolved1FilterTemplate = (options) => {
    return (
      <React.Fragment>
        <Slider
          value={options.value}
          onChange={(e) => options.filterCallback(e.value)}
          range
          className="m-3"
        ></Slider>
        <div className="flex align-items-center justify-content-between px-2">
          <span>{options.value ? options.value[0] : 0}</span>
          <span>{options.value ? options.value[1] : 100}</span>
        </div>
      </React.Fragment>
    );
  };

  const onPageInputKeyDown = (event, options) => {
    if (event.key === "Enter") {
      const page = parseInt(currentPage);
      if (page < 1 || page > options.totalPages) {
        setPageInputTooltip(
          `O valor deve estar entre 1 e ${options.totalPages}.`
        );
      } else {
        const first = currentPage ? options.rows * (page - 1) : 0;

        setFirst1(first);
        setPageInputTooltip("Pressione ENTER para ir para esta página.");
      }
    }
  };

  const onCustomPage = (event) => {
    setFirst1(event.first);
    setRows1(event.rows);
    setCurrentPage(event.page + 1);
  };

  const onPageInputChange = (event) => {
    setCurrentPage(event.target.value);
  };

  //Export datatable functions
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  let columnsTable = [];
  let col = columnsSelected.forEach((colSelected) => {
    cols.forEach((colum) => {
      if (colSelected == colum.header) {
        columnsTable.push({ title: colum.header, dataKey: colum.field });
      }
    });
  });

  function exportBodyAndHeaderData() {
    let fieldsPdf = [];
    columnsSelected.forEach((colSelected) => {
      cols.forEach((col) => {
        if (colSelected == col.header) {
          fieldsPdf.push(col.field);
        }
      });
    });
    let dataPdfFil = valueFiltered.map((dataItem) => {
      let data = [];
      fieldsPdf.map((field) => {
        data.push(dataItem[field]);
      });
      return data;
    });
    return dataPdfFil;
  }

  const exportPdf = () => {
    const doc = new jsPDF(0, 0);
    let dataPdfFil = exportBodyAndHeaderData();

    autoTable(doc, {
      head: [columnsSelected],
      body: dataPdfFil,
    });

    doc.save("Relatório.pdf");
  };

  const exportExcel = () => {
    let dataPdfFil = exportBodyAndHeaderData();
    import("xlsx").then((xlsx) => {
      let today =
        new Date().getDay() +
        "/" +
        new Date().getMonth() +
        "/" +
        new Date().getFullYear() +
        " - " +
        new Date().getHours() +
        ":" +
        new Date().getMinutes();
      const worksheet = xlsx.utils.aoa_to_sheet([
        columnsSelected,
        ...dataPdfFil,
      ]);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "Relatório");
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  //
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div style={{ textAlign: "left", display: "grid" }}>
          <span
            style={{
              fontSize: ".90rem",
              fontWeight: "600",
              color: colors.text,
            }}
            className="mb-1"
          >
            Selecione as colunas que deseja visualizar:
          </span>
          <MultiSelect
            value={columnsSelected}
            options={columns}
            optionLabel="name"
            panelClassName="dropdown-check"
            placeholder="Selecione as colunas que deseja visualizar"
            onChange={(e) => {
              setColumnsSelected(e.value);
              console.log(columnsSelected);
            }}
            style={{ width: "20em" }}
          />
        </div>
      </React.Fragment>
    );
  };

  const buttonFilterClear = (filter) => {
    return (filter.field = (
      <Button
        style={{
          color: "#2684FF",
          borderColor: "#2684FF",
        }}
        className="p-button-outlined p-button-sm p-2"
        onClick={filter.filterClearCallback}
      >
        Limpar
      </Button>
    ));
  };
  const buttonFilterApply = (filter) => {
    return (filter.field = (
      <Button
        style={{
          backgroundColor: colors.secundary,
          color: "#FFF",
          border: "none",
        }}
        className="p-button-sm p-2"
        onClick={filter.filterApplyCallback}
      >
        Aplicar
      </Button>
    ));
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <span>
          <strong style={{ color: colors.text }} className="mr-2">
            Opções de exportação:{" "}
          </strong>
        </span>
        <Button
          type="button"
          icon="pi pi-file"
          onClick={() => exportCSV()}
          className="mr-2 p-button-info"
          tooltip="CSV"
          tooltipOptions={{ position: "top" }}
        />
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={() => exportExcel()}
          tooltip="XLS"
          className="p-button-success mr-2"
          tooltipOptions={{ position: "top" }}
        />
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={() => exportPdf()}
          className="p-button-warning mr-2"
          tooltipOptions={{ position: "top" }}
          tooltip="PDF"
        />
      </React.Fragment>
    );
  };

  const pagination = {
    layout: "PrevPageLink PageLinks NextPageLink",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span
            className={className}
            style={{ userSelect: "none", borderRadius: "3px" }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: "10px" }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  const statusBody = React.useCallback(
    (rowData) => {
      return (
        <p onClick={() => console.log(rowData)}>{rowData.current_status}</p>
      );
    },
    [occurrences]
  );

  const header2 = renderHeader2();

  return (
    <div className="Report datatabl ">
      <div className="card" style={{ maxWidth: "89.4vw" }}>
        <Toolbar
          className="mb-4 w-full"
          style={{ backgroundColor: colors.container, border: "none" }}
          right={rightToolbarTemplate}
          left={leftToolbarTemplate}
        ></Toolbar>
        <DataTableStyle
          ref={dt}
          scrollable
          backgroundColor={colors.container}
          bgColorHeaderFilter={colors.container}
          backgroundButtonPaginate={colors.btn_color}
          colorButtonPaginate={colors.text}
          backgroundButtonPaginateActive={colors.icon_visu}
          colorButtonPaginateActive={"#FFF"}
          rowStripedColor={colors.stripedRow}
          rowsColor={colors.row}
          colorIcon={colors.text}
          backgroundHeader={colors.container}
          scrollDirection="horizontal"
          scrollHeight="58vh"
          value={occurrences}
          onValueChange={(e) => {
            let handlingData = e.map((item) => {
              return {
                ...item,
                partner: item.partner.join(" "),
                progress: item.progress.join(" "),
              };
            });
            setValueFiltered(handlingData);
          }}
          stripedRows
          paginator
          paginatorTemplate={pagination}
          first={first1}
          rows={20}
          onPage={onCustomPage}
          className="w-full"
          size="small"
          dataKey="id"
          filters={filters2}
          filterDisplay="menu"
          loading={loading2}
          globalFilterFields={["event", "school", "partner", "progress"]}
          header={header2}
          emptyMessage="Nenhum dado encontrado."
        >
          {columnsSelected.includes("Ocorrências") && (
            <Column
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: "#777",
              }}
              bodyStyle={{
                color: colors.text,
                border: `1px transparent solid`,
              }}
              headerStyle={{
                color: colors.text,
                backgroundColor: colors.container,
                border: `1px transparent solid`,
              }}
              filterClear={buttonFilterClear}
              filterApply={buttonFilterApply}
              field="event"
              header="Ocorrência"
              filter
              sortable
              showFilterMatchModes={false}
              filterElement={eventRowFilterTemplate}
              filterPlaceholder="Search by name"
              style={{ width: "10%" }}
            />
          )}
          {columnsSelected.includes("Escola") && typeLogin == "usuarios" && (
            <Column
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: "#777",
              }}
              bodyStyle={{
                color: colors.text,
                border: `1px transparent solid`,
              }}
              headerStyle={{
                color: colors.text,
                backgroundColor: colors.container,
                border: `1px transparent solid`,
              }}
              filterClear={buttonFilterClear}
              filterApply={buttonFilterApply}
              header="Escolas"
              field="school"
              filterField="school"
              style={{ width: "10%" }}
              showFilterMatchModes={false}
              sortable
              filterElement={schoolRowFilterTemplate}
              filter
              filterPlaceholder="Search by country"
            />
          )}
          {columnsSelected.includes("Parceiros") && (
            <Column
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: "#777",
                width: "5rem",
              }}
              bodyStyle={{
                color: colors.text,
                border: `1px transparent solid`,
              }}
              headerStyle={{
                color: colors.text,
                backgroundColor: colors.container,
                border: `1px transparent solid`,
              }}
              filterClear={buttonFilterClear}
              filterApply={buttonFilterApply}
              header="Parceiros"
              field="partner"
              filterField="partner"
              showFilterOperator={false}
              showFilterMatchModes={true}
              showAddButton={false}
              filterMatchModeOptions={[
                { label: "Contém", value: FilterMatchMode.CONTAINS },
                { label: "Encontrar vários", value: "filterArray" },
              ]}
              sortable
              style={{ width: "10%" }}
              body={partnerBodyTemplate}
              filter
              filterElement={partnerRowFilterTemplate}
            />
          )}
          {columnsSelected.includes("Status") && (
            <Column
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: "#777",
              }}
              bodyStyle={{
                color: colors.text,
                border: `1px transparent solid`,
              }}
              headerStyle={{
                color: colors.text,
                backgroundColor: colors.container,
                border: `1px transparent solid`,
              }}
              filterClear={buttonFilterClear}
              filterApply={buttonFilterApply}
              field="current_status"
              header="Status"
              filter
              sortable
              showFilterMatchModes={false}
              filterElement={statusRowFilterTemplate}
              style={{ width: "10%" }}
            />
          )}
          {columnsSelected.includes("Encaminhamentos") && (
            <Column
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: "#777",
                width: "5rem",
              }}
              bodyStyle={{
                color: colors.text,
                border: `1px transparent solid`,
              }}
              headerStyle={{
                color: colors.text,
                backgroundColor: colors.container,
                border: `1px transparent solid`,
              }}
              filterClear={buttonFilterClear}
              filterApply={buttonFilterApply}
              header="Encaminhamentos"
              field="progress"
              filterField="progress"
              showFilterOperator={false}
              showFilterMatchModes={true}
              showAddButton={false}
              filterMatchModeOptions={[
                { label: "Contém", value: FilterMatchMode.CONTAINS },
                {
                  label: "Encontrar vários",
                  value: "filterArray",
                },
              ]}
              sortable
              style={{ width: "15%" }}
              body={progressBodyTemplate}
              filter
              filterElement={progressRowFilterTemplate}
            />
          )}
          <Column
            filterMenuStyle={{
              backgroundColor: colors.container,
              color: "#777",
            }}
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
            }}
            filterClear={buttonFilterClear}
            filterApply={buttonFilterApply}
            field="date_occurrence"
            header="Data da ocorrência"
            style={{ width: "10%" }}
            sortable
            exportable={false}
            filter
            label="BR"
            dataType="date"
            filterMatchModeOptions={[
              { label: "Esta data", value: FilterMatchMode.DATE_IS },
              { label: "Sem essa data", value: FilterMatchMode.DATE_IS_NOT },
              {
                label: "Antes dessa data",
                value: FilterMatchMode.DATE_BEFORE,
              },
              {
                label: "Depois dessa data",
                value: FilterMatchMode.DATE_AFTER,
              },
            ]}
            body={dateBodyTemplate}
            filterElement={dateFilterTemplate}
          />
          {columnsSelected.includes("Idade do envolvido 1") && (
            <Column
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: "#777",
              }}
              bodyStyle={{
                color: colors.text,
                border: `1px transparent solid`,
              }}
              headerStyle={{
                color: colors.text,
                backgroundColor: colors.container,
                border: `1px transparent solid`,
              }}
              filterClear={buttonFilterClear}
              filterApply={buttonFilterApply}
              field="age_of_involved1"
              header="Idade do envolvido 1"
              showFilterMatchModes={false}
              style={{ width: "10%" }}
              sortable
              filter
              filterElement={ageInvolved1FilterTemplate}
            />
          )}
          {columnsSelected.includes("Idade do envolvido 2") && (
            <Column
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: "#777",
              }}
              bodyStyle={{
                color: colors.text,
                border: `1px transparent solid`,
              }}
              headerStyle={{
                color: colors.text,
                backgroundColor: colors.container,
                border: `1px transparent solid`,
              }}
              filterClear={buttonFilterClear}
              filterApply={buttonFilterApply}
              field="age_of_involved2"
              header="Idade do envolvido 2"
              showFilterMatchModes={false}
              style={{ width: "10%" }}
              sortable
              filter
              filterElement={ageInvolved1FilterTemplate}
            />
          )}
          {columnsSelected.includes("Série do envolvido 1") && (
            <Column
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: "#777",
                width: "5rem",
              }}
              bodyStyle={{
                color: colors.text,
                border: `1px transparent solid`,
              }}
              headerStyle={{
                color: colors.text,
                backgroundColor: colors.container,
                border: `1px transparent solid`,
              }}
              filterClear={buttonFilterClear}
              filterApply={buttonFilterApply}
              header="Série do envolvido 1"
              field="series_taken_assaulted"
              filterField="series_taken_assaulted"
              showFilterMatchModes={false}
              style={{ width: "10%" }}
              filter
              sortable
              filterElement={seriesRowFilterTemplate}
            />
          )}
          {columnsSelected.includes("Série do envolvido 2") && (
            <Column
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: "#777",
                width: "5rem",
              }}
              bodyStyle={{
                color: colors.text,
                border: `1px transparent solid`,
              }}
              headerStyle={{
                color: colors.text,
                backgroundColor: colors.container,
                border: `1px transparent solid`,
              }}
              filterClear={buttonFilterClear}
              filterApply={buttonFilterApply}
              header="Série do envolvido 2"
              field="series_taken_aggressor"
              filterField="series_taken_aggressor"
              showFilterMatchModes={false}
              style={{ width: "10%" }}
              filter
              sortable
              filterElement={seriesRowFilterTemplate}
            />
          )}
          {columnsSelected.includes("Gênero do envolvido 1") && (
            <Column
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: "#777",
                width: "5rem",
              }}
              bodyStyle={{
                color: colors.text,
                border: `1px transparent solid`,
              }}
              headerStyle={{
                color: colors.text,
                backgroundColor: colors.container,
                border: `1px transparent solid`,
              }}
              filterClear={buttonFilterClear}
              filterApply={buttonFilterApply}
              header="Gênero do envolvido 1"
              field="sex_assaulted"
              filterField="sex_assaulted"
              showFilterMatchModes={false}
              style={{ width: "10%" }}
              filter
              sortable
              filterElement={sexRowFilterTemplate}
            />
          )}
          {columnsSelected.includes("Gênero do envolvido 2") && (
            <Column
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: "#777",
                width: "5rem",
              }}
              bodyStyle={{
                color: colors.text,
                border: `1px transparent solid`,
              }}
              headerStyle={{
                color: colors.text,
                backgroundColor: colors.container,
                border: `1px transparent solid`,
              }}
              filterClear={buttonFilterClear}
              filterApply={buttonFilterApply}
              header="Gênero do envolvido 2"
              field="sex_aggressor"
              filterField="sex_aggressor"
              showFilterMatchModes={false}
              style={{ width: "10%" }}
              filter
              sortable
              filterElement={sexRowFilterTemplate}
            />
          )}
        </DataTableStyle>
      </div>
    </div>
  );
};
