import styled from "styled-components";

const Card = styled.div``;

const CardsLogin = styled(Card)`
  display: grid;
  grid-template-columns: 80px 1fr 80px;
  background-color: #f7f7f7;
  border: 1.8px solid #616480;
  width: 100%;
  height: 70px;
  border-radius: 5px;
  box-shadow: 2px 2px 3px 0 rgba(117, 113, 113, 0.25);
  &:hover {
    transform: translatex(1.5%);
    transition: 100ms ;
    transition-timing-function: ease-in;
  }
`
export default CardsLogin;