import React from "react";
import styled from "styled-components";
import Logo from "../assets/img/logoseed.png";

const LogoSEED = styled.img`
  place-self: ${(props) => props.self};
  justify-self: ${(props) => props.justify};
  align-self: ${(props) => props.align};
  width: ${(props) => props.width};
  -webkit-transform: translate(${(props) => props.translate});
  -webkit-transform: translate(${(props) => props.translate});
  -moz-transform: translate(${(props) => props.translate});
  -ms-transform: translate(${(props) => props.translate});
  -o-transform: translate(${(props) => props.translate});
  transform: translate(${(props) => props.translate});
  max-width: 250px;
  @media (max-height: 810px) {
    width: ${(props) => props.widthResp};
  }
`;

// eslint-disable-next-line react/prop-types
const LogoIMG = ({ className, ...rest }) => {
  return <LogoSEED className={className} {...rest} src={Logo}></LogoSEED>;
};

export default LogoIMG;
