/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import Container from "../Container50";
import LogoIMG from "../Banner";
import { Context } from "../../context/loginContext";
import { Sidebar as SidebarMobile } from "primereact/sidebar";
import { Link, NavLink, Redirect } from "react-router-dom";
import styled from "styled-components";
import { PageContext } from "../../context/pageContext";
import IconeLogo from "../../assets/seed-ap-secretaria-de-estado-de-educacao-do-amapa-removebg-preview(1).png";
import { ThemeContext } from "styled-components";
import "./style.css";

const LinkSide = styled(Link)`
  display: flex;
  gap: 10px;
  background-color: ${(props) => props.bgcolor};
  align-items: center;
  height: 40px;
  text-align: left;
  font-family: Poppins;
  width: ${(props) => props.width};
  border-left: ${(props) => props.borderl};
  border-radius: 0px 5px 5px 0px;
  color: ${(props) => props.textcolor};
  text-decoration: none;
  font-size: 1.4rem;
  padding: 0.5rem;
  transition: 500ms ease-in-out;
  &:hover {
    text-decoration: none;
    color: white;
    background-color: #2684ff;
  }
  @media (max-height: 810px) {
    font-size: 1rem;
    padding: 0.2rem;
  }
  @media (max-width: 1500px) {
    font-size: 1rem;
    padding: 0.2rem;
  } ;
`;

const LogoutButton = styled.p`
  display: grid;
  grid-template-columns: 35px 1fr;
  background-color: ${(props) => props.bgcolor};
  align-items: center;
  text-align: left;
  font-family: Poppins;
  width: ${(props) => props.width};
  border-left: ${(props) => props.borderl};
  border-radius: 0px 5px 5px 0px;
  color: ${(props) => props.textcolor};
  text-decoration: none;
  font-size: 1.4rem;
  padding: 0.5rem;
  &:hover {
    text-decoration: none;
    color: white;
    background-color: #2684ff;
  }
  @media (max-height: 810px) {
    font-size: 1rem;
    padding: 0.2rem;
  } ;
`;

const Span = styled.span`
  -webkit-transform: translate(${(props) => props.translate});
  -webkit-transform: translate(${(props) => props.translate});
  -moz-transform: translate(${(props) => props.translate});
  -ms-transform: translate(${(props) => props.translate});
  -o-transform: translate(${(props) => props.translate});
  transform: translate(${(props) => props.translate});
  -webkit-transform: rotate(${(props) => props.rotate});
  -webkit-transform: rotate(${(props) => props.rotate});
  -moz-transform: rotate(${(props) => props.rotate});
  -ms-transform: rotate(${(props) => props.rotate});
  -o-transform: rotate(${(props) => props.rotate});
  transform: rotate(${(props) => props.rotate});
  transition: 500ms ease-in-out;
`;

const Img = styled.img`
  place-self: ${(props) => props.self};
  justify-self: ${(props) => props.justify};
  align-self: ${(props) => props.align};
  -webkit-transform: translate(${(props) => props.translate});
  -webkit-transform: translate(${(props) => props.translate});
  -moz-transform: translate(${(props) => props.translate});
  -ms-transform: translate(${(props) => props.translate});
  -o-transform: translate(${(props) => props.translate});
  transform: translate(${(props) => props.translate});
  width: ${(props) => props.width};
`;

const DivTranslate = styled.div`
  -webkit-transform: translate(${(props) => props.translate});
  -webkit-transform: translate(${(props) => props.translate});
  -moz-transform: translate(${(props) => props.translate});
  -ms-transform: translate(${(props) => props.translate});
  -o-transform: translate(${(props) => props.translate});
  transform: translate(${(props) => props.translate});
  @media (max-height: 810px) {
    -webkit-transform: translate(${(props) => props.translateResp});
    -webkit-transform: translate(${(props) => props.translateResp});
    -moz-transform: translate(${(props) => props.translateResp});
    -ms-transform: translate(${(props) => props.translateResp});
    -o-transform: translate(${(props) => props.translateResp});
    transform: translate(${(props) => props.translateResp});
  }
`;

const itensSideBar = [
  {
    display: "Dashboard",
    icon: <i className="fa-solid fa-chart-line"></i>,
    to: "/user/dashboard",
    section: "dashboard",
  },
  {
    display: "Cadastrar Escola",
    icon: <i className="fa-solid fa-graduation-cap"></i>,
    to: "/user/escola",
    section: "escola",
  },
  {
    display: "Usuários",
    icon: <i className="fa-solid fa-user"></i>,
    to: "/user/usuarios",
    section: "usuarios",
  },
  {
    display: "Parceiros",
    icon: <i className="fa-solid fa-user"></i>,
    to: "/user/parceiros",
    section: "parceiros",
  },
  {
    display: "Relatórios",
    icon: <i className="fa-solid fa-clipboard-list"></i>,
    to: "/user/relatorios",
    section: "relatorios",
  },
  {
    display: "Config. perfil de usuário",
    icon: <i className="fa-solid fa-user-gear"></i>,
    to: "/user/config-user",
    section: "config-user",
  },
  {
    display: "Configurações",
    icon: <i className="fa-solid fa-gear"></i>,
    to: "/user/configuracoes",
    section: "configuraçoes",
  },
];
const SideBar = ({ activeSide, setActiveSide }) => {
  const { active, setActive } = useContext(PageContext);
  const [sideBarExpand, setSideBarExpand] = useState(false);
  const LocationPath = window.location.pathname;
  const { isAdmin, setAdmin, setAuth, dataServerLogin, Login } =
    useContext(Context);
  const { colors } = React.useContext(ThemeContext);
  dataServerLogin ? setAdmin(dataServerLogin.data.userLogin.isAdmin) : null;

  function Logout() {
    if (localStorage.getItem("@epaz:auth") == "oauth") {
      window.location.replace("https://sisged.seed.ap.gov.br");
      window.localStorage.removeItem("@epaz:auth")
      window.localStorage.removeItem("auth")
      window.localStorage.removeItem("dataDash")
      window.localStorage.removeItem("dataServerLogin")
      window.localStorage.removeItem("TypeLogin")
      setAuth(false);
      setAdmin(false);
    } else {
      window.localStorage.removeItem("@epaz:auth")
      window.localStorage.removeItem("dataDash")
      window.localStorage.removeItem("TypeLogin")
      window.localStorage.removeItem("dataPartner");
      window.localStorage.removeItem("dataSchool");
      window.localStorage.removeItem("dataServerLogin");
      window.localStorage.removeItem("dataUserSchool");
      window.localStorage.removeItem("auth");
      setAuth(false);
      setAdmin(false);
      window.location.href = "/";
    }
  }

  return (
    <>
      <Container
        zIndex={"1"}
        minHeight={"600px"}
        width={sideBarExpand ? "17vw" : "5vw"}
        height={"100vh"}
        className="sidebar-desk"
        bgcolor={colors.primary}
        style={{
          position: "relative",
          left: "0",
          transition: "500ms ease-in-out",
          minWidth: "55px",
        }}
      >
        <Container
          width={sideBarExpand ? "17vw" : "5vw"}
          height={"70%"}
          minHeight={"600px"}
        >
          <Container
            heightResp={"150px"}
            height={"225px"}
            justify={"center"}
            align={"center"}
          >
            <Span
              rotate={sideBarExpand ? "180deg" : "0deg"}
              style={{
                fontSize: window.innerHeight > 800 ? "1.5rem" : "1rem",
                position: "absolute",
                right: "1rem",
                top: "1rem",
                color: "white",
                padding: ".5rem",
                cursor: "pointer",
                zIndex: "10",
              }}
              onClick={() => setSideBarExpand((e) => !e)}
              className="fa-solid fa-angles-right"
            ></Span>
            <DivTranslate
              // translate={sideBarExpand ? "0%" : "-80%"}
              style={{
                width: sideBarExpand ? "15vw" : "5vw",
                position: "absolute",
                left: "0rem",
                top: sideBarExpand ? "2rem" : "4rem",
                display: "grid",
                overflow: "hidden",
                transition: "500ms ease-in-out",
              }}
            >
              <DivTranslate
                translate={
                  window.innerHeight > 800 && sideBarExpand
                    ? "-95%"
                    : window.innerHeight <= 800 && sideBarExpand
                    ? "-95%"
                    : "0%"
                }
                translateResp
                style={{
                  height: sideBarExpand ? "90%" : "120%",
                  display: "grid",
                  gridTemplateColumns: "100% 100%",
                  transition: "500ms ease-in-out",
                }}
              >
                <Img
                  self="center"
                  width={window.innerWidth > 1521 ? "90px" : "4vw"}
                  style={{ transition: "500ms ease-in-out" }}
                  src={IconeLogo}
                />
                <LogoIMG
                  self="center"
                  width={sideBarExpand ? "17vw" : "4vw"}
                  widthResp={sideBarExpand ? "9vw" : "50px"}
                  style={{
                    transition: "500ms ease-in-out",
                  }}
                />
              </DivTranslate>
            </DivTranslate>
          </Container>
          <Container
            width={sideBarExpand ? "17vw" : "5vw"}
            height={"500px"}
            align={"start"}
          >
            <Container
              width={sideBarExpand ? "17vw" : "5vw"}
              align={"start"}
              justify={"start"}
              position={"relative"}
              gap={"15px"}
            >
              {itensSideBar.map((item, index) => {
                if (Login == "escola" || Login == "usuarios") {
                  if (
                    item.display == "Dashboard" ||
                    item.display == "Configurações" ||
                    item.display == "Relatórios"
                  ) {
                    return (
                      <div
                        style={{
                          width: sideBarExpand ? "17vw" : "5vw",
                          WebkitTransition: "500ms ease-in-out",
                        }}
                        key={item + index}
                      >
                        <LinkSide
                          width="105%"
                          style={{
                            WebkitTransition: "500ms ease-in-out",
                            minWidth: "58px",
                          }}
                          borderl={() =>
                            window.location.pathname === item.to
                              ? "8px white solid"
                              : null
                          }
                          textcolor={"white"}
                          bgcolor={() =>
                            window.location.pathname === item.to
                              ? "#47D096"
                              : null
                          }
                          onLoad={
                            item.to === LocationPath ? setActive(item) : null
                          }
                          to={item.to}
                          key={item + index}
                        >
                          <div
                            style={{ display: "grid", placeItems: "center" }}
                          >
                            <Span
                              style={{
                                fontSize:
                                  window.innerWidth > 1500 ? "28px" : "20px",
                              }}
                              translate={sideBarExpand ? "0px" : "80%"}
                            >
                              {item.icon}
                            </Span>
                          </div>
                          <Span
                            style={{ width: "250px" }}
                            translate={sideBarExpand ? "0px" : "-150%"}
                          >
                            {" "}
                            {item.display}
                          </Span>
                        </LinkSide>
                      </div>
                    );
                  }
                }
                if (
                  Login == "usuarios" &&
                  (isAdmin == true ||
                    localStorage.getItem("@epaz:auth") == "oauth")
                ) {
                  if (
                    item.display == "Dashboard" ||
                    item.display == "Configurações" ||
                    item.display == "Relatórios" ||
                    item.display == "Servidores" ||
                    item.display == "Cadastrar Escola" ||
                    item.display == "Parceiros"
                  ) {
                    return (
                      <div
                        style={{
                          width: sideBarExpand ? "17vw" : "5vw",
                          WebkitTransition: "500ms ease-in-out",
                        }}
                        key={item + index}
                      >
                        <LinkSide
                          width="105%"
                          style={{
                            WebkitTransition: "500ms ease-in-out",
                            minWidth: "58px",
                          }}
                          borderl={() =>
                            window.location.pathname === item.to
                              ? "8px white solid"
                              : null
                          }
                          textcolor={"white"}
                          bgcolor={() =>
                            window.location.pathname === item.to
                              ? "#47D096"
                              : null
                          }
                          onLoad={
                            item.to === LocationPath ? setActive(item) : null
                          }
                          to={item.to}
                          key={item + index}
                        >
                          <Span translate={sideBarExpand ? "0px" : "80%"}>
                            {item.icon}
                          </Span>
                          <Span translate={sideBarExpand ? "0px" : "-150%"}>
                            {item.display}
                          </Span>
                        </LinkSide>
                      </div>
                    );
                  }
                }
              })}
            </Container>
          </Container>
        </Container>
        <Container position={"relative"} style={{ bottom: "1rem" }}>
          <LogoutButton
            width={sideBarExpand ? "17vw" : "5.4vw"}
            onClick={Logout}
            textcolor={"white"}
          >
            <i
              style={{
                WebkitTransform: `translate(${sideBarExpand ? "0%" : "50%"})`,
                transition: "500ms ease-in-out",
              }}
              className="fa-solid fa-right-from-bracket"
            ></i>
            <Span translate={sideBarExpand ? "0px" : "-170%"}>Logout</Span>
          </LogoutButton>
        </Container>
      </Container>

      <SidebarMobile
        style={{ backgroundColor: colors.container }}
        visible={activeSide}
        onHide={() => setActiveSide(false)}
        icons={() => <img src={IconeLogo} style={{ width: "80px" }} />}
      >
        <div style={{ display: "grid" }}>
          {itensSideBar.map((item, index) => {
            if (Login == "escola" || Login == "usuarios") {
              if (
                item.display == "Dashboard" ||
                item.display == "Configurações" ||
                item.display == "Relatórios"
              ) {
                return (
                  <NavLink
                    className="navLink links"
                    activeClassName="active"
                    activeStyle={{
                      backgroundColor: colors.secundary,
                      color: "#f7f7f7",
                    }}
                    style={{ color: colors.text }}
                    key={item.to + item.icon}
                    to={item.to}
                  >
                    <span className="link-text">
                      {item.icon}
                      {item.display}
                    </span>
                  </NavLink>
                );
              }
            }
            if (
              Login == "usuarios" &&
              (isAdmin == true || localStorage.getItem("@epaz:auth") == "oauth")
            ) {
              if (
                item.display == "Dashboard" ||
                item.display == "Configurações" ||
                item.display == "Relatórios" ||
                item.display == "Servidores" ||
                item.display == "Cadastrar Escola" ||
                item.display == "Parceiros"
              ) {
                return (
                  <NavLink
                    key={item.to + item.display}
                    activeClassName="active"
                    activeStyle={{ backgroundColor: colors.secundary }}
                    className="navLink links"
                    style={{ color: colors.text }}
                    to={item.to}
                  >
                    <span className="link-text">
                      {item.icon}
                      {item.display}
                    </span>
                  </NavLink>
                );
              }
            }
          })}
        </div>
        <LogoutButton
          style={{ cursor: "pointer", color: colors.text }}
          onClick={Logout}
          textcolor={"white"}
        >
          <i
            style={{
              transition: "500ms ease-in-out",
            }}
            className="fa-solid fa-right-from-bracket"
          ></i>
          Logout
        </LogoutButton>
      </SidebarMobile>
    </>
  );
};

export default SideBar;
