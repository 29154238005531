/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled, { ThemeContext } from "styled-components";
import { FileInput, Input, InputMask } from "./Form";
import Container from "./Container50";
import TextStyledComp from "./TextStyled";
import Text from "./Text";
import { Form } from "@unform/web";
import { Checkbox } from "@mui/material";
import { string, object, mixed, ValidationError } from "yup";
import InputTest from "./InputTest";
import { useRef } from "react";
import { toast } from "react-toastify";
import CardReport from "./CardReport";
import axios from "axios";
import { Dialog } from "primereact/dialog";

export default function ModalPartner(props) {
  const [openView, setOpenView] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const formUpdate = React.useRef(null);
  const { colors } = React.useContext(ThemeContext);

  const ModalStyled = styled(Modal)`
    position: "absolute";
    background-color: rgba(0, 0, 0, 0.15);
    overflow: scroll;
  `;
  const BoxStyle = styled(Box)`
    display: grid;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    min-height: 50vh;
    height: ${(props) => props.height};
    max-height: 680px;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 2rem;
    transition: 500ms ease-in-out;
    overflow: ${(props) => props.overflow};
  `;

  const ButtonStyled = styled.button`
    font-size: ${(props) => props.fontSize};
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.color};
    padding: ${(props) => props.padding};
    border-radius: 3px;
    transition: 100ms ease-in-out;
    &:hover {
      background-color: ${(props) =>
        props.btnHoverBg ? props.btnHoverBg : "#2194FF"};
      color: ${(props) => props.btnHoverColor};
      transform: scale(1.02);
    }
  `;

  const ButtonModal = styled.button`
    display: ${(props) => props.display};
    color: ${(props) => props.color};
    place-items: center;
    background-color: ${(props) => props.bgcolor};
    font-size: ${(props) => props.fontSize};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    justify-self: ${(props) => props.justify};
    border-radius: 3px;
    margin: 5px 10px;
    transition: 100ms ease-in-out;
    &:hover {
      color: ${(props) => props.colorhover};
      background-color: ${(props) => props.bgcolorhover};
    }
    @media (max-height: 800px) {
      width: ${(props) => props.widthResp};
      height: ${(props) => props.heightResp};
      font-size: ${(props) => props.fontSizeResp};
    }
  `;

  async function handlerSubmit(data) {
    async function submitData() {
      setOpenView(false);

      var formdata = new FormData();
      formdata.append("nameOrgan", data.nameOrgan);
      formdata.append("nameProject", data.nameProject);
      formdata.append("managerName", data.managerName);
      formdata.append("telephone", data.telephone);
      formdata.append("email", data.email);
      data.tct_file[0]
        ? formdata.append("file", data.tct_file[0], data.tct_file[0].name)
        : null;

      let headersList = {
        Accept: "*/*",
        "Content-Type": "multipart/form-data",
      };

      let reqOptions = {
        url: `${process.env.REACT_APP_API_URL}/partner/${props.item.id}`,
        method: "PUT",
        data: formdata,
        headers: headersList,
      };

      let response;

      checked
        ? await toast
            .promise(axios.request(reqOptions), {
              pending: "Atualizando Parceiro",
              success: "Atualizado com sucesso",
              error: {
                render({ data }) {
                  return data.response.data.includes("<")
                    ? `${data.message}`
                    : `${data.response.data}`;
                },
              },
            })
            .then((responseRequest) => {
              props.insertDataRequest();
              response = responseRequest;
            })
        : toast.error("Habilite a caixinha para atualizar");
      props.insertDataRequest();
    }

    try {
      let schema = data.tct_file[0]
        ? object({
            nameOrgan: string()
              .min(2, "No minimo 3 caracteres")
              .required("Preencha com nome do orgão"),
            nameProject: string().required("Preencha com o nome do projeto"),
            email: string()
              .email("Digite um email valido")
              .min(4, "No minimo 4 caracteres")
              .required("É obrigatorio o email"),
            telephone: string()
              .min(6, "Digite um número válido")
              .required("Digite o Telefone"),
            managerName: string()
              .min(2, "Digite um nome válido")
              .required("O nome é obrigatorio"),
            tct_file: mixed().test({
              message: "Formato de arquivo não suportado, inserir apenas .pdf",
              test: (file, context) => {
                const isValid = file[0].name.includes(".pdf");
                if (!isValid) context?.createError();
                return isValid;
              },
            }),
          })
        : object({
            nameOrgan: string()
              .min(2, "No minimo 3 caracteres")
              .required("Preencha com nome do orgão"),
            nameProject: string().required("Preencha com o nome do projeto"),
            email: string()
              .email("Digite um email valido")
              .min(4, "No minimo 4 caracteres")
              .required("É obrigatorio o email"),
            telephone: string()
              .min(6, "Digite um número válido")
              .required("Digite o Telefone"),
            managerName: string()
              .min(2, "Digite um nome válido")
              .required("O nome é obrigatorio"),
          });
      console.log(schema);
      await schema
        .validate(data)
        .then(() => {
          submitData();
        })
        .catch((err) => {
          console.log(err);
          null;
        });
      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof ValidationError) {
        const errorMensages = {};

        err.inner.forEach((error) => {
          errorMensages[error.path] = error.message;
        });
        formUpdate.current.setErrors(errorMensages);
      }
    }
  }

  return (
    <React.Fragment>
      <ButtonStyled
        bgColor={props.bgColor}
        color={props.color}
        padding={props.padding}
        fontSize={props.fontSize}
        btnHoverBg={props.btnHoverBg}
        btnHoverColor={props.btnHoverColor}
        style={{cursor: "pointer"}}
        onClick={() => setOpenView(true)}
      >
        {props.placeholderBtn}
      </ButtonStyled>
      <Dialog
        visible={openView}
        onHide={() => setOpenView(false)}
        header="Parceiro"
        contentStyle={{ backgroundColor: colors.container, color: colors.text }}
        headerStyle={{ backgroundColor: colors.container, color: colors.text }}
      >
        <div
          style={{
            height: "65vh",
            overflow: "scroll",
            minWidth: "340px",
            width: "30vw",
            maxWidth: "500px",
            borderTop: "3px solid #2684FF",
          }}
        >
          <Container>
            <div style={{ marginBottom: "1rem" }}>
              <Checkbox
                style={{ left: "-10px" }}
                checked={checked}
                inputProps={{ "aria-label": "controlled" }}
                onChange={(event) => {
                  setChecked(event.target.checked);
                }}
              />{" "}
              <TextStyledComp fontSize=".9rem">
                Desejo alterar as informações
              </TextStyledComp>
            </div>
            <Form ref={formUpdate} onSubmit={handlerSubmit}>
              <Container gap="15px">
                <div>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                    }}
                  >
                    Nome do orgão
                  </Text>
                  <Input
                    height="40px"
                    bgColor={colors.background}
                    color={colors.text}
                    fontSize="15px"
                    name="nameOrgan"
                    defaultValue={props.item.nameOrgan}
                    disabled={!checked}
                  />
                </div>
                <div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Nome do projeto
                  </TextStyledComp>
                  <Input
                    bgColor={colors.background}
                    color={colors.text}
                    height="40px"
                    fontSize="15px"
                    name="nameProject"
                    defaultValue={props.item.nameProject}
                    disabled={!checked}
                  />
                </div>
                <div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Nome do Gestor
                  </TextStyledComp>
                  <Input
                    bgColor={colors.background}
                    color={colors.text}
                    height="40px"
                    fontSize="15px"
                    name="managerName"
                    defaultValue={props.item.managerName}
                    disabled={!checked}
                  />
                </div>
                <div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Email
                  </TextStyledComp>
                  <Input
                    bgColor={colors.background}
                    color={colors.text}
                    height="40px"
                    fontSize="15px"
                    name="email"
                    defaultValue={props.item.email}
                    disabled={!checked}
                  />
                </div>
                <div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Telefone
                  </TextStyledComp>
                  <InputMask
                    style={{
                      height: "40px",
                      width: "97%",
                      paddingLeft: ".5rem",
                      border: "1px #CCC solid",
                      borderRadius: "4px",
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    name="telephone"
                    mask="(99) 9 9999-9999"
                    value={props.item.telephone}
                    disabled={!checked}
                  />
                </div>
                <div>
                  <TextStyledComp
                    style={{ position: "relative", marginRight: "1rem" }}
                    translateX="50px"
                    fontSize="0.85rem"
                    textcolor={colors.text}
                  >
                    Possui TCT, ou deseja alterar?
                  </TextStyledComp>
                </div>
                <div>
                  <FileInput
                    disabled={!checked}
                    name="tct_file"
                    accept=".pdf"
                  />
                </div>
                {props.item.tct_file && (
                  <>
                    <TextStyledComp
                      style={{ position: "relative", marginTop: "1rem" }}
                      fontSize="0.85rem"
                      textcolor="#666"
                    >
                      TCT:
                    </TextStyledComp>
                    <CardReport
                      fontSize="15px"
                      name={props.item.tct_file.split("-")[1]}
                      url={`${process.env.REACT_APP_API_URL}/files/${props.item.tct_file}`}
                      colors={colors}
                    />
                  </>
                )}
              </Container>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "flex-end",
                  alignContent: "center",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignContent: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <TextStyledComp
                    style={{
                      alignSelf: "center",
                      height: "35px",
                      width: "20%",
                      textAlign: "center",
                      cursor: "pointer",
                      display: "grid",
                      placeItems: "center",
                      borderRadius: "8px"
                    }}
                    justify={"end"}
                    bgcolor={"#EFEFEF"}
                    textcolor={"#222"}
                    fontSize={"1rem"}
                    colorhover={"#FFF"}
                    bgcolorhover={"#0065FF"}
                    onClick={() => setOpenView(false)}
                  >
                    Voltar
                  </TextStyledComp>
                  <ButtonModal
                    type="submit"
                    display={"grid"}
                    justify={"start"}
                    bgcolor={"#36B37E"}
                    color={"white"}
                    height={"35px"}
                    heightResp={"30px"}
                    width={"150px"}
                    fontSize={"1rem"}
                    bgcolorhover={"#008453"}
                    style={{borderRadius: "8px", cursor: "pointer"}}
                  >
                    Atualizar
                  </ButtonModal>
                </div>
              </div>
            </Form>
          </Container>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
