/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { LoginContext, Context } from "../context/loginContext";
import LPTipoAcesso from "../pages/LPTipoAcesso";
// import PageCadastro from "../pages/PageCadastro";
import PageLogin from "../pages/PageLogin";
import { ThemeContext } from "styled-components";
import { ContextPage, PageContext } from "../context/pageContext";
import DynamicPage from "../pages/DynamicPage";
import { AuthProvider } from "../hooks/auth";
import Callback from "../pages/Callback";
import OAuth from "../pages/OAuth";

const PrivateRoute = ({ component: Component, toggleTheme, ...rest }) => {
  let { auth } = React.useContext(Context);
  const { colors, font, title } = React.useContext(ThemeContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          <Component
            {...props}
            toggleTheme={toggleTheme}
            colors={colors}
            font={font}
            title={title}
          />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default function Routes({ toggleTheme }) {
  const { colors, font, title } = React.useContext(ThemeContext);

  return (
    <div style={{backgroundColor: colors.background}}>
      <Switch>
        <Route exact path="/callback" component={Callback} />
        <Route exact path="/oauth" component={OAuth} />
        <LoginContext>
          <Route
            exact
            path="/"
            render={(props) => (
              <LPTipoAcesso
                {...props}
                colors={colors}
                font={font}
                title={title}
                toggleTheme={toggleTheme}
              />
            )}
          />
          <AuthProvider>
            <Route
              exact
              path="/login"
              render={(props) => (
                <PageLogin
                  {...props}
                  colors={colors}
                  font={font}
                  title={title}
                />
              )}
            />
          </AuthProvider>
          <ContextPage>
            <PrivateRoute
              exact
              path="/user/dashboard"
              component={DynamicPage}
              toggleTheme={toggleTheme}
            />
            <PrivateRoute
              exact
              path="/user/escola"
              component={DynamicPage}
              toggleTheme={toggleTheme}
            />
            <PrivateRoute
              exact
              path="/user/parceiros"
              component={DynamicPage}
              toggleTheme={toggleTheme}
            />
            <PrivateRoute
              exact
              path="/user/opcoes"
              component={DynamicPage}
              toggleTheme={toggleTheme}
            />
            <PrivateRoute
              exact
              path="/user/relatorios"
              component={DynamicPage}
              toggleTheme={toggleTheme}
            />
            <PrivateRoute
              exact
              path="/user/usuarios"
              component={DynamicPage}
              toggleTheme={toggleTheme}
            />
            <PrivateRoute
              exact
              path="/user/notacess"
              component={DynamicPage}
              toggleTheme={toggleTheme}
            />
            <PrivateRoute
              exact
              path="/user/configuracoes"
              component={DynamicPage}
              toggleTheme={toggleTheme}
            />
          </ContextPage>
        </LoginContext>
      </Switch>
    </div>
  );
}
