import React, { useContext } from "react";
import Container from "./Container50";
import TextStyledComp from "./TextStyled";
import LogoIMG from "./Banner";
import { Context } from "../context/loginContext";

// import { Redirect } from "react-router-dom";

const NotAcess = () => {
  const { isAdmin } = useContext(Context);
  if (isAdmin == false || isAdmin == undefined) {
    return (
      <Container templateRows="50vh 10vh" placeItens="center">
        <LogoIMG widthResp="380px" />
        <TextStyledComp
          display="grid"
          columns="50px 1fr"
          alignItens="center"
          fontSizeResp="22px"
          fontSize="25px"
          textcolor="red"
          gap="20px"
        >
          <TextStyledComp
            fontSize="50px"
            className="fa-solid fa-triangle-exclamation"
          ></TextStyledComp>
          <div>Você não tem acesso a essa parte</div>
        </TextStyledComp>
      </Container>
    );
  }
};

export default NotAcess;
