/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { Form } from "@unform/web";
import { ThemeContext } from "styled-components";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { Ripple } from "primereact/ripple";
import { InputText } from "primereact/inputtext";
import { addLocale, locale, FilterMatchMode } from "primereact/api";
import { classNames } from 'primereact/utils'

// import DataTable from "../DataTable";
import InputStyled from "../InputDeBusca";
// import Select from "../components/Select";
import { Input, Select, Texterea, TextereaStyle } from "../Form/index";
import FormView from "../FormView";

import Pagination from "../Pagination";
import ModalRegisterEvent from "../ModalRegisterEvent/index.js";

import "./style.css";
import Container from "../Container50";
import styled from "styled-components";
import TextStyledComp from "../TextStyled";
import { Context } from "../../context/loginContext";
import { PageContext } from "../../context/pageContext";
import api from "../../services/api";
import Button from "../Button";
import { padding } from "@mui/system";
import { Tooltip } from "primereact/tooltip";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    zIndex: "999",
    width: window.innerWidth >= 1780 ? "100px" : "80px",
    overflow: "none",
  }),
  menuList: (provided, state) => ({
    ...provided,
    overflow: "none",
  }),
};
const customStylesSchool = {
  container: (provided, state) => ({
    ...provided,
    zIndex: "999",
    width: window.innerWidth >= 1780 ? "120px" : "100px",
    overflow: "none",
  }),
  menuList: (provided, state) => ({
    ...provided,
    height: "150px",
    overflow: "none",
  }),
};

const DotStatus = styled.div`
  border-width: 3px;
  border-radius: 100%;
  background-color: ${(props) => props.backgroundColor};
  width: 12px;
  height: 12px;
  align-self: center;
`;

const TooltipStyled = styled(Tooltip)`
  background-color: ${(props) => props.backgroundColor};
  .p-tooltip-text {
    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};
  }
  .p-tooltip-arrow {
    background-color: ${(props) => props.backgroundColor};
  }
`;

const ButtonStyled = styled(Button)`
  display: ${(props) => props.display};
  color: ${(props) => props.color};
  place-items: center;
  background-color: ${(props) => props.bgcolor};
  font-size: ${(props) => props.fontSize};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  justify-self: ${(props) => props.justify};
  border-radius: 3px;
  transition: 100ms ease-in-out;
  &:hover {
    color: ${(props) => props.colorhover};
    background-color: ${(props) => props.bgcolorhover};
  }
  @media (max-height: 800px) {
    width: ${(props) => props.widthResp};
    height: ${(props) => props.heightResp};
    font-size: ${(props) => props.fontSizeResp};
`;

const DataTableStyle = styled(DataTable)`
  .p-paginator.p-component.p-paginator-bottom {
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    justify-content: flex-start;
  }
  .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: ${(props) => props.colorIcon} !important;
  }
  .p-sortable-column .p-sortable-column-icon {
    color: #555;
  }
  .p-datatable-tbody tr {
    background-color: ${(props) => props.rowsColor};
  }
  .p-datatable-tbody tr.p-row-odd {
    background-color: ${(props) => props.rowStripedColor} !important;
  }
  .p-paginator-page.p-paginator-element.p-link {
    background-color: ${(props) => props.backgroundButtonPaginate};
    color: ${(props) => props.colorButtonPaginate};
    border-radius: 8px;
    min-width: 0rem;
    padding: 0;
    height: 31px;
    width: 31px;
  }
  .p-paginator-page.p-paginator-element.p-link.p-highlight {
    background-color: ${(props) => props.backgroundButtonPaginateActive};
    color: ${(props) => props.colorButtonPaginateActive};
  }
`;

const Dashboard = () => {
  //Remember refactore this
  let { dataDash, setDataDash, atualiza, loading, setLoading } =
    useContext(PageContext);

  // colors theme
  const { colors } = React.useContext(ThemeContext);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [first1, setFirst1] = React.useState(0);
  const [rows, setRows1] = useState(10);
  const [datefilter, setDatefilter] = useState(undefined);
  const [statusData, setStatusData] = useState(null);
  const [showStatus, setShowStatus] = useState(false);

  const [load, setLoad] = useState(false);

  const [pageInputTooltip, setPageInputTooltip] = React.useState(
    "Pressione ENTER para ir para esta página."
  );

  //LoginPage stores data the respect of login
  const LoginPagePermissions = React.useContext(Context);
  let id_school = {
    value: LoginPagePermissions.dataSchool
      ? LoginPagePermissions.dataSchool.data.userLogin.id
      : null,
  };

  // Stores the data value of the request
  let [data, setData] = React
    .useState
    // (data) =>
    // localStorage.getItem("dataDash")
    //   ? JSON.parse([localStorage.getItem("dataDash")])
    //   : data
    //   ? data
    //   : []
    ();

  //Requisition related
  let [event, setEvent] = React.useState(null);
  let [school, setSchool] = React.useState(
    LoginPagePermissions.Login == "escola" ? id_school : null
  );
  let [visible, setVisible] = React.useState(false);
  let [historyStatus, setHistoryStatus] = React.useState({
    status: "Não concluido",
    history: [
      { status: "Não concluido", description: "Acabou de ser criado" },
      {
        status: "Dificuldade na conclusão",
        description: "Dificuldade na conclusão",
      },
      { status: "Concluido", description: "Concluido" },
    ],
  });
  let [selectedStatus, setSelectedStatus] = React.useState("Não concluido");
  let [paginationData, setPaginationData] = React.useState({
    currentPage: 1,
    lastPage: 3,
  });
  let [schools, setSchools] = React.useState(null);
  let [initialDate, setInicitalDate] = React.useState(null);
  let [finalDate, setFinalDate] = React.useState(null);
  let [page, setPage] = React.useState(1);
  let [quantityRegisters, setQuantity] = React.useState({
    value: 10,
    label: 10,
  });

  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let yyyy = today.getFullYear();
  today = `${yyyy}-${mm}-${dd}`;

  //Options related of the create ocorrence form
  let [options, setOptions] = React.useState({
    breed: [
      { value: "Pardo", label: "Pardo" },
      { value: "Branco", label: "Branco" },
      { value: "Preto", label: "Preto" },
      { value: "Amarelo", label: "Amarelo" },
    ],
    partners: null,
    series_taken: [
      { value: "8° Ano", label: "8° Ano" },
      { value: "9° Ano", label: "9° Ano" },
      { value: "1° Ano (Ensino Médio)", label: "1° Ano (Ensino Médio)" },
      { value: "2° Ano (Ensino Médio)", label: "2° Ano (Ensino Médio)" },
      { value: "3° Ano (Ensino Médio)", label: "3° Ano (Ensino Médio)" },
    ],
    events: [
      { value: "Ameaça direta", label: "Ameaça direta" },
      { value: "Ameaça indireta", label: "Ameaça indireta" },
      { value: "Assédio sexual", label: "Assédio sexual" },
      { value: "Briga", label: "Briga" },
      { value: "Bullying", label: "Bullying" },
      { value: "Dano ao patrimonio", label: "Dano ao patrimonio" },
      { value: "Difamação", label: "Difamação" },
      { value: "Intimidação", label: "Intimidação" },
      { value: "Isolamento", label: "Isolamento" },
      { value: "Ferimentos recentes", label: "Ferimentos recentes" },
      { value: "Crises de pânico", label: "Crises de pânico" },
      { value: "Crises de ansiedade", label: "Crises de ansiedade" },
      { value: "Falta de ar", label: "Falta de ar" },
      { value: "Tentativa de suicídio", label: "Tentativa de suicídio" },
      {
        value: "Autolesão praticada na escola",
        label: "Autolesão praticada na escola",
      },
      {
        value: "Autolesão não praticada na escola",
        label: "Autolesão não praticada na escola",
      },
      {
        value: "Porte ou uso de arma",
        label: "Porte ou uso de arma",
      },
      {
        value: "Agressão física com brigas de menor potencial agressivo",
        label: "Agressão física com brigas de menor potencial agressivo",
      },
      {
        value: "Agressão física com lesão corporal",
        label: "Agressão física com lesão corporal",
      },
      {
        value: "Consumo ou tráfico de drogas",
        label: "Consumo ou tráfico de drogas",
      },
      {
        value:
          "Ameaças a integridade física ou psicológica a algum estudante ou profissional da educação",
        label:
          "Ameaças a integridade física ou psicológica a algum estudante ou profissional da educação",
      },
      {
        value: "Furtos ou roubos",
        label: "Furtos ou roubos",
      },
      {
        value: "Danos ao patrimônio",
        label: "Danos ao patrimônio",
      },
      {
        value: "Abuso sexual",
        label: "Abuso sexual",
      },
      {
        value: "Abuso físico",
        label: "Abuso físico",
      },
      {
        value: "Abandono familiar",
        label: "Abandono familiar",
      },
      {
        value: "Mudanças repentinas de humor e comportamento",
        label: "Mudanças repentinas de humor e comportamento",
      },
      {
        value: "Tristeza que perdura por vários dias ou semanas",
        label: "Tristeza que perdura por vários dias ou semanas",
      },
      {
        value: "Mudanças físicas inexplicáveis, como perda ou ganho de peso",
        label: "Mudanças físicas inexplicáveis, como perda ou ganho de peso",
      },
      {
        value: "Queda repentina no desempenho escolar",
        label: "Queda repentina no desempenho escolar",
      },
      {
        value:
          "Mudanças nos hábitos sociais, como afastamento ou isolamento de amigos e familiares",
        label:
          "Mudanças nos hábitos sociais, como afastamento ou isolamento de amigos e familiares",
      },
      {
        value:
          "Incapacidade de realizar tarefas diárias (como higiene e autocuidado básico);",
        label:
          "Incapacidade de realizar tarefas diárias (como higiene e autocuidado básico);",
      },
      {
        value: "Ameaças verbais e agressividade repentina",
        label: "Ameaças verbais e agressividade repentina",
      },
      {
        value: "Dormir muito ou pouco",
        label: "Dormir muito ou pouco",
      },
      {
        value: "Ter dores inexplicáveis e/ou constantes",
        label: "Ter dores inexplicáveis e/ou constantes",
      },
      {
        value: "Agitação, inquietação, angústia ou comportamento de pânico",
        label: "Agitação, inquietação, angústia ou comportamento de pânico",
      },
      {
        value: "Falar ou escrever sobre cometer suicídio",
        label: "Falar ou escrever sobre cometer suicídio",
      },
      {
        value:
          "Expressar sentimentos de fracasso, inutilidade ou perda de esperança",
        label:
          "Expressar sentimentos de fracasso, inutilidade ou perda de esperança",
      },
      {
        value: "Atrasos ou faltas frequentes",
        label: "Atrasos ou faltas frequentes",
      },
      {
        value:
          "Usar roupas que cobrem o corpo em clima quente, com o intuito de esconder lesões",
        label:
          "Usar roupas que cobrem o corpo em clima quente, com o intuito de esconder lesões",
      },
      {
        value: "Não querer fazer atividades físicas",
        label: "Não querer fazer atividades físicas",
      },
      {
        value:
          "Atos contínuos de incivilidade (desrespeito e/ou bullying) e/ou indisciplina",
        label:
          "Atos contínuos de incivilidade (desrespeito e/ou bullying) e/ou indisciplina",
      },
      {
        value: "Indução a autolesão ou ao suicídio",
        label: "Indução a autolesão ou ao suicídio",
      },
      {
        value: "Abuso psicológico",
        label: "Abuso psicológico",
      },
      {
        value: "Gravidez na adolescência",
        label: "Gravidez na adolescência",
      },
      {
        value: "Cyberbullying",
        label: "Cyberbullying",
      },
      {
        value: "Revitimização",
        label: "Revitimização",
      },
      {
        value: "Outros (que não for competência da escola)",
        label: "Outros (que não for competência da escola)",
      },
    ],
    progress: [
      { value: "Conselho tutelar", label: "Conselho tutelar" },
      { value: "Família", label: "Família" },
      { value: "CAPS", label: "CAPS" },
      { value: "CVEDUC", label: "CVEDUC" },
      { value: "Polícia", label: "Polícia" },
      { value: "SAMU", label: "SAMU" },
      { value: "Outros", label: "Outros" },
    ],
    sex: [
      { value: "Masculino", label: "Masculino" },
      { value: "Feminino", label: "Feminino" },
    ],
  });

  //Função de solicitação de dados com filtros ou sem
  async function insertDataRequest() {
    setLoad(true);
    setDataDash();

    let response;
    let queryString = `?Id_school${school ? `=${school.value}` : ""}`;
    LoginPagePermissions.Login != "escola"
      ? (response = await axios.get(
          `${process.env.REACT_APP_API_URL}/occurrence${queryString}`
        ))
      : (response = await axios.get(
          `${process.env.REACT_APP_API_URL}/occurrence${queryString}`
        ));

    paginationData.currentPage = response.data.currentPage;
    paginationData.lastPage = response.data.lastPage;
    let partners = await api.get(`${process.env.REACT_APP_API_URL}/partner`);
    partners = partners.data.data.map((item) => {
      return { value: item.id, label: item.nameOrgan };
    });
    options.partners = partners;

    function ascendingSort(a, b) {
      a = {
        ...a,
        date_time_occurrence: `${a.date_occurrence}T${a.time_occurrence}`,
      };
      b = {
        ...b,
        date_time_occurrence: `${b.date_occurrence}T${b.time_occurrence}`,
      };
      return a.date_time_occurrence > b.date_time_occurrence;
    }
    let dataOccurence;
    dataOccurence = response.data.data.sort(ascendingSort);
    setData();
    localStorage.setItem("dataDash", JSON.stringify(dataDash));
    setDataDash(() =>
      response.data.data.map((item) => {
        let date = new Date(`${item.date_occurrence}T${item.time_occurrence}`);
        return {
          ...item,
          status_id: String(item?.Status?.map((item) => item.id)),
          current_status: String(item?.Status?.map((item) => item.status)),
          history: item?.Status.map((item) => {
            return [
              {
                description: item?.not_completed,
                name: "not_completed",
                status: "Não Concluído",
              },
              {
                description: item?.difficulty_completion,
                name: "difficulty_completion",
                status: "Dificuldade na conclusão",
              },
              {
                description: item?.completed,
                name: "completed",
                status: "Concluído",
              },
            ];
          }).flat(1),
          data_time_occurrence: date,
        };
      })
    );
    setOptions(options);
    setLoad(false);
  }

  React.useEffect(() => {
    setLoading(true);
    async function schoolsListOptions() {
      let request = await api
        .get(`${process.env.REACT_APP_API_URL}/school?NameSchool`)
        .then()
        .catch((response) => toast.error("Erro ao buscar opções de escolas"));
      request
        ? (schools = request.data.data.map((item) => {
            return { value: item.id, label: item.nameSchool };
          }))
        : null;
      setSchools([{ value: "", label: "Todos" }, ...schools]);
    }
    schoolsListOptions();
    insertDataRequest();
    setLoading(false);
  }, [page]);

  const optionsStatus = [
    {
      label: "Não Concluído",
      value: "Não Concluído",
    },
    { label: "Dificuldade na conclusão", value: "Dificuldade na conclusão" },
    { label: "Concluído", value: "Concluído" },
  ];

  const handlerStatus = React.useCallback((rowData) => {
    setShowStatus(true);
    setStatusData({
      status_id: rowData.status_id,
      status: rowData.current_status,
      history: rowData.history,
    });
  });

  const handlerUpdateStatus = React.useCallback((data) => {
    const { not_completed, completed, difficulty_completion, status } = data;

    const bodyData = {
      not_completed,
      completed,
      difficulty_completion,
      status,
    };

    async function updateStatus() {
      let reqOptions = {
        url: `${process.env.REACT_APP_API_URL}/status/${data.id}`,
        method: "PUT",
        data: bodyData,
      };

      await toast
        .promise(axios.request(reqOptions), {
          pending: {
            render() {
              return "Atualizando status da ocorrência";
            },
          },
          success: "Atualizado com sucesso",
          error: {
            render({ data }) {
              return `${data.message}`;
            },
          },
        })
        .then((res) => {
          statusData.history = [
            {
              description: res?.data?.not_completed,
              name: "not_completed",
              status: "Não Concluído",
            },
            {
              description: res?.data?.difficulty_completion,
              name: "difficulty_completion",
              status: "Dificuldade na conclusão",
            },
            {
              description: res?.data?.completed,
              name: "completed",
              status: "Concluído",
            },
          ];
          statusData.status = res?.data.status;
          setStatusData((e) => ({ ...e }));
          insertDataRequest();
        });
    }

    updateStatus();
  });

  // Custom table -----------------------------------------------------------------

  const proprietsStatus = {
    "Não Concluído": {
      label: "#937F2D",
      background: "#FFF0B3",
      description:
        "(Não Concluído) As tratativas com os parceiros ainda estão em fase inicial",
    },
    "Dificuldade na conclusão": {
      label: "#BF2600",
      background: "#FFEBE6",
      description:
        "(Dificuldade na conclusão) Houve alguma dificuldade de comunicação com os parceiros",
    },
    Concluído: {
      label: "#006644",
      background: "#E3FCEF",
      description: "(Concluído) A situação foi solucionada",
    },
  };

  // Body Status
  const bodyStatus = (rowData) => {
    return (
      <p
        onClick={() => handlerStatus(rowData)}
        style={{
          backgroundColor:
            proprietsStatus[rowData?.current_status]?.background ||
            "transparent",
          borderRadius: "100px",
          padding: "2px 8px",
          width: "90%",
          fontSize: "16px",
          color:
            proprietsStatus[rowData?.current_status]?.label || "transparent",
          cursor: "pointer",
          textAlign: "center",
        }}
      >
        {rowData.current_status}
      </p>
    );
  };

  // Date body on table
  const dateBodyTemplate = (rowData) => {
    let [year, month, day] = rowData.date_occurrence.split("-");
    return `${day}/${month}/${year} - ${rowData.time_occurrence}`;
  };

  // Calendar on filter and locale
  addLocale("BR", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "segunda",
      "terça",
      "quarta",
      "quinta",
      "sexta",
      "sábado",
    ],
    dayNamesShort: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
    dayNamesMin: ["D", "S", "T", "Q", "Q", "S", "S"],
    monthNames: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    monthNamesShort: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    today: "Hoje",
    apply: "Aplicar",
    clear: "Limpar",
    addRule: "Adicionar filtro",
    removeRule: "Remover filtro",
    matchAll: "Todos corresponderem",
    matchAny: "Qualquer um corresponder",
  });
  locale("BR");

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
        locale="BR"
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  // Progress data on table
  const progressBodyTemplate = (rowData) => {
    const progress = rowData.progress_text.split(" ");
    progress.length = 2;
    return (
      <React.Fragment>
        <span className="image-text">{progress.join(" ")}...</span>
      </React.Fragment>
    );
  };

  // Filter Dropdown school
  const schoolRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={schools}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Escola"
        className="p-column-filter"
        maxSelectedLabels={1}
      />
    );
  };

  // Filter Dropdown event
  const eventRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={optionsFilter.events}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Ocorrências"
        className="p-column-filter"
        maxSelectedLabels={1}
      />
    );
  };

  // functions custom pagination

  const onCustomPage = (event) => {
    setFirst1(event.first);
    setRows1(event.rows);
    setCurrentPage(event.page + 1);
  };

  //Pagination comp
  const pagination = {
    layout: "PrevPageLink PageLinks NextPageLink",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span
            className={className}
            style={{ userSelect: "none", borderRadius: "3px" }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: "10px" }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  const optionsBody = (rowdata) => {
    return (
      <>
        {LoginPagePermissions.Login === "escola" && (
          <FormView
            colors={colors}
            icon={"fa-solid fa-pen-to-square"}
            data={rowdata}
            setData={setData}
            options={options}
            permission={LoginPagePermissions.Login}
          />
        )}
        {LoginPagePermissions.Login === "usuarios" && (
          <FormView
            colors={colors}
            disabled={false}
            icon={"fa-solid fa-eye"}
            data={rowdata}
            setData={setData}
            options={options}
            permission={LoginPagePermissions.Login}
            insertData={insertDataRequest}
          />
        )}
      </>
    );
  };

  return (
    <div
      className="Dashboard container-full"
      style={{ backgroundColor: colors.background }}
    >
      <div style={{ position: "fixed" }}>
        <ToastContainer theme="colored" />
      </div>
      <div
        className="container-datatable"
        style={{
          backgroundColor: colors.container,
        }}
      >
        <div className="header-datatable">
          <div
            style={{ color: colors.text }}
            className="quantity-registers-container"
          >
            Mostrar
            <Dropdown
              value={rows}
              options={[
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: "Tudo", value: -1 },
              ]}
              onChange={(e) => setRows1(e.value)}
              className="DropDown"
            />
            registros
          </div>
          <div
            style={{
              display: "grid",
              alignItems: "center",
              padding: "5px",
            }}
          >
            {LoginPagePermissions.Login == "escola" && (
              <ModalRegisterEvent
                intentionModal="Cadastro de Ocorrência"
                options={options}
                updateData={insertDataRequest}
                colors={colors}
              />
            )}
          </div>
        </div>
        <DataTableStyle
          key="id"
          value={dataDash}
          scrollable
          className={`datatable`}
          backgroundColor={colors.container}
          bgColorHeaderFilter={colors.container}
          backgroundButtonPaginate={colors.btn_color}
          colorButtonPaginate={colors.text}
          backgroundButtonPaginateActive={colors.icon_visu}
          colorButtonPaginateActive={"#FFF"}
          rowStripedColor={colors.stripedRow}
          rowsColor={colors.row}
          colorIcon={colors.text}
          scrollDirection="horizontal"
          tableStyle={{ height: "58vh" }}
          scrollHeight="58vh"
          borderColor={colors.border}
          paginator
          paginatorTemplate={pagination}
          stripedRows
          first={first1}
          rows={rows}
          onPage={onCustomPage}
          size="small"
          dataKey="id"
          filterDisplay="menu"
          loading={load}
          emptyMessage="Nenhum dado encontrado."
        >
          <Column
            filter
            sortable
            rowReorderIcon="pi pi-sort"
            filterMatchMode="contains"
            className="colum"
            filterMenuStyle={{
              backgroundColor: colors.container,
              color: colors.text,
            }}
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
            }}
            showFilterMenuOptions={false}
            filterPlaceholder="Escola"
            header="Escola"
            field="School.nameSchool"
          />
          <Column
            filter
            sortable
            filterMatchMode="contains"
            className="colum"
            filterMenuStyle={{
              backgroundColor: colors.container,
              color: colors.text,
            }}
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
            }}
            showFilterMenuOptions={false}
            filterPlaceholder="Ocorrência"
            header="Ocorrência"
            field="event"
          />
          <Column
            filter
            sortable
            filterMenuStyle={{
              color: colors.text,
              backgroundColor: colors.container,
            }}
            filterType="date"
            dataType="date"
            className="colum"
            showFilterOperator={false}
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
              display: "grid",
              justifyItems: "center",
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
              display: "grid",
              justifyItems: "center",
            }}
            header="Data/hora"
            field="data_time_occurrence"
            label="BR"
            filterMatchModeOptions={[
              { label: "Esta data", value: FilterMatchMode.DATE_IS },
              { label: "Sem essa data", value: FilterMatchMode.DATE_IS_NOT },
              {
                label: "Antes dessa data",
                value: FilterMatchMode.DATE_BEFORE,
              },
              {
                label: "Depois dessa data",
                value: FilterMatchMode.DATE_AFTER,
              },
            ]}
            body={dateBodyTemplate}
            filterElement={dateFilterTemplate}
          />
          <Column
            filter
            sortable
            rowReorderIcon="pi pi-sort"
            filterMatchMode="contains"
            className="colum"
            filterMenuStyle={{
              backgroundColor: colors.container,
              color: colors.text,
            }}
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
              display: "grid",
              justifyItems: "center",
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
            }}
            showFilterMenuOptions={false}
            body={bodyStatus}
            filterPlaceholder="Status"
            header="Status"
            field="status"
          />
          <Column
            filter
            sortable
            filterMatchMode="contains"
            className="colum"
            showFilterMenuOptions={false}
            filterPlaceholder="Encaminhamento"
            header="Encaminhamento"
            field="progress_text"
            filterMenuStyle={{
              backgroundColor: colors.container,
              color: colors.text,
            }}
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
            }}
            body={progressBodyTemplate}
          />
          <Column
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
            }}
            style={{ minWith: "6rem", maxWidth: "6rem" }}
            header="Opções"
            body={optionsBody}
          />
        </DataTableStyle>
      </div>
      <Dialog
        header="Historico de Status"
        contentStyle={{
          backgroundColor: colors.container,
          minWidth: "340px",
          width: "40vw",
          maxWidth: "600px",
          height: "100%",
        }}
        headerStyle={{ backgroundColor: colors.container, color: colors.text }}
        visible={showStatus}
        onHide={() => setShowStatus(false)}
      >
        <Form
          style={{ display: "grid", gap: "25px" }}
          onSubmit={handlerUpdateStatus}
        >
          <div style={{ display: "none" }}>
            <Input
              name="id"
              defaultValue={statusData?.status_id}
              type="hidden"
            />
          </div>
          <div style={{ display: "grid", gap: "10px" }}>
            <label
              htmlFor="statusAtualizar"
              style={{ fontWeight: "bold", color: colors.text }}
            >
              Status:
            </label>
            <Select
              options={optionsStatus}
              defaultValue={{
                value: statusData?.status,
                label: statusData?.status,
              }}
              onChange={(e) => {
                statusData.status = e.value;
                setStatusData((e) => ({ ...e }));
              }}
              colors={colors}
              name="status"
              id="statusAtualizar"
              isDisabled={LoginPagePermissions.Login == "usuarios"}
            />
            {statusData?.history?.map((item) => {
              if (item.status == statusData?.status) {
                const classNameTooltip = item.status.split(" ").join("_");
                return (
                  <>
                    <TooltipStyled
                      target={`.${classNameTooltip}`}
                      color={colors.text}
                      backgroundColor={colors.background}
                      position="top"
                    >
                      <p style={{ display: "flex", gap: "10px" }}>
                        <DotStatus
                          backgroundColor={proprietsStatus[item?.status]?.label}
                        ></DotStatus>
                        {proprietsStatus[item?.status]?.description}
                      </p>
                    </TooltipStyled>
                    <div className={classNameTooltip}>
                      <TextereaStyle
                        name={item?.name}
                        defaultValue={item.description}
                        key={item.status_text}
                        backgroundColor={
                          proprietsStatus[item?.status]?.background
                        }
                        color={proprietsStatus[item?.status]?.label}
                        borderColor={proprietsStatus[item?.status]?.label}
                        style={{
                          backgroundColor:
                            proprietsStatus[item?.status]?.background,
                          color: proprietsStatus[item?.status]?.label,
                          borderWidth: `2px`,
                          borderColor: proprietsStatus[item?.status]?.label,
                          width: "100%",
                          resize: "vertical",
                          minHeight: "200px",
                        }}
                        className={classNameTooltip}
                        resize="vertical"
                        width="100%"
                        tooltipOptions={{ showOnDisabled: true }}
                        disabled={LoginPagePermissions.Login == "usuarios"}
                      />
                    </div>
                  </>
                );
              }
            })}
          </div>
          <div>
            <label style={{ fontWeight: "bold", color: colors.text }}>
              Descrição dos status:
            </label>
            <div style={{ display: "grid", gap: "20px" }}>
              {statusData?.history?.map((item) => {
                const classNameTooltip = item.status.split(" ").join("_");

                if (item.status != statusData.status) {
                  return (
                    <>
                      <TooltipStyled
                        target={`.${classNameTooltip}`}
                        color={colors.text}
                        backgroundColor={colors.background}
                        position="top"
                      >
                        <p style={{ display: "flex", gap: "10px" }}>
                          <DotStatus
                            backgroundColor={
                              proprietsStatus[item?.status]?.label
                            }
                          ></DotStatus>
                          {proprietsStatus[item?.status]?.description}
                        </p>
                      </TooltipStyled>
                      <span className={classNameTooltip}>
                        <TextereaStyle
                          name={item?.name}
                          defaultValue={item?.description}
                          key={item?.status_text}
                          backgroundColor={
                            proprietsStatus[item?.status]?.background
                          }
                          color={proprietsStatus[item?.status]?.label}
                          borderColor={proprietsStatus[item?.status]?.label}
                          style={{
                            backgroundColor:
                              proprietsStatus[item?.status]?.background,
                            color: proprietsStatus[item?.status]?.label,
                            borderWidth: `2px`,
                            borderColor: proprietsStatus[item?.status]?.label,
                            width: "100%",
                            resize: "vertical",
                            minHeight: "200px",
                          }}
                          resize="vertical"
                          width="100%"
                          disabled
                        />
                      </span>
                    </>
                  );
                }
              })}
            </div>
          </div>
          {LoginPagePermissions.Login != "usuarios" && (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <ButtonStyled
                bgcolor={"#EFEFEF"}
                color={"#222"}
                bgcolorhover={"#0065FF"}
                height="35px"
                fontSize="17px"
                style={{ borderRadius: "8px" }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowStatus(false);
                }}
              >
                Voltar
              </ButtonStyled>
              <ButtonStyled
                bgcolor={colors.secundary}
                bgcolorhover={"#36B37E"}
                height="35px"
                fontSize="17px"
                style={{ borderRadius: "8px" }}
              >
                Atualizar
              </ButtonStyled>
            </div>
          )}
        </Form>
      </Dialog>
    </div>
  );
};

export default Dashboard;
