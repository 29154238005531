/* eslint-disable react/prop-types */
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { Input, InputMask } from "./Form";
import Container from "./Container50";
import TextStyledComp from "./TextStyled";
import { Form } from "@unform/web";
import Checkbox from "@mui/material/Checkbox";
import { string, object, ValidationError } from "yup";
import Loading from "./animations/loading";
import { toast } from "react-toastify";
import axios from "axios";

const ModalStyled = styled(Modal)`
  position: "absolute";
  background-color: rgba(0, 0, 0, 0.15);
  overflow: scroll;
`;

const BoxStyle = styled(Box)`
  display: grid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: ${(props) => props.height};
  max-height: 800px;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 2rem;
  overflow: ${(props) => props.overflow};
  transition: 500ms ease-in-out;
`;

const ButtonStyled = styled.button`
  font-size: ${(props) => props.fontSize};
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  padding: ${(props) => props.padding};
  border-radius: 3px;
  transition: 100ms ease-in-out;
  &:hover {
    background-color: ${(props) =>
      props.btnHoverBg ? props.btnHoverBg : "#2194FF"};
    color: ${(props) => props.btnHoverColor};
    transform: scale(1.02);
  }
`;

const ButtonModal = styled.button`
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgcolor};
  font-size: ${(props) => props.fontSize};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 3px;
  margin: 5px 10px;
  transition: 100ms ease-in-out;
  &:hover {
    color: ${(props) => props.colorhover};
    background-color: ${(props) => props.bgcolorhover};
  }
  @media (max-height: 800px) {
    width: ${(props) => props.widthResp};
    height: ${(props) => props.heightResp};
    font-size: ${(props) => props.fontSizeResp};
  }
`;
export default function ModalServer(props) {
  const [openView, setOpenView] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [openReset, setOpenReset] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const formRef = React.useRef(null);

  async function handlerReset() {
    setLoading(true);

    let headersList = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${process.env.REACT_APP_API_URL}/resetServer/?id=${props.item.id}`,
      method: "GET",
      headers: headersList,
    };

    await toast
      .promise(axios.request(reqOptions), {
        pending: "Atualizando senha",
        success: {
          render({ data }) {
            props.setNewPassword(data.data.password);
            props.setChangePassword(true);
            return "Senha Atualizada com sucesso";
          },
        },
        error: {
          render({ data }) {
            return data.response.data.includes("<")
              ? `${data.message}`
              : `${data.response.data}`;
          },
        },
      })
      .then((responseRequest) => {
        setLoading(false);
        setTimeout(console.log("helo"), 1000);
        setNewPassword(responseRequest.data.password);
      })
      .catch(() => setLoading(false));
    props.insertData();
  }

  async function handlerChangePassword(data) {
    let inputsValue = data;

    async function submitData() {
      setOpenView(false);

      let data = JSON.stringify({
        password: inputsValue.password,
      });

      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let reqOptions = {
        url: `${process.env.REACT_APP_API_URL}/server/${props.item.id}`,
        method: "PUT",
        data: data,
        headers: headersList,
      };

      await toast
        .promise(axios.request(reqOptions), {
          pending: "Atualizando senha",
          success: "Atualizado com sucesso",
          error: {
            render({ data }) {
              return data.response.data.includes("<")
                ? `${data.message}`
                : `${data.response.data}`;
            },
          },
        })
        .then((responseRequest) => {
          setLoading(false);
          response = responseRequest;
        })
        .catch(() => setLoading(false));
      props.insertData();
    }

    try {
      const schema = object({
        password: string().required("Digite a senha nova"),
      });

      await schema
        .validate(data)
        .then(() => submitData())
        .catch(() => null);
      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof ValidationError) {
        const errorMensages = {};

        err.inner.forEach((error) => {
          errorMensages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMensages);
      }
    }
  }

  return (
    <React.Fragment>
      <ButtonStyled
        bgColor={props.bgColor}
        color={props.color}
        padding={props.padding}
        fontSize={props.fontSize}
        btnHoverBg={props.btnHoverBg}
        btnHoverColor={props.btnHoverColor}
        onClick={() => setOpenView((e) => !e)}
      >
        {props.placeholderBtn}
      </ButtonStyled>
      <ModalStyled
        hideBackdrop
        open={openView}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <BoxStyle height={checked ? "75%" : "55%"} overflow="scroll">
          <Container
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
            position="fixed"
            top=".5rem"
            right="1rem"
            onClick={() => setOpenView(false)}
          >
            X
          </Container>
          <Container
            mb="0rem"
            height="40px"
            width="98%"
            borderB="4px #2684FF solid"
          >
            <TextStyledComp
              fontWeight="bold"
              tag="h3"
              justify="start"
              style={{ marginBottom: ".2rem" }}
            >
              Servidor
            </TextStyledComp>
          </Container>
          <Container mt=".8rem">
            <Form ref={formRef} onSubmit={handlerChangePassword}>
              <Container gap="15px">
                <div>
                  <Input type="hidden" defaultValue={props.item.id} name="id" />
                  <TextStyledComp tag="label" fontWeight="bold">
                    Nome do Servidor
                  </TextStyledComp>
                  <Input
                    height="40px"
                    fontSize="15px"
                    name="fullNameSchool"
                    defaultValue={props.item.name}
                    disabled
                  />
                </div>
                <div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    N° Matrícula
                  </TextStyledComp>
                  <InputMask
                    style={{
                      height: "40px",
                      width: "97%",
                      paddingLeft: ".5rem",
                      border: "1px #CCC solid",
                      borderRadius: "4px",
                    }}
                    fontSize="15px"
                    name="fullNameSchool"
                    mask="999-999-99"
                    defaultValue={props.item.registration}
                    disabled
                  />
                </div>
                <div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Email do servidor
                  </TextStyledComp>
                  <Input
                    height="40px"
                    fontSize="15px"
                    name="email"
                    defaultValue={props.item.email}
                    disabled
                  />
                </div>
                <div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Telefone do servidor
                  </TextStyledComp>
                  <InputMask
                    style={{
                      height: "40px",
                      width: "97%",
                      paddingLeft: ".5rem",
                      border: "1px #CCC solid",
                      borderRadius: "4px",
                    }}
                    name="telephone"
                    mask="(99) 9 9999-9999"
                    defaultValue={props.item.telephone}
                    disabled
                  />
                </div>
                <div>
                  <Checkbox
                    style={{ left: "-10px" }}
                    checked={checked}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(event) => {
                      setChecked(event.target.checked);
                    }}
                  />{" "}
                  <TextStyledComp
                    style={{ position: "relative" }}
                    translateX="50px"
                    fontSize="0.85rem"
                    textcolor="#666"
                  >
                    Clique aqui se deseja mudar de senha
                  </TextStyledComp>
                </div>
              </Container>
              <div
                style={{
                  height: checked ? "45%" : "0px",
                  overflow: "hidden",
                  transition: "500ms ease-in-out",
                }}
              >
                <div style={{ marginBottom: "1rem" }}>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Nova senha
                  </TextStyledComp>
                  <Input height="40px" fontSize="15px" name="password" />
                </div>
                <TextStyledComp
                  bgcolor="#e6394f"
                  bgcolorhover="#e6397F"
                  textcolor="white"
                  style={{
                    cursor: "pointer",
                    padding: ".5rem",
                    fontSize: ".80rem",
                    borderRadius: "3px",
                  }}
                  onClick={() => {
                    handlerReset();
                    setOpenReset(true);
                  }}
                >
                  resetar senha
                </TextStyledComp>
                <ModalStyled
                  hideBackdrop
                  open={openReset}
                  aria-labelledby="child-modal-title"
                  aria-describedby="child-modal-description"
                >
                  <BoxStyle>
                    <Container
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        cursor: "pointer",
                      }}
                      position="fixed"
                      top=".5rem"
                      right="1rem"
                      onClick={() => setOpenReset(false)}
                    >
                      X
                    </Container>
                    <Container>
                      <Container gap="10px">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <TextStyledComp fontWeight="500" fontSize="20px">
                            Aguarde enquanto a senha é atualizada!
                          </TextStyledComp>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {loading && <Loading height="65px" width="65px" />}
                        </div>
                      </Container>
                    </Container>
                  </BoxStyle>
                </ModalStyled>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <TextStyledComp
                    tag="span"
                    bgcolor={"#transparent"}
                    textcolor={"#2684FF"}
                    borderRadius="3px"
                    display="grid"
                    alignItems="center"
                    textAlign="center"
                    width={"80px"}
                    height={"40px"}
                    fontSize={"1rem"}
                    colorhover="#e63946"
                    cursor="pointer"
                    onClick={() => setOpenView(false)}
                  >
                    Voltar
                  </TextStyledComp>
                  <ButtonModal
                    type="submit"
                    bgcolor={"#2684FF"}
                    color={"white"}
                    height={"40px"}
                    heightResp={"40px"}
                    width={"130px"}
                    fontSize={"1rem"}
                    bgcolorhover={"#36B37E"}
                  >
                    Alterar senha
                  </ButtonModal>
                </div>
              </div>
            </Form>
          </Container>
        </BoxStyle>
      </ModalStyled>
    </React.Fragment>
  );
}
