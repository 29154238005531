/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { Input, InputMask } from "./Form";
import Container from "./Container50";
import TextStyledComp from "./TextStyled";
import { Form } from "@unform/web";
import Checkbox from "@mui/material/Checkbox";
import { string, object, ValidationError } from "yup";
import Loading from "./animations/loading";
import { toast } from "react-toastify";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { colors } from "@mui/material";
import { ThemeContext } from "styled-components";

const ModalStyled = styled(Modal)`
  position: "absolute";
  background-color: rgba(0, 0, 0, 0.15);
  overflow: scroll;
`;

const BoxStyle = styled(Box)`
  display: grid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: ${(props) => props.height};
  max-height: 800px;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 2rem;
  overflow: ${(props) => props.overflow};
  transition: 500ms ease-in-out;
`;

const ButtonStyled = styled.button`
  font-size: ${(props) => props.fontSize};
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  padding: ${(props) => props.padding};
  border-radius: 3px;
  transition: 100ms ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.btnHoverBg ? props.btnHoverBg : "#2194FF"};
    color: ${(props) => props.btnHoverColor};
    transform: scale(1.02);
  }
`;

const ButtonModal = styled.button`
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgcolor};
  font-size: ${(props) => props.fontSize};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 3px;
  margin: 5px 10px;
  transition: 100ms ease-in-out;
  &:hover {
    color: ${(props) => props.colorhover};
    background-color: ${(props) => props.bgcolorhover};
  }
  @media (max-height: 800px) {
    width: ${(props) => props.widthResp};
    height: ${(props) => props.heightResp};
    font-size: ${(props) => props.fontSizeResp};
  }
`;
export default function ModalSchool(props) {
  const [openView, setOpenView] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [openReset, setOpenReset] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const formRef = React.useRef(null);

  const { colors } = React.useContext(ThemeContext);

  async function handlerReset() {
    setLoading(true);

    let headersList = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    let reqOptions = {
      url: `${process.env.REACT_APP_API_URL}/resetSchool/?id=${props.item.id}`,
      method: "GET",
      headers: headersList,
    };

    await toast
      .promise(axios.request(reqOptions), {
        pending: "Atualizando senha",
        success: {
          render({ data }) {
            props.setNewPassword(data.data.password);
            props.setChangePassword(true);
            return "Senha Atualizada com sucesso";
          },
        },
        error: {
          render({ data }) {
            return data.response.data.includes("<")
              ? `${data.message}`
              : `${data.response.data}`;
          },
        },
      })
      .then((responseRequest) => {
        setLoading(false);
        setTimeout(console.log("helo"), 1000);
        setNewPassword(responseRequest.data.password);
      })
      .catch(() => setLoading(false));
    props.insertData();
  }

  async function handlerSubmit(data) {
    let inputsValue = data;

    async function submitData() {
      setOpenView(false);

      let data = JSON.stringify({
        password: inputsValue.password,
      });

      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let reqOptions = {
        url: `${process.env.REACT_APP_API_URL}/school/${props.item.id}`,
        method: "PUT",
        data: data,
        headers: headersList,
      };

      await toast
        .promise(axios.request(reqOptions), {
          pending: "Atualizando senha",
          success: "Atualizado com sucesso",
          error: {
            render({ data }) {
              return data.response.data.includes("<")
                ? `${data.message}`
                : `${data.response.data}`;
            },
          },
        })
        .then((responseRequest) => {
          setLoading(false);
          response = responseRequest;
        })
        .catch(() => setLoading(false));
      props.insertData();
    }

    try {
      const schema = object({
        password: string().required("Digite a nova senha"),
      });

      await schema
        .validate(data)
        .then(() => submitData())
        .catch(() => null);
      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof ValidationError) {
        const errorMensages = {};

        err.inner.forEach((error) => {
          errorMensages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMensages);
      }
    }
  }
 
  return (
    <React.Fragment>
      <ButtonStyled
        bgColor={props.bgColor}
        color={props.color}
        padding={props.padding}
        fontSize={props.fontSize}
        btnHoverBg={props.btnHoverBg}
        btnHoverColor={props.btnHoverColor}
        onClick={() => setOpenView((e) => !e)}
      >
        {props.placeholderBtn}
      </ButtonStyled>
      <Dialog
        visible={openView}
        onHide={() => setOpenView(false)}
        header="Escola"
        contentStyle={{
          backgroundColor: colors.container,
          color: colors.text,
        }}
        headerStyle={{ backgroundColor: colors.container, color: colors.text }}
      >
        <div
          style={{
            height: checked ? "62%" : "45%",
            overflow: "scroll",
            minWidth: "340px",
            width: "30vw",
            maxWidth: "500px",
            borderTop: "3px solid #2684FF"
          }}
        >
          <Container mt=".8rem">
            <Form ref={formRef} onSubmit={handlerSubmit}>
              <Container gap="15px">
                <div>
                  <div style={{display: "none"}}>
                  <Input type="hidden" defaultValue={props.item.id} name="id" />
                  </div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Nome da escola
                  </TextStyledComp>
                  <Input
                    height="40px"
                    bgColor={colors.background}
                    color={colors.text}
                    fontSize="15px"
                    name="nameSchool"
                    defaultValue={props.item.nameSchool}
                    disabled
                  />
                </div>
                <div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Email da escola
                  </TextStyledComp>
                  <Input
                    height="40px"
                    bgColor={colors.background}
                    color={colors.text}
                    fontSize="15px"
                    name="email"
                    defaultValue={props.item.email}
                    disabled
                  />
                </div>
                <div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Telefone da escola
                  </TextStyledComp>
                  <InputMask
                    style={{
                      height: "40px",
                      width: "97%",
                      paddingLeft: ".5rem",
                      borderRadius: "8px",
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    name="telephone"
                    mask="(99) 9 9999-9999"
                    value={props.item.telephone}
                    disabled
                  />
                </div>
                <div>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Número Inep
                  </TextStyledComp>
                  <Input
                    height="40px"
                    bgColor={colors.background}
                    color={colors.text}
                    fontSize="15px"
                    name="inep"
                    defaultValue={props.item.inep}
                    disabled
                  />
                </div>
                <div>
                  <Checkbox
                    style={{ left: "-10px" }}
                    checked={checked}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(event) => {
                      setChecked(event.target.checked);
                    }}
                  />{" "}
                  <TextStyledComp
                    style={{ position: "relative" }}
                    translateX="50px"
                    fontSize="0.85rem"
                    textcolor="#666"
                  >
                    Clique aqui se deseja mudar de senha
                  </TextStyledComp>
                </div>
              </Container>
              <div
                style={{
                  height: checked ? "45%" : "0px",
                  overflow: "hidden",
                  transition: "500ms ease-in-out",
                }}
              >
                <div style={{ marginBottom: "1rem" }}>
                  <TextStyledComp tag="label" fontWeight="bold">
                    Nova senha
                  </TextStyledComp>
                  <Input
                    bgColor={colors.background}
                    color={colors.text}
                    height="40px"
                    fontSize="15px"
                    name="password"
                  />
                </div>
                <TextStyledComp
                  bgcolor="#FF3030"
                  bgcolorhover="#ED2121"
                  textcolor="white"
                  style={{
                    cursor: "pointer",
                    padding: ".5rem",
                    fontSize: "1rem",
                    borderRadius: "8px",
                  }}
                  onClick={() => {
                    handlerReset();
                    setOpenReset(true);
                  }}
                >
                  resetar senha
                </TextStyledComp>
                <ModalStyled
                  hideBackdrop
                  open={openReset}
                  aria-labelledby="child-modal-title"
                  aria-describedby="child-modal-description"
                >
                  <BoxStyle>
                    <Container
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        cursor: "pointer",
                      }}
                      position="fixed"
                      top=".5rem"
                      right="1rem"
                      onClick={() => setOpenReset(false)}
                    >
                      X
                    </Container>
                    <Container>
                      <Container gap="10px">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <TextStyledComp fontWeight="500" fontSize="20px">
                            Aguarde enquanto a senha é atualizada!
                          </TextStyledComp>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {loading && <Loading height="65px" width="65px" />}
                        </div>
                      </Container>
                    </Container>
                  </BoxStyle>
                </ModalStyled>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1.2rem"
                  }}
                >
                  <TextStyledComp
                    tag="span"
                    bgcolor={"#EFEFEF"}
                    textcolor={"#222"}
                    borderRadius="8px"
                    display="grid"
                    alignItems="center"
                    textAlign="center"
                    width={"80px"}
                    height={"40px"}
                    fontSize={"1rem"}
                    colorhover="#FFF"
                    bgcolorhover="#2684FF"
                    cursor="pointer"
                    onClick={() => setOpenView(false)}
                  >
                    Voltar
                  </TextStyledComp>
                  <ButtonModal
                    type="submit"
                    bgcolor={"#36B37E"}
                    color={"white"}
                    height={"40px"}
                    heightResp={"40px"}
                    width={"130px"}
                    fontSize={"1rem"}
                    bgcolorhover={"#008453"}
                    style={{borderRadius: "8px", cursor: "pointer"}}
                  >
                    Alterar senha
                  </ButtonModal>
                </div>
              </div>
            </Form>
          </Container>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
