/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useContext, useRef, useState } from "react";
import { Form } from "@unform/web";
import {
  object,
  string,
  number,
  date,
  email,
  array,
  ValidationError,
} from "yup";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

import "./style.css";
import Container from "../../components/Container50";
import Banner from "../../components/Banner";
import LogoEpaz from '../../assets/logoepaz.png'
import Button from "../../components/Button";
// import { Button } from "primereact/button";
import { Context } from "../../context/loginContext";
import history from "../../history";
import TextStyledComp from "../../components/TextStyled";
import { Input, InputMask } from "../../components/Form/index";
import { AuthContext } from "../../hooks/auth";

const PageLogin = ({ colors, font, title }) => {
  const { signIn } = useContext(AuthContext);
  const LoginPagePermissions = useContext(Context);
  const { setTypeLogin } = useContext(Context);
  const [viewPassowrd, setViewPassword] = useState(false);
  const [value, setValue] = useState('')
  const formRef = useRef(null);

  const handleViewPass = React.useCallback((value) => {
    setViewPassword(e => !e)
  },[])

  setTypeLogin();
  const StyleInputMask = {
    width: "98%",
    borderWidth: "1px 1px 1px 0",
    borderRadius: "0px 8px 8px 0px",
    borderLeftColor: "transparent",
    height: "50px",
    fontSize: "20px",
    paddingLeft: "1%",
  };
  const fixedIcon = {
    fontSize: "2.5rem",
    color: "#36B37E",
  };

  async function handlerSubmit(data) {
    let schema = null;
    let inputsValue = data;

    function handlerSaveData(data) {
      LoginPagePermissions.Login == "escola"
        ? LoginPagePermissions.setDataSchool(data)
        : null;
      LoginPagePermissions.Login == "escola"
        ? localStorage.setItem("dataUserSchool", JSON.stringify(data))
        : null;
      LoginPagePermissions.Login == "usuarios"
        ? LoginPagePermissions.setDataServerLogin(data)
        : null;
      LoginPagePermissions.Login == "usuarios"
        ? localStorage.setItem("dataServerLogin", JSON.stringify(data))
        : null;
      LoginPagePermissions.setAdmin(data.data.userLogin.isAdmin);
      setTimeout(function () {
        history.push("/user/dashboard");
      }, 1000);
    }
    async function submitLogin() {
      let data = JSON.stringify(inputsValue);

      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let reqOptions = {
        url:
          LoginPagePermissions.Login == "usuarios"
            ? `${process.env.REACT_APP_API_URL}/loginServer`
            : `${process.env.REACT_APP_API_URL}/schoolLogin`,
        method: "POST",
        data: data,
        headers: headersList,
      };

      let response;
      await toast
        .promise(axios.request(reqOptions), {
          pending: "Logando",
          success: "Logado com sucesso",
          error: "Erro no Login, confira os dados",
        })
        .then((responseRequest) => {
          response = responseRequest;
          localStorage.setItem("auth", true);
          localStorage.setItem("@epaz:auth", "auth");
          LoginPagePermissions.setAuth(true);
          handlerSaveData(response);
        })
        .catch((err) => {
          response = err;
          localStorage.setItem("auth", false);
          LoginPagePermissions.setAuth(false);
        });
    }
    try {
      LoginPagePermissions.Login === "usuarios"
        ? (schema = object({
            cpf: string().required("Insira o CPF"),
            password: string().required("Digite a senha"),
          }))
        : (schema = object({
            email: string().required("Insira um email válido"),
            password: string().required("Digite a senha"),
          }));

      await schema
        .validate(data)
        .then(() => submitLogin())
        .catch(() => null);

      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof ValidationError) {
        const errorMensages = {};

        err.inner.forEach((error) => {
          errorMensages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMensages);
      }
    }
  }
  
  return (
    <div
      style={{ backgroundColor: colors.background }}
      className="PageLogin container-full"
    >
      <div style={{ backgroundColor: colors.primary }} className="side-logo">
        <Banner className="logo" self={"center"} />
      </div>
      <div className="side-login" style={{backgroundColor: colors.background}}>
        <div className="header-login">
          <Banner className="logo-login" />
          <i
            style={fixedIcon}
            className={`${
              LoginPagePermissions.Login === "escola"
                ? "fa-sharp fa-solid fa-graduation-cap"
                : "fa-solid fa-user"
            } icon-logo`}
          />
        </div>
        <div style={{ position: "absolute" }}>
          <ToastContainer theme="colored"/>
        </div>
        <Form ref={formRef} className="form" onSubmit={handlerSubmit}>
          <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
          <img src={LogoEpaz} style={{minWidth: "250px", maxWidth: "350px", width: "70%"}}/>
          </div>
          <Container align="start" gap="20px">
            <Container>
              <TextStyledComp
                textcolor={colors.text}
                fontSize="16px"
                tag="label"
              fontWeight="bold"
              style={{fontFamily: font.family}}
              >
                {LoginPagePermissions.Login == "escola" ? "Email" : "CPF"}
              </TextStyledComp>
              {LoginPagePermissions.Login == "escola" ? (
                <Input
                  type="email"
                  name="email"
                  style={{
                    backgroundColor: colors.background,
                    color: colors.text,
                  }}
                  placeholder="Digite o email da escola"
                />
              ) : (
                <InputMask
                  mask="999.999.999-99"
                  style={{
                    // ...StyleInputMask,
                    backgroundColor: colors.background,
                    color: colors.text,
                  }}
                  name="cpf"
                  placeholder="Digite o CPF"
                  icon="fa-solid fa-user"
                  autoClear={false}
                />
              )}
            </Container>
            <Container>
              <TextStyledComp
                textcolor={colors.text}
                fontSize="16px"
                tag="label"
              fontWeight="bold"
              style={{fontFamily: font.family}}
              >
                Senha
              </TextStyledComp>
                <Input
                  type={viewPassowrd ? "text" : "password"}
                  style={{
                    backgroundColor: colors.background,
                    color: colors.text,
                  }}
                  name="password"
                  placeholder="Digite sua senha"
                  icon="fa-sharp fa-solid fa-eye"
                  iconChange="fa-sharp fa-solid fa-eye-slash"
                  conditionIcon={viewPassowrd}
                  handleViewPass={handleViewPass}
                  rightIcon
                />
            </Container>
            <Button label="Entrar" bgcolor="#36B37E" bgcolorhover="#008453"/>
            {/* <Button bgcolor={colors.secundary} color="#FFF" className="button">
              Entrar
            </Button> */}
          </Container>
        </Form>
      </div>
    </div>
  );
};

export default PageLogin;
