/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import Container from "./Container50";
import TextStyledComp from "./TextStyled";

const CardReport = ({ name, url, date, fontSize, colors }) => {
  return (
    <Container
      templateColumns="20px 1fr 100px"
      alignItens="center"
      mb=".5rem"
      padding=".8rem"
      transY
      style={{
        boxShadow: ".5px .5px 2px .5px rgba(0, 0, 0, 0.4)",
        borderRadius: "3px",
      }}
    >
      <i className="fa-solid fa-file" />
      <TextStyledComp
        fontSize={fontSize}
        tag="a"
        data-fancybox
        data-type="iframe"
        data-src={url}

        // href="javascript:;"
      >
        {name}
      </TextStyledComp>
      <TextStyledComp style={{color: colors.text}} tag="span">{date}</TextStyledComp>
    </Container>
  );
};

export default CardReport;
