import React from "react";
import styled from "styled-components";

const Texterea = styled.textarea`
  width: 94%;
  border-radius: 10px;
  margin-top: 1rem;
  background-color: #e9e9ed;
  font-size: 0.9rem;
  padding: 1rem;
  height: 100px;
  resize: none;
`;

const TextereaComp = ({ ...rest }) => {
  return <Texterea {...rest} />;
};

export default TextereaComp;
