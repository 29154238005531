/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import Banner from "../../components/Banner";
import Container from "../../components/Container50";
import CardsLogin from "../../components/CardsLogin";
import Text from "../../components/Text";
import { Context } from "../../context/loginContext";
import history from "../../history";
import "./style.css";

const ContainerFull = styled.div`
  height: 100vh;
  background-color: #f7f7f7;
`;

const LPTipoAcesso = ({ toggleTheme, colors, font, title }) => {
  const global = useContext(Context);

  return (
    <div
      style={{ backgroundColor: colors.background }}
      className="LPTipoAcesso container-full"
    >
      <div style={{ backgroundColor: colors.primary }} className="side-logo">
        <Banner self={"center"} width={"600px"} />
      </div>
      <div className="side-cards">
        <Banner className={"logo"} self={"center"} width={"600px"} />
        <div className="container-cards">
          <Text
            tag="h5"
            className="header-card-type"
            style={{
              color: colors.text,
              textAlign: "left",
              width: "100%",
              fontSize: "22px",
              fontFamily: font.family,
              fontWeight: 'bold'
            }}
          >
            Qual seu tipo de acesso?
          </Text>
          <div className="group-cards">
            <div
              className="card"
              style={{
                backgroundColor: colors.card,
              }}
              onClick={() => {
                global.setLogin("usuarios");
                localStorage.setItem("TypeLogin", "usuarios");
                history.push("login");
              }}
            >
              <Text
                tag="p"
                className="icon-text"
                style={{
                  color: colors.text,
                  fontSize: "1.4rem",
                  fontFamily: font.family,
                }}
              >
                <i
                  className="fa-solid fa-user"
                  style={{
                    fontSize: "2.5rem",
                    placeSelf: "center",
                    color: colors.icon,
                  }}
                />
                Usuário
              </Text>
              <i
                className="fa-solid fa-angles-right"
                style={{
                  fontSize: "2rem",
                  placeSelf: "center",
                  color: colors.icon,
                }}
              />
            </div>
            <div
              className="card"
              style={{
                backgroundColor: colors.card,
              }}
              onClick={() => {
                global.setLogin("escola");
                localStorage.setItem("TypeLogin", "escola");
                history.push("login");
              }}
            >
              <Text
                tag="p"
                className="icon-text"
                style={{
                  fontSize: "1.4rem",
                  color: colors.text,
                  fontFamily: font.family,
                }}
              >
                <i
                  className="fa-sharp fa-solid fa-graduation-cap"
                  style={{
                    fontSize: "2.5rem",
                    placeSelf: "center",
                    color: colors.icon,
                  }}
                />
                Escola
              </Text>
              <i
                className="fa-solid fa-angles-right"
                style={{
                  fontSize: "2rem",
                  placeSelf: "center",
                  color: colors.icon,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LPTipoAcesso;
