/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react";
import styled from "styled-components";
import Loading from "./animations/loading";
import Container from "./Container50";
import FormView from "./FormView";

const DivTable = styled.div`
  background-color: #fff;
  overflow-y: scroll;
  width: 100%;
  height: 60vh;
  min-height: 50vh;
  max-height: 65vh:
`;

const Table = styled.table`
  position: relative;
  text-align: center;
  background-color: #fff;
  font-family: Poppins;
  width: 100%;
`;
const Td = styled.td`
  color: #222;
  padding: 0.8rem;
  font-size: 0.85rem;
  @media (max-height: 800px) {
    font-size: 0.7rem;
    padding: 0.5rem;
  }
`;

const THead = styled.thead`
  position: sticky;
  top: 0;
  background-color: white;
  text-align: center;
  width: 100%;
  z-index: 2;
`;

const Th = styled.th`
  position: relative;
  padding: 1.4rem;
  font-weight: 500;
  font-size: 1rem;
  @media (max-height: 800px) {
    font-size: 0.7rem;
    padding: 1rem;
  }
`;

const Tr = styled.tr`
  &:nth-child(2n + 1) {
    background-color: #f5f9ff;
  }
`;
//Componente estilizado de status (porém não utilizado para a devida função)
const CompStatus = styled.div`
  background-color: ${(props) => props.bgcolor};
  width: 50%;
  border-radius: 15px;
  color: ${(props) => props.color};
`;

const IconSort = styled.i`
  position: absolute;
  right: 1rem;
  top: 38%;
  font-size: 20px;
  transition: 150ms ease-in-out;
  &:hover {
    color: #2684ff;
  }
  @media (max-height: 800px) {
    font-size: 0.7rem;
  }
`;

const DataTableRefact = ({ category, data, loading, children }) => {
  return (
    <div>
      <DivTable>
        <Table>
          <THead>
            <tr>
              {category.map((item, index) => {
                return <Th key={item + index}>{item}</Th>;
              })}
            </tr>
          </THead>
          {loading && (
            <Container
              position={"absolute"}
              width={"98%"}
              height={"50vh"}
              placeItens={"center"}
              bgcolor={"transparent"}
            >
              <Loading
                width={"50px"}
                height={"50px"}
                borderSize={"6px"}
                widthResp={"30px"}
                heightResp={"30px"}
              />
            </Container>
          )}
          <tbody style={{ display: "100%", position: "relative" }}>
            {children}
          </tbody>
        </Table>
      </DivTable>
    </div>
  );
};

export default DataTableRefact;
