/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useMemo, useRef, useState } from "react";
import TextStyledComp from "../TextStyled";
import styled from "styled-components";
import TextereaComp from "../Texterea";
import { Form } from "@unform/web";
import { object, mixed, ValidationError, string } from "yup";
import {
  Select,
  Input,
  DatePicker,
  InputMask,
  TimePicker,
  FileInput,
} from "../Form/index";
import Container from "../Container50";
import { PageContext } from "../../context/pageContext";
import Text from "../Text";
import { toast } from "react-toastify";
import axios from "axios";

import { SIZE } from "baseui/modal";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FocusOnce,
} from "baseui/modal";
import { Context } from "../../context/loginContext";
import CardReport from "../CardReport";
import { Dialog } from "primereact/dialog";

const ButtonModal = styled.button`
  color: ${(props) => props.color};
  background-color: ${(props) => props.bgcolor};
  font-size: ${(props) => props.fontSize};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 3px;
  margin: 5px 10px;
  transition: 100ms ease-in-out;
  &:hover {
    color: ${(props) => props.colorhover};
    background-color: ${(props) => props.bgcolorhover};
  }
  @media (max-height: 800px) {
    width: ${(props) => props.widthResp};
    height: ${(props) => props.heightResp};
    font-size: ${(props) => props.fontSizeResp};
  }
`;

const Icon = styled.i`
  font-size: 1.4rem;
  padding: 0px 5px;
  cursor: pointer;

  .nocursor {
    cursor: default;
  }
  .ativo {
    color: green;
    transform: rotate(15deg);
  }

  @media (max-height: 800px) {
    font-size: 1.1rem;
    padding: 0px 5px;
  }
`;

const FormView = ({
  data,
  icon,
  disabled,
  permission,
  options,
  insertData,
  colors,
}) => {
  let { dataDash } = React.useContext(PageContext);
  let [dataInput, setDataInput] = useState(data);
  const [isOpen, setOpen] = React.useState(false);
  const formuRef = useRef(null);

  dataInput.date_birth_assaulted = dataInput.date_birth_assaulted.split("T")[0];
  dataInput.date_birth_aggressor = dataInput.date_birth_aggressor.split("T")[0];
  dataInput.date_occurrence = dataInput.date_occurrence.split("T")[0];

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      height: "150px",
      overflow: "scroll",
    }),
  };

  const StyleInputMask = {
    width: "98%",
    backgroundColor: colors.background,
    color: colors.text,
    border: "1px #CCC solid",
    borderRadius: "4px",
    height: "36px",
    paddingLeft: "1%",
  };

  //Atualizando dados do modal
  React.useEffect(() => {
    setDataInput(data);
  }, [dataDash]);

  let partners = dataInput.OcurrencePartner.map(
    (item) => item.Partner.nameOrgan
  );

  // Regex de telefone
  dataInput.telephone = data.telephone.replace(/\D/g, ""); //Remove tudo o que não é dígito
  dataInput.telephone = data.telephone.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  dataInput.telephone = data.telephone.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos

  //Function Style and Effects by CSS
  let [growDiv, setGrow] = useState(false);
  function growDivEvent(e) {
    if (!growDiv) {
      e.currentTarget.nextElementSibling.classList.remove("desactive");
      e.currentTarget.nextElementSibling.classList.add("active");
    } else {
      e.currentTarget.nextElementSibling.classList.remove("active");
      e.currentTarget.nextElementSibling.classList.add("desactive");
    }
  }

  async function handlerSubmit(data) {
    async function submitData() {
      setOpen(false);

      let formdata = new FormData();

      formdata.append("id_occurrence", data?.id_occurrence);
      if (data.file[0]) {
        formdata.append("file", data?.file[0], data?.file[0]?.name);
        console.log("pass file");
      }

      let requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      let response;
      if (data.file.length > 0) {
        await toast
          .promise(
            fetch(`${process.env.REACT_APP_API_URL}/report`, requestOptions),
            {
              pending: "Cadastrando Relatório",
              success: "Cadastrado com sucesso",
              error: "Não foi possivel efetuar o cadastro",
            }
          )
          .then((responseRequest) => {
            response = responseRequest;
            insertData();
          })
          .catch();
      }
    }
    try {
      const schema = object({
        file: mixed().test({
          message: "Formato de arquivo não suportado, inserir apenas .pdf",
          test: (file, context) => {
            const isValid = file[0].name.includes(".pdf");
            if (!isValid) context?.createError();
            return isValid;
          },
        }),
        id_occurrence: string().required("erro ao tentar enviar relatório"),
      });

      await schema
        .validate(data)
        .then(() => submitData())
        .catch(() => null);
      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof ValidationError) {
        const errorMensages = {};

        err.inner.forEach((error) => {
          errorMensages[error.path] = error.message;
        });
        formuRef.current.setErrors(errorMensages);
      }
    }
  }

  return (
    <React.Fragment>
      <ButtonModal
        bgcolor={"transparent"}
        color={"#2684FF"}
        colorhover={"#0052cc"}
        fontSize={"1rem"}
        fontSizeResp={"14px"}
        onClick={() => {
          setOpen((s) => !s);
          setDataInput(data);
        }}
      >
        <Icon className={icon}></Icon>
      </ButtonModal>
      <Dialog
        header="Datalhes do evento"
        visible={isOpen}
        onHide={() => setOpen(false)}
        contentStyle={{ backgroundColor: colors.container }}
        headerStyle={{ backgroundColor: colors.container, color: colors.text }}
      >
        <div>
          <Container
            style={{
              display: "grid",
              gap: "10px",
              paddingTop: "1rem",
              width: "35vw",
              minWidth: "320px",
              maxWidth: "800px",
            }}
          >
            <Form
              ref={formuRef}
              style={{ alignSelf: "start" }}
              onSubmit={handlerSubmit}
            >
              <TextStyledComp
                display={"grid"}
                columns={"1fr 1fr"}
                fontSizeResp={"1.1rem"}
                fontSize={"1.25rem"}
                tag="h3"
                style={{
                  borderBottom: "4px #2684FF solid",
                  paddingBottom: ".5rem",
                  marginBottom: "1rem",
                  color: colors.text,
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  growDivEvent(e);
                  setGrow((e) => !e);
                }}
              >
                Dados do envolvido 1{" "}
              </TextStyledComp>
              <Container className="desactive">
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Nome
                  </Text>
                  <Input
                    style={{
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    name="name_assaulted"
                    height="40px"
                    disabled
                    required
                    defaultValue={dataInput.name_assaulted}
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Nome do responsável
                  </Text>
                  <Input
                    style={{
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    name="responsible_assaulted"
                    height="40px"
                    disabled
                    required
                    defaultValue={dataInput.responsible_assaulted}
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    CPF do responsável
                  </Text>
                  <InputMask
                    mask="999.999.999-99"
                    style={{
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    height="40px"
                    fontSize="15px"
                    name="responsible_assaulted_cpf"
                    value={dataInput.responsible_assaulted_cpf}
                    disabled
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Data de nascimento
                  </Text>
                  <br></br>
                  <DatePicker
                    colors={colors}
                    name="date_birth_assaulted"
                    disabled
                    defaultValue={dataInput.date_birth_assaulted}
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Genero
                  </Text>
                  <br></br>
                  <Select
                    colors={colors}
                    name="sex_assaulted"
                    isDisabled
                    defaultValue={{
                      value: dataInput.sex_assaulted,
                      label: dataInput.sex_assaulted,
                    }}
                    options={options.sex}
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Raça
                  </Text>
                  <br></br>
                  <Select
                    colors={colors}
                    name="breeding_assauted"
                    isDisabled
                    defaultValue={{
                      value: dataInput.breeding_assauted,
                      label: dataInput.breeding_assauted,
                    }}
                    options={options.breed}
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Deficiencia (se houver)
                  </Text>
                  <br></br>
                  <Input
                    style={{
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    height="40px"
                    name="disability_assaulted"
                    disabled
                    placeholder={"Se houver"}
                  />
                </div>
                <div style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Serie cursada
                  </Text>
                  <br></br>
                  <Select
                    colors={colors}
                    name="series_taken_assaulted"
                    required
                    isDisabled
                    defaultValue={{
                      value: dataInput.series_taken_assaulted,
                      label: dataInput.series_taken_assaulted,
                    }}
                    options={options.series_taken}
                  />
                </div>
              </Container>
              <TextStyledComp
                tag="h3"
                fontSizeResp={"1.1rem"}
                fontSize={"1.25rem"}
                style={{
                  borderBottom: "4px #A30D11 solid",
                  paddingBottom: ".5rem",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  cursor: "pointer",
                  color: colors.text,
                }}
                onClick={(e) => {
                  growDivEvent(e);
                  setGrow((e) => !e);
                }}
              >
                Dados do envolvido 2
              </TextStyledComp>
              <Container className="desactive">
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Nome
                  </Text>
                  <Input
                    style={{
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    name="name_aggressor"
                    disabled
                    height="40px"
                    required
                    defaultValue={dataInput.name_aggressor}
                    placeholder="Nome do agressor"
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Nome do responsável
                  </Text>
                  <Input
                    style={{
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    name="responsible_aggressor"
                    height="40px"
                    disabled
                    required
                    value={dataInput.responsible_aggressor}
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    CPF do responsável
                  </Text>
                  <InputMask
                    mask="999.999.999-99"
                    style={{
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    height="40px"
                    fontSize="15px"
                    defaultValue={dataInput.responsible_aggressor_cpf}
                    disabled
                    name="responsible_aggressor_cpf"
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Data de nascimento
                  </Text>
                  <br></br>
                  <DatePicker
                    colors={colors}
                    name="date_birth_aggressor"
                    required
                    disabled
                    defaultValue={dataInput.date_birth_aggressor}
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Genero
                  </Text>
                  <br></br>
                  <Select
                    colors={colors}
                    name="sex_aggressor"
                    isDisabled
                    defaultValue={{
                      value: dataInput.sex_aggressor,
                      label: dataInput.sex_aggressor,
                    }}
                    options={options.sex}
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Raça
                  </Text>
                  <br></br>
                  <Select
                    colors={colors}
                    name="breeding_aggressor"
                    required
                    isDisabled
                    defaultValue={{
                      value: dataInput.breeding_aggressor,
                      label: dataInput.breeding_aggressor,
                    }}
                    options={options.breed}
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Deficiencia (se houver)
                  </Text>
                  <br></br>
                  <Input
                    style={{
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    name="disability_aggressor"
                    disabled
                    height="40px"
                    placeholder={"Se houver"}
                    type={"text"}
                  />
                </div>
                <div style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Serie cursada
                  </Text>
                  <br></br>
                  <Select
                    colors={colors}
                    name="series_taken_aggressor"
                    isDisabled
                    required
                    defaultValue={{
                      value: dataInput.series_taken_aggressor,
                      label: dataInput.series_taken_aggressor,
                    }}
                    options={options.series_taken}
                  />
                </div>
              </Container>
              <TextStyledComp
                tag="h3"
                fontSizeResp={"1.1rem"}
                fontSize={"1.25rem"}
                style={{
                  borderBottom: "4px #36B37E solid",
                  paddingBottom: ".5rem",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  cursor: "pointer",
                  color: colors.text,
                }}
                onClick={(e) => {
                  growDivEvent(e);
                  setGrow((e) => !e);
                }}
              >
                Dados da ocorrência
              </TextStyledComp>
              <Container className="desactive">
                <div style={{ marginTop: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Escola
                  </Text>
                  <Input
                    colors={colors}
                    name="fullNameSchool"
                    style={{
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    disabled
                    height="40px"
                    required
                    defaultValue={dataInput.School.nameSchool}
                    placeholder="Nome da escola"
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gap: "0.5rem",
                    marginTop: "1rem",
                  }}
                >
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Contatos
                  </Text>
                  <InputMask
                    colors={colors}
                    name="School.telephone"
                    required
                    style={StyleInputMask}
                    disabled
                    mask="(99) 9 9999-9999"
                    value={dataInput.telephone}
                    placeholder="Numero de contato"
                  />
                  <Input
                    style={{
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    name="School.email"
                    required
                    disabled
                    height="40px"
                    type={"email"}
                    endEnhancer={"@"}
                    defaultValue={dataInput.email}
                    placeholder="Email de contato"
                  />
                  <Input
                    name="id_occurrence"
                    type="hidden"
                    defaultValue={dataInput.id}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gap: "0.5rem",
                    marginTop: "1rem",
                  }}
                >
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Data e Hora da Ocorrencia
                  </Text>
                  <DatePicker
                    colors={colors}
                    name="date_occurrence"
                    defaultValue={dataInput.date_occurrence}
                    disabled
                  />
                  <TimePicker
                    colors={colors}
                    name="time_occurrence"
                    disabled
                    required
                    defaultValue={dataInput.time_occurrence}
                    type={"time"}
                  />
                </div>
                <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Parceiro solicitado
                  </Text>
                  <br></br>
                  <Select
                    colors={colors}
                    name="partner"
                    isDisabled
                    defaultValue={{
                      value: partners.join(", "),
                      label: partners.join(", "),
                    }}
                    options={options.partners}
                  />
                </div>
                <div></div>
                <Container gap="5px" mb="1rem">
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Documentos enviados ao parceiro:
                  </Text>
                  {data.reportPartner.length > 0 ? (
                    data?.reportPartner?.map((item, index) => {
                      let date_created = [
                        item?.created_at.split("-")[0],
                        item?.created_at.split("-")[1],
                        item?.created_at.split("-")[2].split("T")[0],
                      ];
                      let [year, mouth, day] = date_created;
                      return (
                        <CardReport
                          key={item.file_occurrence + index}
                          name={item?.file_occurrence?.split("-")[1]}
                          url={`${process.env.REACT_APP_API_URL}/files/${item?.file_occurrence}`}
                          date={`${day}/${mouth}/${year}`}
                          colors={colors}
                        />
                      );
                    })
                  ) : (
                    <p style={{ color: colors.text }}>
                      Não há relatório anexado...
                    </p>
                  )}
                </Container>
                <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Encaminhamento
                  </Text>
                  <br></br>
                  <Select
                    colors={colors}
                    name="progress"
                    isDisabled
                    defaultValue={{
                      value: dataInput.progress,
                      label: dataInput.progress,
                    }}
                    options={options.randoms}
                  />
                  <TextereaComp
                    style={{
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    name="progress_text"
                    disabled
                    defaultValue={dataInput.progress_text}
                    placeholder={"Descrição sobre o encaminhamento"}
                  />
                </div>
                <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                  <Text
                    tag="label"
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      color: colors.text,
                    }}
                  >
                    Evento
                  </Text>
                  <br></br>
                  <Select
                    colors={colors}
                    name="event"
                    style={customStyles}
                    isDisabled
                    defaultValue={{
                      value: dataInput.event,
                      label: dataInput.event,
                    }}
                    options={options.events}
                  />
                  <TextereaComp
                    style={{
                      backgroundColor: colors.background,
                      color: colors.text,
                    }}
                    name="event_text"
                    disabled
                    placeholder={"Descrição do evento"}
                    defaultValue={dataInput.event_text}
                  />
                  {permission !== "escola" && (
                    <FileInput
                      name="file"
                      style={{
                        marginTop: "1rem",
                        border: "none",
                        color: colors.text,
                      }}
                      type="file"
                      accept=".pdf"
                    />
                  )}
                </div>
                <Container gap="5px" mb="1rem">
                  {data.report.map((item, index) => {
                    let date_created = [
                      item.created_at.split("-")[0],
                      item.created_at.split("-")[1],
                      item.created_at.split("-")[2].split("T")[0],
                    ];
                    let [year, mouth, day] = date_created;

                    return (
                      <CardReport
                        key={item.file_occurrence + index}
                        name={item.file_occurrence.split("-")[1]}
                        url={`${process.env.REACT_APP_API_URL}/files/${item.file_occurrence}`}
                        date={`${day}/${mouth}/${year}`}
                        colors={colors}
                      />
                    );
                  })}
                </Container>
              </Container>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextStyledComp
                  tag="span"
                  bgcolor={"#EFEFEF"}
                  textcolor={"#222"}
                  borderRadius="8px"
                  display="grid"
                  alignItems="center"
                  textAlign="center"
                  width={"120px"}
                  height={"40px"}
                  fontSize={"1rem"}
                  bgcolorhover={"#2684FF"}
                  colorhover="#FFF"
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(false)}
                >
                  Voltar
                </TextStyledComp>
                {permission === "usuarios" && (
                  <ButtonModal
                    bgcolor={"#36B37E"}
                    color={"white"}
                    height={"40px"}
                    heightResp={"40px"}
                    width={"140px"}
                    fontSize={"1.1rem"}
                    bgcolorhover={"#008453"}
                    style={{ borderRadius: "8px", cursor: "pointer" }}
                  >
                    Enviar Relatório
                  </ButtonModal>
                )}
              </div>
            </Form>
          </Container>
          <ModalFooter></ModalFooter>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default FormView;
