/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import { Form } from "@unform/web";
import React, { useContext, useRef } from "react";
import Container from "../Container50";
import { Input, InputMask } from "../Form";
import TextStyledComp from "../TextStyled";
import styled, { ThemeContext } from "styled-components";
import { Checkbox } from "@mui/material";
import { object, string, ValidationError } from "yup";
import Button from "../Button";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import ModalReset from "../ModalReset";
import { Box, Modal } from "@mui/material";
import Loading from "../animations/loading";
import "./style.css";

const InputStyled = styled(Input)`
  height: 40px;
  :disabled {
    background-color: #ececec;
    color: #676767;
  }
`;
const InputMaskStyled = styled(InputMask)`
  height: 40px;
  padding-left: 0.5rem;
  width: 97.5%;
  border-radius: 4px;
  border: 1px #ccc solid;
  :disabled {
    background-color: #ececec;
    color: #676767;
  }
`;

const ModalStyled = styled(Modal)`
  position: "absolute";
  background-color: rgba(0, 0, 0, 0.15);
  overflow: scroll;
`;

const BoxStyle = styled(Box)`
  display: grid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: ${(props) => props.height};
  max-height: 800px;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 2rem;
  overflow: ${(props) => props.overflow};
  transition: 500ms ease-in-out;
`;

const Configuraçoes = () => {
  const user = localStorage.getItem("dataUserSchool")
    ? JSON.parse(localStorage.getItem("dataUserSchool"))
    : JSON.parse(localStorage.getItem("dataServerLogin"))
    ? JSON.parse(localStorage.getItem("dataServerLogin"))
    : null;

  const [changePassword, setChangePassword] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState(null);

  const [checked, setChecked] = React.useState(false);
  const form = useRef(null);
  const typeLogin = localStorage.getItem("TypeLogin")
    ? localStorage.getItem("TypeLogin")
    : null;

  const [openReset, setOpenReset] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { colors } = useContext(ThemeContext);

  async function handlerChangePassword(data) {
    let inputsValue = data;

    async function submitData() {
      let data = JSON.stringify({
        password: inputsValue.password,
      });

      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let reqOptions = {
        url:
          typeLogin == "usuarios"
            ? `${process.env.REACT_APP_API_URL}/server/${inputsValue.id}`
            : typeLogin == "escola"
            ? `${process.env.REACT_APP_API_URL}/school/${inputsValue.id}`
            : null,
        method: "PUT",
        data: data,
        headers: headersList,
      };

      await toast
        .promise(axios.request(reqOptions), {
          pending: "Atualizando senha",
          success: "Atualizado com sucesso",
          error: {
            render({ data }) {
              return data.response.data.includes("<")
                ? `${data.message}`
                : `${data.response.data}`;
            },
          },
        })
        .then((responseRequest) => {
          setLoading(false);
          response = responseRequest;
        })
        .catch(() => setLoading(false));
      props.insertData();
    }

    try {
      const schema = object({
        password: string().required("Digite a nova senha"),
      });

      await schema
        .validate(data)
        .then(() => submitData())
        .catch(() => null);
      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof ValidationError) {
        const errorMensages = {};

        err.inner.forEach((error) => {
          errorMensages[error.path] = error.message;
        });
        form.current.setErrors(errorMensages);
      }
    }
  }
  async function handlerResetPassword() {
    setLoading(true);
    let headersList = {
      Accept: "*/*",
    };

    let reqOptions = {
      url:
        typeLogin == "usuarios"
          ? `${process.env.REACT_APP_API_URL}/resetServer/?id=${user.data.userLogin.id}`
          : typeLogin == "escola"
          ? `${process.env.REACT_APP_API_URL}/resetSchool/?id=${user.data.userLogin.id}`
          : null,
      method: "GET",
      headers: headersList,
    };

    await toast
      .promise(axios.request(reqOptions), {
        pending: "Resetando senha",
        success: "Resetada com sucesso",
        error: {
          render({ data }) {
            return data.response.data.includes("<")
              ? `${data.message}`
              : `${data.response.data}`;
          },
        },
      })
      .then((responseRequest) => {
        setLoading(false);
        setOpenReset(false);
        setNewPassword(responseRequest.data.password);
        setChangePassword(true);
        response = responseRequest;
      })
      .catch(() => {
        setOpenReset(false);
        setLoading(false);
      });
  }
  console.log(user);
  return (
    <Container
      zIndex={"0"}
      width={"100%"}
      height={"90vh"}
      position={"relative"}
      bgcolor={"#f7f7f7"}
    >
      <div style={{ position: "fixed" }}>
        <ToastContainer theme="colored" />
      </div>
      <ModalReset
        changePassword={changePassword}
        setChangePassword={setChangePassword}
        newPassword={newPassword}
      />
      <Container
        justifyItens={"center"}
        height={"92vh"}
        width="100%"
        templateRows={"100px 1fr"}
        bgcolor={colors.background}
      >
        <Container height={"92vh"} width={"100%"}>
          <Container
            style={{
              boxShadow: "5px 7px 7px 2px rgba(0,0,0,0.1)",
              padding: "2rem",
              overflow: "scroll",
              marginTop: "1rem",
            }}
            width={"85%"}
            height={"80vh"}
            justify={"center"}
            align={"start"}
            bgcolor={colors.container}
            alignItems={"start"}
            templateRows="8vh 1fr"
            className="container-config"
          >
            <TextStyledComp
              style={{ fontWeight: "bold", color: colors.text }}
              tag="h3"
            >
              Configurações do usuário
            </TextStyledComp>
            <Form
              ref={form}
              onSubmit={handlerChangePassword}
              style={{ width: "90%", justifySelf: "center", marginTop: "1rem" }}
            >
              <TextStyledComp
                style={{ fontWeight: "bold", color: colors.text }}
                tag="h4"
              >
                Informações do usuário
              </TextStyledComp>
              <Input
              hide
                style={{
                  color: colors.text,
                  backgroundColor: colors.container,
                }}
                type="hidden"
                name="id"
                defaultValue={user.data.userLogin.id}
              />
              <div
                style={{
                  display: "grid",
                  height: "280px",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "10px",
                  gridTemplateRows: "80px 80px 80px 80px",
                }}
              >
                <div>
                  <TextStyledComp textcolor={colors.text} fontWeight="500">
                    {typeLogin == "usuarios" ? "Nome" : "Nome da escola"}
                  </TextStyledComp>
                  <InputStyled
                    style={{
                      color: colors.text,
                      backgroundColor: colors.container,
                    }}
                    height="40px"
                    defaultValue={
                      typeLogin == "usuarios"
                        ? user.data.userLogin.name
                        : user.data.userLogin.nameSchool
                    }
                    name="nome"
                    disabled
                  />
                </div>
                <div>
                  <TextStyledComp fontWeight="500" textcolor={colors.text}>
                    {typeLogin == "usuarios" ? "Matrícula" : "Endereço"}
                  </TextStyledComp>
                  {typeLogin == "usuarios" ? (
                    <InputStyled
                      style={{
                        color: colors.text,
                        backgroundColor: colors.container,
                      }}
                      height="40px"
                      defaultValue={user.data.userLogin.registration}
                      name="matricula"
                      disabled
                    />
                  ) : (
                    <InputStyled
                      style={{
                        color: colors.text,
                        backgroundColor: colors.container,
                      }}
                      defaultValue={user.data.userLogin.address}
                      name="address"
                      disabled
                    />
                  )}
                </div>
                <div>
                  <TextStyledComp fontWeight="500" textcolor={colors.text}>
                    Telefone
                  </TextStyledComp>
                  <InputMaskStyled
                    style={{
                      color: colors.text,
                      backgroundColor: colors.container,
                    }}
                    mask="(99) 9 99999-9999"
                    value={user.data.userLogin.telephone}
                    name="telefone"
                    disabled
                  />
                </div>
                <div>
                  {typeLogin == "usuarios" ? (
                    <>
                      <TextStyledComp fontWeight="500" textcolor={colors.text}>
                        {typeLogin == "usuarios"} CPF
                      </TextStyledComp>
                      <InputMaskStyled
                        style={{
                          color: colors.text,
                          backgroundColor: colors.container,
                        }}
                        mask="999.999.999-99"
                        value={user.data.userLogin.cpf}
                        name="cpf"
                        disabled
                      />
                    </>
                  ) : (
                    <>
                      <TextStyledComp
                        fontWeight="500"
                        textcolor={colors.text}
                        stylestyle={{ WebkitColumnSpan: "all" }}
                      >
                        Email
                      </TextStyledComp>
                      <InputStyled
                        height="40px"
                        style={{
                          marginTop: "0",
                          color: colors.text,
                          backgroundColor: colors.container,
                        }}
                        defaultValue={user.data.userLogin.email}
                        name="email"
                        disabled
                      />
                    </>
                  )}
                </div>
                <div style={{ WebkitColumnSpan: "all" }}>
                  {typeLogin == "usuarios" && (
                    <>
                      <TextStyledComp
                        fontWeight="500"
                        textcolor={colors.text}
                        stylestyle={{ WebkitColumnSpan: "all" }}
                      >
                        Email
                      </TextStyledComp>
                      <InputStyled
                        height="40px"
                        defaultValue={user.data.userLogin.email}
                        style={{
                          width: "200%",
                          color: colors.text,
                          backgroundColor: colors.container,
                        }}
                        name="email"
                        disabled
                      />
                    </>
                  )}
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <div style={{ display: "flex" }}>
                  <Checkbox
                    style={{ position: "relactive", left: "-10px" }}
                    sx={{
                      color: colors.secundary,
                      "&.Mui-checked": {
                        color: colors.icon_visu,
                      },
                    }}
                    checked={checked}
                    onClick={() => setChecked((e) => !e)}
                  />
                  <TextStyledComp
                    textcolor={colors.text}
                    style={{ alignSelf: "center" }}
                  >
                    Desejo mudar de senha
                  </TextStyledComp>
                </div>
                <div
                  style={{
                    display: "grid",
                    overflow: "hidden",
                    gridTemplateRows: "80px 80px",
                    width: "100%",
                    height: checked ? "160px" : "0px",
                    WebkitTransition: "250ms ease-in-out",
                  }}
                >
                  <div style={{ paddingTop: "1rem" }}>
                    <TextStyledComp fontWeight="500" textcolor={colors.text}>
                      Senha nova
                    </TextStyledComp>
                    <InputStyled
                      style={{ width: "97%" }}
                      name="password"
                      placeholder="Digite uma nova senha"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "10px",
                    }}
                  >
                    <TextStyledComp
                      height="40px"
                      bgcolor="#e6394f"
                      bgcolorhover="#e63939"
                      borderRadius="3px"
                      fontSize=".9rem"
                      textcolor="white"
                      display="grid"
                      cursor="pointer"
                      placeItems="center"
                      padding=".5rem"
                      scale="1.02"
                      style={{ alignSelf: "end", marginBottom: ".9rem" }}
                      onClick={() => {
                        handlerResetPassword();
                        setOpenReset(true);
                      }}
                    >
                      Resetar senha
                    </TextStyledComp>
                    <ModalStyled
                      hideBackdrop
                      open={openReset}
                      aria-labelledby="child-modal-title"
                      aria-describedby="child-modal-description"
                    >
                      <BoxStyle>
                        <Container
                          style={{
                            fontWeight: "bold",
                            fontSize: "1.5rem",
                            cursor: "pointer",
                          }}
                          position="fixed"
                          top=".5rem"
                          right="1rem"
                          onClick={() => setOpenReset(false)}
                        >
                          X
                        </Container>
                        <Container>
                          <Container gap="10px">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <TextStyledComp fontWeight="500" fontSize="20px">
                                Aguarde enquanto a senha é atualizada!
                              </TextStyledComp>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {loading && (
                                <Loading
                                  borderSize="6px"
                                  height="65px"
                                  width="65px"
                                />
                              )}
                            </div>
                          </Container>
                        </Container>
                      </BoxStyle>
                    </ModalStyled>
                    <Button
                      bgcolor="#2684FF"
                      bgcolorhover="#2684F0"
                      style={{
                        borderRadius: "3px",
                        fontSize: "15px",
                        alignSelf: "end",
                        marginBottom: ".9rem",
                        padding: ".5rem",
                      }}
                      height="40px"
                      color="#FFF"
                    >
                      Atualizar senha
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default Configuraçoes;
