/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import TextStyledComp from "../TextStyled";
import { ThemeContext } from "styled-components";
import { ToastContainer } from "react-toastify";
import { InputSwitch } from "primereact/inputswitch";
import "./style.css";

const HeaderDynamic = ({ active, setActiveSide, toggleTheme }) => {
  const { colors, title } = React.useContext(ThemeContext);
  const [mode, setMode] = React.useState((e) => title === "dark");

  return (
    <div
      style={{
        backgroundColor: colors.background,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignContent: "center",
        gridTemplateColumns: ".2fr 1fr",
        paddingLeft: "1.5rem",
      }}
      className="Header container-header"
    >
      {active && (
        <TextStyledComp
          fontSizeResp={"1rem"}
          style={{
            marginTop: "20px",
            color: colors.text,
          }}
          className="text-header"
          tag="h2"
        >
          <i
            onClick={() => setActiveSide((e) => !e)}
            className="fa-solid fa-bars bar-icon"
            style={{ color: colors.text }}
          />
          <TextStyledComp
            textcolor={"#36B37E"}
            fontSizeResp={"1.4rem"}
            fontSize={"2rem"}
            style={{
              marginRight: "1rem",
            }}
            tag={"i"}
          >
            {active.icon}
          </TextStyledComp>
          {active.display}
        </TextStyledComp>
      )}
      {/* <ToastContainer theme="colored"/> */}
      <div
        style={{
          display: "flex",
          alignContent: "end",
          gap: "10px",
          color: colors.text,
          fontWeight: "bold",
        }}
      >
        <span style={{ alignSelf: "end" }}>Dark mode</span>
        <InputSwitch
          checked={mode}
          onChange={() => {
            setMode((e) => !e);
            toggleTheme(title === "dark" ? "light" : "dark");
          }}
        />
      </div>
    </div>
  );
};

export default HeaderDynamic;
