/* eslint-disable react/prop-types */
import React, { useState } from "react";

export const PageContext = React.createContext();

export const ContextPage = (props) => {
  let [active, setActive] = useState();
  let [dataDash, setDataDash] = useState(null);
  let [atualiza, setAtualiza] = useState(true);
  let [loading, setLoading] = useState(false);

  return (
    <PageContext.Provider
      value={{
        active,
        setActive,
        dataDash,
        setDataDash,
        atualiza,
        setAtualiza,
        loading,
        setLoading,
      }}
    >
      {props.children}
    </PageContext.Provider>
  );
};
