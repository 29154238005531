import styled from "styled-components";

const InputStyled = styled.input`
  width: 400px;
  height: 35px;
  border-radius: 5px;
  border: 2.2px #9e9e9e solid;
  margin-left: 3%;
  font-size: 17px;
  padding-left: 2.5rem;
  @media (max-height: 800px) {
    height: 25px;
    width: 270px;
    font-size: ${(props) => props.fontSizeResp};
  }
`;
export default InputStyled;
