/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled, { ThemeContext } from "styled-components";
import Container from "./Container50";
import TextStyledComp from "./TextStyled";
import { toast } from "react-toastify";
import axios from "axios";
import { Dialog } from "primereact/dialog";

const ModalStyled = styled(Modal)`
  position: "absolute";
  background-color: rgba(0, 0, 0, 0.15);
  overflow: scroll;
`;

const BoxStyle = styled(Box)`
  display: grid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: ${(props) => props.height};
  max-height: 800px;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 2rem;
  overflow: ${(props) => props.overflow};
  transition: 500ms ease-in-out;
`;

const ButtonStyled = styled.button`
  font-size: ${(props) => props.fontSize};
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  padding: ${(props) => props.padding};
  border-radius: 8px;
  transition: 100ms ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.btnHoverBg ? props.btnHoverBg : "#2194FF"};
    color: ${(props) => props.btnHoverColor};
    transform: scale(1.02);
  }
`;

const DialogStyled = styled(Dialog)`
  .p-dialog-footer {
    background-color: ${(props) => props.bgColor};
  }
`;

export default function ModalDelete(props) {
  const [openView, setOpenView] = React.useState(false);
  const { colors } = React.useContext(ThemeContext);

  async function submitDelete() {
    setOpenView(false);
    let queryString = `${props.rota}/${String(props.item.id)}`;

    let reqOptions = {
      url: `${process.env.REACT_APP_API_URL}/${queryString}`,
      method: "DELETE",
    };

    await toast.promise(axios.request(reqOptions), {
      pending: `Apagando ${props.intentionDelete}`,
      success: `${props.intentionDelete} Apagado(a)!`,
      error: {
        render({ data }) {
          return data.response.data.includes("<")
            ? `${data.message}`
            : `${data.response.data}`;
        },
      },
    });
    props.insertData();
  }

  const footerOptions = (
    <div
      style={{
        display: "flex",
        gap: "10px",
        justifyContent: "end",
        alignContent: "center",
      }}
    >
      <ButtonStyled
        style={{ height: "32px", fontWeight: "500" }}
        bgColor="transparent"
        btnHoverBg="transparent"
        btnHoverColor="#2684FF"
        color="#888"
        onClick={() => setOpenView(false)}
      >
        Opa, me enganei
      </ButtonStyled>
      <ButtonStyled
        bgColor="#e6394f"
        btnHoverBg="#8f4d55"
        color="white"
        padding=".5rem"
        style={{ height: "32px", fontWeight: "500" }}
        onClick={() => submitDelete()}
      >
        Tenho Certeza!
      </ButtonStyled>
    </div>
  );

  return (
    <React.Fragment>
      <ButtonStyled
        bgColor={props.bgColor}
        color={props.color}
        padding={props.padding}
        fontSize={props.fontSize}
        btnHoverBg={props.btnHoverBg}
        btnHoverColor={props.btnHoverColor}
        onClick={() => setOpenView((e) => !e)}
      >
        {props.placeholderBtn}
      </ButtonStyled>
      <DialogStyled
        visible={openView}
        onHide={() => setOpenView(false)}
        bgColor={colors.container}
        header="Confirmar operação de deletar ?"
        headerStyle={{ backgroundColor: colors.container, color: colors.text }}
        contentStyle={{ backgroundColor: colors.container, color: colors.text }}
        // footer={footerOptions}
      >
        <div
          style={{
            height: "80px",
            minWidth: "340px",
            width: "30vw",
            maxWidth: "500px",
          }}
        >
          {/* <TextStyledComp
              style={{ alignSelf: "start" }}
              tag="i"
              fontSize="80px"
              textcolor="#e6394f"
              className="fa-solid fa-circle-exclamation"
            ></TextStyledComp> */}
          <Container templateRows="40%">
            <Container>
              <TextStyledComp fontSize="18px">
                Não será possivel desfazer esta ação.
              </TextStyledComp>
            </Container>
          </Container>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "end",
            alignContent: "center",
          }}
        >
          <ButtonStyled
            bgColor="#FF3030"
            btnHoverBg="#ED2121"
            color="white"
            padding=".5rem"
            style={{ height: "40px", fontWeight: "500", width: "100%" }}
            onClick={() => submitDelete()}
          >
            <i
              className="fa-solid fa-trash"
              style={{ marginRight: "10px" }}
            ></i>
            Deletar!
          </ButtonStyled>
          <ButtonStyled
            style={{
              height: "40px",
              fontWeight: "500",
              width: "100%",
              border: "2px solid #2684FF",
            }}
            bgColor="transparent"
            btnHoverBg="#2684FF"
            btnHoverColor="#FFF"
            color="#2684FF"
            onClick={() => setOpenView(false)}
          >
            Cancelar
          </ButtonStyled>
        </div>
      </DialogStyled>
    </React.Fragment>
  );
}
