/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { memo, useContext, useEffect, useState } from "react";
import DataTableRefact from "../../components/DataTableRefact";
import InputStyled from "../../components/InputDeBusca";
import Container from "../../components/Container50";
import TextStyledComp from "../TextStyled";
import Pagination from "../../components/Pagination";
import Text from "../Text";
import ModalComp from "../Modal";
import ModalPartner from "../ModalPartner";
import ModalDelete from "../ModalDelete";
import Button from "../Button";
import { DataTable } from "primereact/datatable";

import { Form } from "@unform/web";
import { Input, InputMask, Select, FileInput } from "../Form/index";
import styled, { ThemeContext } from "styled-components";
import "./style.css";

import { PageContext } from "../../context/pageContext";
import api from "../../services/api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { string, object, ValidationError } from "yup";
import Loading from "../animations/loading";
import { Column } from "primereact/column";
import { Ripple } from "primereact/ripple";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";

const Td = styled.td`
  color: #222;
  padding: 0.8rem;
  font-size: 0.85rem;
  @media (max-height: 800px) {
    font-size: 0.7rem;
    padding: 0.5rem;
  }
`;
const Tr = styled.tr`
  background-color: #fff;
  &:nth-child(2n + 1) {
    background-color: #f5f9ff;
  }
  &:hover {
    background-color: rgba(38, 132, 255, 0.2);
  }
`;
const StyleInputMask = {
  width: "98%",
  border: "1px #CCC solid",
  borderRadius: "4px",
  height: "36px",
  paddingLeft: "1%",
};

const ButtonModal = styled.button`
  display: ${(props) => props.display};
  color: ${(props) => props.color};
  place-items: center;
  background-color: ${(props) => props.bgcolor};
  font-size: ${(props) => props.fontSize};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  justify-self: ${(props) => props.justify};
  border-radius: 3px;
  margin: 5px 10px;
  transition: 100ms ease-in-out;
  &:hover {
    color: ${(props) => props.colorhover};
    background-color: ${(props) => props.bgcolorhover};
  }
  @media (max-height: 800px) {
    width: ${(props) => props.widthResp};
    height: ${(props) => props.heightResp};
    font-size: ${(props) => props.fontSizeResp};
  }
`;

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    zIndex: "999",
    width: window.innerWidth >= 1780 ? "100px" : "80px",
    overflow: "none",
  }),
  menuList: (provided, state) => ({
    ...provided,
    overflow: "none",
    color: "#555",
  }),
};

const DataTableStyle = styled(DataTable)`
  .p-paginator.p-component.p-paginator-bottom {
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    justify-content: flex-start;
  }
  .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: ${(props) => props.colorIcon} !important;
  }
  .p-sortable-column .p-sortable-column-icon {
    color: #555;
  }
  .p-datatable-tbody tr {
    background-color: ${(props) => props.rowsColor};
  }
  .p-datatable-tbody tr.p-row-odd {
    background-color: ${(props) => props.rowStripedColor} !important;
  }
  .p-paginator-page.p-paginator-element.p-link {
    background-color: ${(props) => props.backgroundButtonPaginate};
    color: ${(props) => props.colorButtonPaginate};
    border-radius: 8px;
    min-width: 0rem;
    padding: 0;
    height: 31px;
    width: 31px;
  }
  .p-paginator-page.p-paginator-element.p-link.p-highlight {
    background-color: ${(props) => props.backgroundButtonPaginateActive};
    color: ${(props) => props.colorButtonPaginateActive};
  }
`;

const Parceiros = () => {
  let { atualiza, loading, setLoading } = useContext(PageContext);
  let [data, setData] = React.useState((data) =>
    localStorage.getItem("dataPartner")
      ? JSON.parse([localStorage.getItem("dataPartner")])
      : data
      ? data
      : []
  );
  const [openRegister, setOpenRegister] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [btnUpdateTable, setBtnUpdate] = React.useState(false);
  const [nameOrgan, setNameOrgan] = useState("");
  // const [tct, setTct] = React.useState(false);

  let [search, setSearch] = React.useState();
  let [paginationData, setPaginationData] = React.useState({
    currentPage: 1,
    lastPage: 1,
  });
  let [quantityRegisters, setQuantity] = React.useState({
    value: 10,
    label: 10,
  });
  let [page, setPage] = React.useState(1);

  const formuRef = React.useRef(null);

  const { colors } = useContext(ThemeContext);

  async function handlerSubmit(data) {
    async function submitData() {
      setOpenRegister(false);

      let formdata = new FormData();
      formdata.append("nameOrgan", data.nameOrgan);
      formdata.append("nameProject", data.nameProject);
      formdata.append("managerName", data.managerName);
      formdata.append("telephone", data.telephone);
      formdata.append("email", data.email);
      if (data.file.length > 0) {
        formdata.append("file", data?.file[0], data?.file[0]?.name);
      }
      let headersList = {
        Accept: "*/*",
        "Content-Type": "application/json",
      };

      let reqOptions = {
        url: `${process.env.REACT_APP_API_URL}/partner`,
        method: "POST",
        data: formdata,
        headers: headersList,
      };

      let response;
      await toast
        .promise(axios.request(reqOptions), {
          pending: "Cadastrando Parceiro",
          success: "Cadastrado com sucesso",
          error: {
            render({ data }) {
              return data.response.data.includes("<")
                ? `${data.message}`
                : `${data.response.data}`;
            },
          },
        })
        .then((responseRequest) => {
          setLoading(false);
          insertDataRequest();
          response = responseRequest;
        })
        .catch(() => setLoading(false));
      insertDataRequest();
    }

    try {
      let schema;
      schema = object({
        nameOrgan: string()
          .min(2, "No minimo 3 caracteres")
          .required("Preencha com nome do orgão"),
        nameProject: string().required("Preencha com o nome do projeto"),
        email: string()
          .email("Digite um email valido")
          .min(4, "No minimo 4 caracteres")
          .required("É obrigatorio o email"),
        telephone: string()
          .min(6, "Digite um número válido")
          .required("Digite o Telefone"),
        managerName: string()
          .min(2, "Digite um nome válido")
          .required("O nome é obrigatorio"),
      });

      await schema
        .validate(data)
        .then(() => submitData())
        .catch(() => null);
      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof ValidationError) {
        const errorMensages = {};

        err.inner.forEach((error) => {
          errorMensages[error.path] = error.message;
        });
        formuRef.current.setErrors(errorMensages);
      }
    }
  }

  async function insertDataRequest() {
    setLoading(true);
    setData();
    let response;

    search
      ? (response = await api
          .get(`${process.env.REACT_APP_API_URL}/partner?names`, {
            headers: "",
          })
          .then((request) => {
            localStorage.setItem(
              "dataPartner",
              JSON.stringify(request.data.data)
            );
            paginationData.currentPage = request.data.currentPage;
            paginationData.lastPage = request.data.lastPage;
            setData(request.data.data);
          })
          .catch((error) => {
            setData([]);
          }))
      : (response = await api
          .get(`${process.env.REACT_APP_API_URL}/partner?names}`, {
            headers: "",
          })
          .then((request) => {
            localStorage.setItem(
              "dataPartner",
              JSON.stringify(request.data.data)
            );
            paginationData.currentPage = request.data.currentPage;
            paginationData.lastPage = request.data.lastPage;
            setData(request.data.data);
          })
          .catch((error) => {
            setData([]);
          }));
    setLoading(false);
  }

  // Custom DataTable -------------------------

  // Options Body Template
  const optionsBody = (rowData) => {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <ModalPartner
          open={openView}
          setOpen={setOpenView}
          fontSize="20px"
          bgColor="transparent"
          color={colors.icon_visu}
          insertDataRequest={insertDataRequest}
          btnHoverBg="transparent"
          btnHoverColor="#2684FF"
          padding=".2rem"
          placeholderBtn={<i className="fa-solid fa-pen-to-square"></i>}
          item={rowData}
        />
        <ModalDelete
          fontSize="20px"
          bgColor="transparent"
          btnHoverBg="transparent"
          intentionDelete="Parceiro"
          insertData={insertDataRequest}
          btnHoverColor="#e6394f"
          color="#e6394f"
          rota="partner"
          placeholderBtn={<i className="fa-solid fa-trash"></i>}
          item={rowData}
        />
      </div>
    );
  };

  // Pagination
  const pagination = {
    layout: "PrevPageLink PageLinks NextPageLink",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span
            className={className}
            style={{ userSelect: "none", borderRadius: "3px" }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: "10px" }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  React.useEffect(() => {
    insertDataRequest();
  }, [page, search]);

  return (
    <div
      // zIndex={"0"}
      // width={"100%"}
      // height={"100%"}
      // position={"relative"}
      // bgcolor={"#f7f7f7"}
      style={{ backgroundColor: colors.background }}
      className="Parceiros container-full"
    >
      <div style={{ position: "fixed" }}>
        <ToastContainer theme="colored" />
      </div>
      <div
        // justifyItens={"center"}
        // height={"100%"}
        // templateRows={"100px 1fr"}
        style={{ backgroundColor: colors.container }}
        className="container-datatable"
      >
        <div className="header-datatable">
          <Form
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              color: colors.text,
            }}
          >
            <TextStyledComp fontSize={".9rem"} fontSizeResp={".7rem"}>
              Mostrar
            </TextStyledComp>
            <Select
              name={"limit"}
              styles={customStyles}
              options={[
                { value: 10, label: 10 },
                { value: 20, label: 20 },
                { value: 30, label: 30 },
                { value: 40, label: 40 },
              ]}
              placeholder={quantityRegisters.value}
              onChange={(e) => {
                setPage(1);
                setQuantity(e);
              }}
            />
            <TextStyledComp
              fontSize={".9rem"}
              fontSizeResp={".7rem"}
              // fontSizeRespMid={".rem"}
            >
              Registros
            </TextStyledComp>
          </Form>
          <div
            style={{
              position: "relative",
              right: "20px",
              left: "10px",
            }}
            className="container-btns"
          >
            <Button
              bgcolor="#2684FF"
              bgcolorhover="#36B37E !important"
              padding={
                window.innerWidth >= 1780 || window.innerHeight >= 800
                  ? ".6rem"
                  : "0.3rem"
              }
              style={{ borderRadius: "5px", marginRight: "1rem" }}
              hoverShadow="none"
              onMouseLeave={() => setBtnUpdate(false)}
              onClick={() => {
                setPage(1);
                setBtnUpdate(true);
                insertDataRequest();
              }}
            >
              <i
                style={{
                  color: "#FFF",
                  fontSize: "1.3rem",
                  cursor: "pointer",
                  transition: "500ms ease-in-out",
                  WebkitTransform: `rotate(${
                    btnUpdateTable ? "360deg" : "0deg"
                  })`,
                }}
                className="fa-solid fa-arrow-rotate-right"
              ></i>
            </Button>
            <Button
              style={{
                backgroundColor: colors.secundary,
              }}
              className="btn_registerPartner"
              icon="fa-solid fa-plus"
              iconPos="left"
              bgcolorhover="#36B37E !important"
              label="Cadastrar Parceiro"
              onClick={() => setOpenRegister(true)}
            />
          </div>
        </div>
        <DataTableStyle
          key="id"
          value={data}
          scrollable
          className={`datatable`}
          backgroundColor={colors.container}
          bgColorHeaderFilter={colors.container}
          backgroundButtonPaginate={colors.btn_color}
          colorButtonPaginate={colors.text}
          backgroundButtonPaginateActive={colors.icon_visu}
          colorButtonPaginateActive={"#FFF"}
          rowStripedColor={colors.stripedRow}
          rowsColor={colors.row}
          colorIcon={colors.text}
          scrollDirection="horizontal"
          tableStyle={{ height: "58vh" }}
          scrollHeight="58vh"
          borderColor={colors.border}
          paginator
          paginatorTemplate={pagination}
          stripedRows
          rows={quantityRegisters.value}
          size="small"
          dataKey="id"
          filterDisplay="menu"
          loading={loading}
          emptyMessage="Nenhum dado encontrado."
        >
          <Column
            filter
            sortable
            rowReorderIcon="pi pi-sort"
            filterMatchMode="contains"
            className="colum"
            filterMenuStyle={{
              backgroundColor: colors.container,
              color: colors.text,
            }}
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
            }}
            showFilterMenuOptions={false}
            filterPlaceholder="Orgão"
            header="Nome do Orgão"
            field="nameOrgan"
          />

          <Column
            filter
            sortable
            rowReorderIcon="pi pi-sort"
            filterMatchMode="contains"
            className="colum"
            filterMenuStyle={{
              backgroundColor: colors.container,
              color: colors.text,
            }}
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
            }}
            showFilterMenuOptions={false}
            filterPlaceholder="Nome do administrador"
            header="Nome do administrador"
            field="managerName"
          />
          <Column
            filter
            sortable
            rowReorderIcon="pi pi-sort"
            filterMatchMode="contains"
            className="colum"
            filterMenuStyle={{
              backgroundColor: colors.container,
              color: colors.text,
            }}
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
            }}
            showFilterMenuOptions={false}
            filterPlaceholder="Telefone"
            header="Telefone"
            field="telephone"
          />
          <Column
            bodyStyle={{
              color: colors.text,
              border: `1px transparent solid`,
            }}
            headerStyle={{
              color: colors.text,
              backgroundColor: colors.container,
              border: `1px transparent solid`,
            }}
            style={{ minWidth: "5rem", maxWidth: "8rem" }}
            header="Opções"
            body={optionsBody}
          />
        </DataTableStyle>
      </div>
      <Dialog
        visible={openRegister}
        onHide={() => setOpenRegister(false)}
        header="Cadastro de parceiro"
        headerStyle={{
          backgroundColor: colors.container,
          color: colors.text,
        }}
        contentStyle={{
          backgroundColor: colors.container,
          color: colors.text,
        }}
      >
        <div
          style={{
            minWidth: "340px",
            width: "35vw",
            maxWidth: "500px",
            borderTop: "3px solid #2684FF",
          }}
        >
          <Form
            ref={formuRef}
            onSubmit={handlerSubmit}
            style={{ marginTop: "1rem" }}
          >
            <Container gap="15px">
              <div>
                <Text
                  tag="label"
                  style={{ fontSize: "1rem", fontWeight: "600" }}
                >
                  Nome do Orgão
                </Text>
                <Input
                  bgColor={colors.background}
                  color={colors.text}
                  height="40px"
                  fontSize="15px"
                  name="nameOrgan"
                  placeholder="Digite o nome do orgão"
                />
              </div>
              <div>
                <TextStyledComp tag="label" fontWeight="bold">
                  Nome do projeto
                </TextStyledComp>
                <Input
                  bgColor={colors.background}
                  color={colors.text}
                  height="40px"
                  fontSize="15px"
                  name="nameProject"
                  placeholder="Digite o nome do Projeto"
                />
              </div>
              <div>
                <TextStyledComp tag="label" fontWeight="bold">
                  Nome do Gestor
                </TextStyledComp>
                <Input
                  bgColor={colors.background}
                  color={colors.text}
                  height="40px"
                  fontSize="15px"
                  name="managerName"
                  placeholder="Digite o nome do administrador"
                />
              </div>
              <div>
                <TextStyledComp tag="label" fontWeight="bold">
                  Email
                </TextStyledComp>
                <Input
                  bgColor={colors.background}
                  color={colors.text}
                  height="40px"
                  fontSize="15px"
                  name="email"
                  placeholder="EmailDoParceiro@outlook.com"
                />
              </div>
              <div>
                <TextStyledComp tag="label" fontWeight="bold">
                  Telefone
                </TextStyledComp>
                <InputMask
                  style={{
                    height: "40px",
                    width: "97%",
                    paddingLeft: ".5rem",
                    borderRadius: "8px",
                    backgroundColor: colors.background,
                    color: colors.text,
                  }}
                  name="telephone"
                  mask="(99) 9999-9999"
                  placeholder="(00) 0000-0000"
                />
              </div>

              <div>
                <TextStyledComp
                  style={{
                    position: "relative",
                    marginRight: "1rem",
                  }}
                  translateX="50px"
                  fontSize="0.85rem"
                  textcolor="#666"
                >
                  Possui TCT?
                </TextStyledComp>
              </div>
              <div>
                <FileInput name="file" accept=".pdf" />
              </div>
            </Container>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <ButtonModal
                justify={"end"}
                bgcolor={"#EFEFEF"}
                color={"#222"}
                fontSize={"1rem"}
                colorhover={"#FFF"}
                bgcolorhover="#2684FF"
                width="100px"
                style={{
                  borderRadius: "8px",
                  cursor: "pointer",
                  display: "grid",
                  placeItems: "center",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenRegister(false);
                }}
              >
                Voltar
              </ButtonModal>
              <ButtonModal
                type="submit"
                display={"grid"}
                justify={"start"}
                bgcolor={"#36B37E"}
                color={"white"}
                height={"40px"}
                heightResp={"30px"}
                width={"150px"}
                style={{ borderRadius: "8px", cursor: "pointer" }}
                fontSize={"1rem"}
                bgcolorhover={"#008453"}
              >
                Cadastrar
              </ButtonModal>
            </div>
          </Form>
        </div>
      </Dialog>
    </div>
  );
};

export default Parceiros;
