/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";

const TextStyledComp = ({ tag, children, ...rest }) => {
  const Text = tag ? `${tag}` : "span";

  const TextStyled = styled(Text)`
    display: ${(props) => props.display};
    align-items: ${(props) => props.alignItems};
    grid-template-columns: ${(props) => props.columns};
    gap: ${(props) => props.gap};
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    background-color: ${(props) => props.bgcolor};
    border-radius: ${(props) => props.borderRadius};
    color: ${(props) => props.textcolor};
    justify-self: ${(props) => props.justify};
    align-items: ${(props) => props.alignItens};
    place-items: ${(props) => props.placeItems};
    width: ${(props) => props.width};
    text-align: ${(props) => props.textAlign};
    font-weight: ${(props) => props.fontWeight};
    border-top: ${(props) => props.borderT};
    border-bottom: ${(props) => props.borderB};
    border-left: ${(props) => props.borderL};
    border-right: ${(props) => props.borderR};
    padding: ${(props) => props.padding};
    font-size: ${(props) => props.fontSize};
    -moz-transform: translateX(${(props) => props.translateX});
    -ms-transform: translateX(${(props) => props.translateX});
    -webkit-transform: translateX(${(props) => props.translateX});
    transform: translateX(${(props) => props.translateX});
    cursor: ${(props) => props.cursor};
    transition: 300ms ease-in-out;
    @media (max-width: 1314px) {
      font-size: ${(props) => props.fontSizeResp};
    }
    @media (max-width: 1314px) {
      font-size: ${(props) => props.fontSizeRespMid};
    }
    &:hover {
      background-color: ${(props) => props.bgcolorhover};
      color: ${(props) => props.colorhover};
      -moz-transform: scale(${(props) => props.scale});
      -ms-transform: scale(${(props) => props.scale});
      -webkit-transform: scale(${(props) => props.scale});
      transform: scale(${(props) => props.scale});
    }
  `;
  return <TextStyled {...rest}>{children}</TextStyled>;
};

export default TextStyledComp;
