import React from "react";
import styled from "styled-components";
import "./Loading.css";

const LoadingAnim = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: ${(props) => props.borderSize} solid;
  border-color: #80b6fde8;
  border-top-color: #e0ecfce8;
  @media (max-height: 800px) {
    width: ${(props) => props.widthResp};
    height: ${(props) => props.heightResp};
  }
`;

const Loading = ({ ...rest }) => {
  return <LoadingAnim className="Loading" {...rest}></LoadingAnim>;
};

export default Loading;
