/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import Container from "./Container50";
import TextStyledComp from "./TextStyled";

const ButtonPaginate = styled.button`
  height: 35px;
  width: 35px;
  font-size: 14px;
  border-radius: 5px;
  background-color: ${(props) => props.bgcolor};
  color: white;
  transition: 100ms ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-height: 800px) {
    height: 25px;
    width: 25px;
    font-size: 13px;
  }
`;

const Pagination = ({ current_page, lastPage, setPage, page, loading }) => {
  let pages = [
    +page - 1 < 1
      ? 1
      : +page == lastPage
      ? +page - 2 != 0
        ? +page - 2
        : 1
      : +page - 1,
    +page - 1 < 1
      ? +page + 1
      : +page == lastPage
      ? +page - 1 != 1
        ? +page - 1
        : +page
      : +page,
    +page + 1 == 2 ? +page + 2 : +page == lastPage ? +page : +page + 1,
  ];
  pages.length = lastPage ? (lastPage <= 3 ? lastPage : 3) : 1;
  return (
    <Container style={{ padding: "2rem 2rem" }}>
      <Container
        fontSize={"15px"}
        fontSizeResp={"12px"}
        style={{ display: "flex", gap: "8px", alignItems: "center" }}
      >
        <TextStyledComp
          tag="a"
          onClick={() => setPage((e) => (e == 1 ? 1 : e - 1))}
        >
          <i className="fa-solid fa-angles-left"></i> Anterior
        </TextStyledComp>
        {+page >= 3 && lastPage > 3 && (
          <div style={{ display: "flex", gap: "10px" }}>
            <ButtonPaginate
              bgcolor={1 == +page ? "#2684FF" : "#AAA"}
              onClick={(e) => {
                setPage(e.target.innerHTML);
              }}
            >
              1
            </ButtonPaginate>
            ...
          </div>
        )}
        {pages.map((item) => {
          return (
            <ButtonPaginate
              bgcolor={item == +page ? "#2684FF" : "#AAA"}
              onClick={(e) => {
                setPage(e.target.innerHTML);
              }}
              key={item}
            >
              {item}
            </ButtonPaginate>
          );
        })}
        {+page < lastPage - 1 && lastPage > 3 && (
          <div style={{ display: "flex", gap: "10px" }}>
            ...
            <ButtonPaginate
              bgcolor={lastPage == +page ? "#2684FF" : "#AAA"}
              onClick={(e) => {
                setPage(e.target.innerHTML);
              }}
            >
              {lastPage}
            </ButtonPaginate>
          </div>
        )}
        <TextStyledComp
          tag="a"
          onClick={() =>
            setPage((e) => (e == lastPage ? lastPage : Number(+e + 1)))
          }
        >
          Próxima <i className="fa-solid fa-angles-right"></i>
        </TextStyledComp>
      </Container>
    </Container>
  );
};

export default Pagination;
