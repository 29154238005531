import React from 'react';

const OAuth = () => {
  function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  React.useEffect(() => {
    const query = {
      client_id: process.env.REACT_APP_CLIENTE_ID,
      redirect_uri: process.env.REACT_APP_REDIRECT_URL,
      response_type: "code",
      scope: "",
      state: makeid(40),
    };
    const url = `https://sisged.seed.ap.gov.br/oauth/authorize?client_id=${query.client_id}&redirect_uri=${query.redirect_uri}&response_type=${query.response_type}&scope=${query.scope}&state=${query.state}`;

    window.location.assign(url);
  }, []);

  return (
    <div />
  );
};

export default OAuth;
