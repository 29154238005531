/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import * as React from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  object,
  string,
  number,
  date,
  email,
  array,
  ValidationError,
} from "yup";
import "./style.css";
import { PageContext } from "../../context/pageContext";

import Container from "../Container50";
import TextStyledComp from "../TextStyled";
import Text from "../Text";
import {
  Input,
  Select,
  DatePicker,
  TimePicker,
  InputMask,
  Texterea,
  FileInput,
} from "../Form/index";
import { Form } from "@unform/web";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Loading from "../animations/loading/index";
import api from "../../services/api";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Context } from "../../context/loginContext";
import { render } from "@testing-library/react";

const ButtonModal = styled.button`
  display: ${(props) => props.display};
  color: ${(props) => props.color};
  place-items: center;
  background-color: ${(props) => props.bgcolor};
  font-size: ${(props) => props.fontSize};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  justify-self: ${(props) => props.justify};
  border-radius: 3px;
  transition: 100ms ease-in-out;
  &:hover {
    color: ${(props) => props.colorhover};
    background-color: ${(props) => props.bgcolorhover};
  }
  @media (max-height: 800px) {
    width: ${(props) => props.widthResp};
    height: ${(props) => props.heightResp};
    font-size: ${(props) => props.fontSizeResp};
  }
`;
const ButtonStyled = styled.button`
  font-size: ${(props) => props.fontSize};
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  padding: ${(props) => props.padding};
  border-radius: 3px;
  &:hover {
    background-color: #26f;
  }
`;
const ModalStyled = styled(Modal)`
  position: "absolute";
  background-color: rgba(0, 0, 0, 0.65);
`;
const BoxStyle = styled(Box)`
  display: grid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-items: center;
  width: 550px;
  height: 80vh;
  max-height: 80vh;
  overflow: scroll;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 2rem;
`;

export default function ModalRegisterEvent({
  intentionModal,
  options,
  updateData,
  colors,
}) {
  let LoginPagePermissions = React.useContext(Context);
  let id_school = LoginPagePermissions.dataSchool.data.userLogin.id;
  let name_school = LoginPagePermissions.dataSchool.data.userLogin.nameSchool;
  let number_school = LoginPagePermissions.dataSchool.data.userLogin.telephone;
  let email_school = LoginPagePermissions.dataSchool.data.userLogin.email;
  let [loading, setLoading] = React.useState(false);
  //Abrir e fechar modal
  const [open, setOpen] = React.useState(false);
  //Referencia ao formulario do unform
  const formRef = React.useRef(null);
  //Dados dos options do Select
  const { breed, partners, series_taken, events, progress, sex } = options;
  //Style do menu de options do Select
  const customStyles = {
    menuList: (provided, state) => ({
      ...provided,
      height: "150px",
      overflow: "none",
      backgroundColor: colors?.background || "#fff",
      color: colors?.text || "#FFF",
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: colors?.text || "#FFF",
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: colors?.background || "#fff",
      color: colors?.text || "#FFF",
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isFocused ? colors?.container : colors.background,
      color: colors?.text || "#FFF",
    }),
  };

  const StyleInputMask = {
    width: "98%",
    border: "1px #CCC solid",
    borderRadius: "4px",
    height: "36px",
    paddingLeft: "1%",
    backgroundColor: colors.background,
    color: colors.text,
  };

  async function handlerSubmit(data) {
    setLoading(true);
    data.progress = Array.isArray(data.progress)
      ? data.progress.join(", ")
      : data.progress;

    console.log(data)

    async function submitData() {
      setOpen(false);
      var formdata = new FormData();

      formdata.append("name_assaulted", data.name_assaulted);
      formdata.append("responsible_assaulted", data.responsible_assaulted);
      formdata.append(
        "responsible_assaulted_cpf",
        data.responsible_assaulted_cpf
      );
      formdata.append("date_birth_assaulted", data.date_birth_assaulted);
      formdata.append("sex_assaulted", data.sex_assaulted);
      formdata.append("breeding_assauted", data.breeding_assauted);
      formdata.append("disability_assaulted", data.disability_assaulted);
      formdata.append("series_taken_assaulted", data.series_taken_assaulted);
      formdata.append("name_aggressor", data.name_aggressor);
      formdata.append("responsible_aggressor", data.responsible_aggressor);
      formdata.append(
        "responsible_aggressor_cpf",
        data.responsible_aggressor_cpf
      );
      formdata.append("date_birth_aggressor", data.date_birth_aggressor);
      formdata.append("sex_aggressor", data.sex_aggressor);
      formdata.append("breeding_aggressor", data.breeding_aggressor);
      formdata.append("disability_aggressor", data.disability_aggressor);
      formdata.append("series_taken_aggressor", data.series_taken_aggressor);
      formdata.append("id_school", data.id_school);
      formdata.append("telephone", data.telephone);
      formdata.append("email", data.email);
      formdata.append("date_occurrence", data.date_occurrence);
      formdata.append("time_occurrence", data.time_occurrence);
      formdata.append("progress", data.progress);
      formdata.append("progress_text", data.progress_text);
      formdata.append("event", data.event);
      formdata.append("event_text", data.event_text);

      var myHeaders = new Headers();

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      let reqOptions = {
        url: `${process.env.REACT_APP_API_URL}/occurrence`,
        method: "POST",
        headers: requestOptions.myHeaders,
        data: formdata,
      };

      let response;
      await toast
        .promise(axios.request(reqOptions), {
          pending: {
            render() {
              return "Cadastrando Ocorrência";
            },
          },
          success: "Cadastrado com sucesso",
          error: {
            render({ data }) {
              return data?.response?.data?.message || data?.message;
            },
          },
        })
        .then((responseRequest) => {
          setLoading(false);
          response = responseRequest;
          vinculationPartner();
          vinculationStatus();
          if (data.file_partner.length > 0) {
            vinculationReportPartner();
          }
        })
        .catch(() => {
          setLoading(false);
        });
      // Código testando requisição de vinculação de ocorrencia ao parceiro

      let responsePartner = null;

      async function vinculationPartner() {
        let partnersRequested =
          typeof data.requested_partner == "object"
            ? data.requested_partner
            : [data.requested_partner];

        partnersRequested.forEach(async (item) => {
          let headersList = {
            Accept: "*/*",
            "Content-Type": "application/json",
          };

          let body = {
            id_occurrence: response.data.id,
            id_partner: item,
          };

          var raw = JSON.stringify(body);

          var requestOptions = {
            url: `${process.env.REACT_APP_API_URL}/occurrencePartner`,
            method: "POST",
            headers: headersList,
            data: raw,
          };

          responsePartner = await axios.request(requestOptions);
        });
      }

      // Código de vinculação do status á ocorrência
      let responseStatus;

      async function vinculationStatus() {
        let headersList = {
          Accept: "*/*",
          "Content-Type": "application/json",
        };

        let body = {
          id_occurrence: response.data.id,
          not_completed: "Ocorrência criada",
          difficulty_completion: "",
          completed: "",
          status: "Não Concluído",
          description: "",
        };

        let raw = JSON.stringify(body);

        var requestOptions = {
          url: `${process.env.REACT_APP_API_URL}/status`,
          method: "POST",
          headers: headersList,
          data: raw,
        };

        responseStatus = await axios.request(requestOptions);
      }

      // Código de vinculação do arquivo do reportPartner á ocorrência
      async function vinculationReportPartner() {
        const formdataPartner = new FormData();

        formdataPartner.append("id_occurrence", response?.data?.id);
        formdataPartner.append(
          "file",
          data?.file_partner[0],
          data?.file_partner[0]?.name
        );
        console.log("pass file_partner");

        let requestOptionsReport = {
          method: "POST",
          body: formdataPartner,
          redirect: "follow",
        };

        await toast
          .promise(
            fetch(
              `${process.env.REACT_APP_API_URL}/reportPartner`,
              requestOptionsReport
            ),
            {
              pending: "Cadastrando Relatório Parceiro",
              success: "Cadastrado com sucesso",
              error: "Não foi possivel efetuar o cadastro",
            }
          )
          .catch();
      }
      updateData();
    }

    try {
      const schema = object({
        // Validação de raça
        breeding_assauted: string()
          .required("Selecione a raça")
          .min(3, "Digite no minimo 3 caracteres"),

        // Validação de data e hora
        date_birth_assaulted: string().required("Digite a data de nascimento"),
        date_occurrence: string().required("Digite a data da ocorrência"),
        time_occurrence: string().required("Digite a hora da ocorrência"),

        // Validação de email e número
        email: string()
          .email("Digite um email valido")
          .min(4, "No minimo 4 caracteres")
          .required("É obrigatorio o email"),
        telephone: string().required("Digite o Telefone"),

        // Validação de texto
        event: string().required("É preciso selecionar um tipo de evento"),
        event_text: string().required(
          "Necessario o preechimento de detalhes do evento"
        ),
        name_assaulted: string().required("Preencha o nome"),

        progress: string().required("Selecione o encaminhamento"),
        progress_text: string().required("Dê detalhes sobre o encaminhamento"),

        series_taken_assaulted: string().required("Selecione uma opção"),

        sex_assaulted: string().required("Selecione um gênero"),
      });

      await schema
        .validate(data)
        .then(() => submitData())
        .catch(() => null);
      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      if (err instanceof ValidationError) {
        const errorMensages = {};

        err.inner.forEach((error) => {
          errorMensages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMensages);
      }
    }
  }

  return (
    <React.Fragment>
      <ButtonModal
        bgcolor={colors.secundary}
        color={"white"}
        height={"40px"}
        heightResp={"28px"}
        // width={"170px"}
        fontSize={"1rem"}
        fontSizeResp={"14px"}
        bgcolorhover={"#36B37E"}
        style={{
          cursor: "pointer",
          display: "flex",
          alignContent: "end",
          fontWeight: "500",
          gap: "5px",
          paddingLeft: "8px",
          padding: "15px",
          borderRadius: "8px",
        }}
        onClick={() => setOpen((s) => !s)}
      >
        <i className="fa-solid fa-plus" style={{ fontSize: "15px" }} />
        Cadastrar ocorrência
      </ButtonModal>
      <Dialog
        header={intentionModal}
        visible={open}
        headerStyle={{ backgroundColor: colors.container, color: colors.text }}
        contentStyle={{
          backgroundColor: `${colors.container}`,
        }}
        onHide={() => setOpen(false)}
        contentClassName="Modal-register-event"
      >
        <div className="content-modal">
          <Container mb="1rem" width="98%" borderB="4px #CCC solid"></Container>
          <Container
            style={{ display: "grid", gap: "10px", paddingTop: "1rem" }}
          >
            <Form
              ref={formRef}
              style={{ alignSelf: "start" }}
              className={"fomRegisterEvent"}
              onSubmit={handlerSubmit}
            >
              <TextStyledComp
                fontSizeResp={"1.1rem"}
                fontSize={"1.25rem"}
                tag="h3"
                style={{
                  borderBottom: "4px #2684FF solid",
                  paddingBottom: ".5rem",
                  color: colors.text,
                }}
              >
                Envolvido 1
              </TextStyledComp>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Nome
                </Text>
                <Input
                  bgColor={colors.background}
                  color={colors.text}
                  height="40px"
                  fontSize="15px"
                  name="name_assaulted"
                  placeholder="Nome do agredido"
                />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Nome do responsável
                </Text>
                <Input
                  bgColor={colors.background}
                  color={colors.text}
                  height="40px"
                  fontSize="15px"
                  name="responsible_assaulted"
                  placeholder="Nome do responsável"
                />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  CPF do responsável
                </Text>
                <InputMask
                  mask="999.999.999-99"
                  style={{
                    backgroundColor: colors.background,
                    color: colors.text,
                  }}
                  height="40px"
                  fontSize="15px"
                  name="responsible_assaulted_cpf"
                  placeholder="CPF do responsável"
                />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Data de nascimento
                </Text>
                <br></br>
                <DatePicker colors={colors} name="date_birth_assaulted" />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Gênero
                </Text>
                <br></br>
                <Select
                  name="sex_assaulted"
                  colors={colors}
                  placeholder="Selecione o sexo"
                  options={sex}
                />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Raça
                </Text>
                <br></br>
                <Select
                  name="breeding_assauted"
                  colors={colors}
                  width={"100%"}
                  height={"36px"}
                  fontSize={".9rem"}
                  options={breed}
                />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Deficiência (se houver)
                </Text>
                <br></br>
                <Input
                  bgColor={colors.background}
                  color={colors.text}
                  height="40px"
                  fontSize="15px"
                  name="disability_assaulted"
                  placeholder={"Se houver"}
                  type={"text"}
                />
              </div>
              <div style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Serie cursada
                </Text>
                <br></br>
                <Select
                  name="series_taken_assaulted"
                  colors={colors}
                  width={"100%"}
                  height={"36px"}
                  fontSize={".9rem"}
                  options={series_taken}
                />
              </div>

              <TextStyledComp
                tag="h3"
                fontSizeResp={"1.1rem"}
                fontSize={"1.25rem"}
                style={{
                  borderBottom: "4px #A30D11 solid",
                  paddingBottom: ".5rem",
                  marginTop: "1rem",
                  color: colors.text,
                }}
              >
                Envolvido 2
              </TextStyledComp>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Nome
                </Text>
                <Input
                  bgColor={colors.background}
                  color={colors.text}
                  height="40px"
                  fontSize="15px"
                  name="name_aggressor"
                  placeholder="Nome do agredido"
                />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Nome do responsável
                </Text>
                <Input
                  bgColor={colors.background}
                  color={colors.text}
                  height="40px"
                  fontSize="15px"
                  name="responsible_aggressor"
                  placeholder="Nome do responsável"
                />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  CPF do responsável
                </Text>
                <InputMask
                  mask="999.999.999-99"
                  style={{
                    backgroundColor: colors.background,
                    color: colors.text,
                  }}
                  height="40px"
                  fontSize="15px"
                  name="responsible_aggressor_cpf"
                  placeholder="CPF do responsável"
                />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Data de nascimento
                </Text>
                <br></br>
                <DatePicker
                  colors={colors}
                  name="date_birth_aggressor"
                  id="dt"
                />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Gênero
                </Text>
                <br></br>
                <Select
                  colors={colors}
                  name="sex_aggressor"
                  width={"100%"}
                  height={"36px"}
                  fontSize={".9rem"}
                  options={sex}
                />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Raça
                </Text>
                <br></br>
                <Select
                  colors={colors}
                  name="breeding_aggressor"
                  width={"100%"}
                  height={"36px"}
                  fontSize={".9rem"}
                  options={breed}
                />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Deficiência (se houver)
                </Text>
                <br></br>
                <Input
                  bgColor={colors.background}
                  color={colors.text}
                  height="40px"
                  fontSize="15px"
                  name="disability_aggressor"
                  placeholder={"Se houver"}
                  type={"text"}
                />
              </div>
              <div style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Serie cursada
                </Text>
                <br></br>
                <Select
                  colors={colors}
                  name="series_taken_aggressor"
                  width={"100%"}
                  height={"36px"}
                  fontSize={".9rem"}
                  options={series_taken}
                />
              </div>

              <TextStyledComp
                tag="h3"
                fontSizeResp={"1.1rem"}
                fontSize={"1.25rem"}
                style={{
                  borderBottom: "4px #36B37E solid",
                  paddingBottom: ".5rem",
                  marginTop: "1rem",
                  color: colors.text,
                }}
              >
                Dados da ocorrência
              </TextStyledComp>
              <div style={{ marginTop: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Escola
                </Text>
                <Input
                  bgColor={colors.background}
                  color={colors.text}
                  height="40px"
                  fontSize="15px"
                  name="fullNameSchool"
                  disabled
                  defaultValue={name_school}
                  type={"text"}
                  placeholder="Nome da escola"
                />
                <Input name="id_school" type={"hidden"} value={id_school} />
              </div>
              <div
                style={{
                  display: "grid",
                  gap: "0.5rem",
                  marginTop: "1rem",
                }}
              >
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Contatos
                </Text>
                <InputMask
                  style={StyleInputMask}
                  mask="(99) 9 9999-9999"
                  name="telephone"
                  disabled
                  value={number_school}
                  placeholder="(00) 0 0000-0000"
                />
                <Input
                  bgColor={colors.background}
                  color={colors.text}
                  disabled
                  height="40px"
                  fontSize="15px"
                  name="email"
                  defaultValue={email_school}
                  type={"email"}
                  placeholder="Email de contato"
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gap: "0.5rem",
                  marginTop: "1rem",
                }}
              >
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Data e Hora da Ocorrência
                </Text>
                <DatePicker colors={colors} name="date_occurrence" />
                <TimePicker colors={colors} name="time_occurrence" />
              </div>
              <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Parceiro solicitado
                </Text>
                <br></br>
                <Select
                  colors={colors}
                  name="requested_partner"
                  isMulti
                  options={partners}
                />
                <FileInput
                  name="file_partner"
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    border: "none",
                    color: colors.text,
                  }}
                  type="file"
                  accept=".pdf"
                />
              </div>
              <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Encaminhamento
                </Text>
                <br></br>
                <Select
                  colors={colors}
                  name="progress"
                  isMulti
                  options={progress}
                />
                <Texterea
                  colors={colors}
                  name="progress_text"
                  placeholder={"Descrição sobre o encaminhamento"}
                />
              </div>
              <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                <Text
                  tag="label"
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color: colors.text,
                  }}
                >
                  Evento
                </Text>
                <br></br>
                <Select name="event" styles={customStyles} options={events} />
                <Texterea
                  colors={colors}
                  name="event_text"
                  placeholder={"Descrição do evento"}
                />
              </div>
              <div
                style={{
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <ButtonModal
                  justify={"end"}
                  bgcolor={"#EFEFEF"}
                  color={"#222"}
                  bgcolorhover={"#0065FF"}
                  fontSize={"1rem"}
                  colorhover={"#FFF"}
                  width="80px"
                  style={{ borderRadius: "8px", cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(false);
                  }}
                >
                  Voltar
                </ButtonModal>
                <ButtonModal
                  type="submit"
                  display={"grid"}
                  justify={"start"}
                  bgcolor={"#36B37E"}
                  color={"white"}
                  height={"35px"}
                  heightResp={"30px"}
                  style={{ borderRadius: "8px", cursor: "pointer" }}
                  width={"150px"}
                  fontSize={"1rem"}
                  bgcolorhover={"#008453"}
                >
                  Cadastrar
                </ButtonModal>
              </div>
            </Form>
          </Container>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
