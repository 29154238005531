/* eslint-disable no-unused-vars */
import React, { StrictMode, useContext } from "react";
import { ThemeContext } from "styled-components";
import Container from "./Container50";
import { DataTableFilterDemo } from "./Table/TableTest";

const Relatorio = () => {
  const { colors } = useContext(ThemeContext);
  return (
    <StrictMode>
      <Container
        zIndex={"0"}
        width={"100%"}
        height={"90vh"}
        position={"relative"}
        bgcolor={"#f7f7f7"}
      >
        <Container
          justifyItens={"center"}
          height={"90vh"}
          width="100%"
          templateRows={"100px 1fr"}
          bgcolor={colors.background}
        >
          <Container height={"90vh"} width={"100%"}>
            <Container
              style={{
                boxShadow: "5px 7px 7px 2px rgba(0,0,0,0.1)",
                padding: ".5rem",
                overflow: "scroll",
              }}
              width={"95%"}
              height={"98%"}
              justify={"center"}
              align={"start"}
              bgcolor={colors.container}
              alignItems={"start"}
              templateRows="8vh 1fr"
            >
              <DataTableFilterDemo />
            </Container>
          </Container>
        </Container>
      </Container>
    </StrictMode>
  );
};

export default Relatorio;
