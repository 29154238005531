/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Container from "./Container50";
import Loading from "./animations/loading";
import TextStyledComp from "./TextStyled";
import styled from "styled-components";
import { Dialog } from "primereact/dialog";

const ModalStyled = styled(Modal)`
  position: "absolute";
  background-color: rgba(0, 0, 0, 0.15);
  overflow: scroll;
`;

const BoxStyle = styled(Box)`
  display: grid;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  height: ${(props) => props.height};
  max-height: 800px;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 2rem;
  overflow: ${(props) => props.overflow};
  transition: 500ms ease-in-out;
`;

const ModalReset = (props) => {
  return (
    <Dialog
      visible={props.changePassword}
      onHide={() => props.setChangePassword(false)}
      header="Senha nova:"
    >
      <div style={{ minWidth: "340px", width: "30vw", maxWidth: "500px" }}>
        <Container>
          <Container gap="10px">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TextStyledComp
                fontSize="18px"
                style={{
                  backgroundColor: "#EEE",
                  border: "2px #CCC solid",
                  width: "60%",
                  height: "50px",
                  display: "grid",
                  placeItems: "center",
                  borderRadius: "5px",
                }}
              >
                {props.newPassword ? props.newPassword : "erro"}
              </TextStyledComp>
            </div>
          </Container>
        </Container>
      </div>
    </Dialog>
  );
};

export default React.memo(ModalReset);
