import React, { useContext } from "react";
import api from "../../services/api";

import { Context } from "../../context/loginContext";

import history from "../../history";

const Callback = () => {
  const LoginPagePermissions = useContext(Context);

  function handlerSaveData(data) {
    LoginPagePermissions.Login == "escola"
      ? LoginPagePermissions.setDataSchool(data)
      : null;
    LoginPagePermissions.Login == "escola"
      ? localStorage.setItem("dataUserSchool", JSON.stringify(data))
      : null;
    LoginPagePermissions.Login == "usuarios"
      ? LoginPagePermissions.setDataServerLogin(data)
      : null;
    LoginPagePermissions.Login == "usuarios"
      ? localStorage.setItem("dataServerLogin", JSON.stringify(data))
      : null;
    LoginPagePermissions.setAdmin(data.data.userLogin.isAdmin);
    setTimeout(function () {
      history.push("/user/dashboard");
    }, 1000);
  }

  function getUserSSO(accessToken) {
    api
      .get("https://sisged.seed.ap.gov.br/api/user", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        api.post("oauth", res.data).then((response) => {
          localStorage.setItem("auth", true);
          localStorage.setItem("@epaz:auth", "oauth");
          handlerSaveData(response);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }

  React.useEffect(() => {
    function callback() {
      api
        .post(`https://sisged.seed.ap.gov.br/oauth/token`, {
          grant_type: "authorization_code",
          client_id: process.env.REACT_APP_CLIENTE_ID,
          client_secret: process.env.REACT_APP_CLIENTE_SECRET,
          redirect_uri: process.env.REACT_APP_REDIRECT_URL,
          code: getQueryVariable("code"),
        })
        .then((res) => {
          localStorage.setItem("@epaz:access_token", res.data.access_token);
          LoginPagePermissions.setLogin("usuarios");
          localStorage.setItem("TypeLogin", "usuarios");
          LoginPagePermissions.setAuth(true);
          getUserSSO(res.data.access_token);
        })
        .catch((e) => console.log(e));
    }

    callback();
  }, []);

  return <div />;
};

export default Callback;
