/* eslint-disable react/prop-types */
import React from "react";

const Text = ({ tag, style, children, className }) => {
  const Tag = `${tag}`;
  return (
    <Tag className={className} style={style}>
      {children}
    </Tag>
  );
};

export default Text;
