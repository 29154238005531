/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";

import Container from "../components/Container50";
import Dashboard from "../components/Dashboard";
import HeaderDynamic from "../components/HeaderDynamic";
import SideBar from "../components/SideBar";
import { PageContext } from "../context/pageContext";
import Servidores from "../components/Servidores";
import School from "../components/School";
import Parceiros from "../components/Parceiros";
import NotAcess from "../components/NotAcess";
import Configuraçoes from "../components/Configurações";
import Relatorio from "../components/Relatorio";

const DynamicPage = ({ toggleTheme }) => {
  const { active } = React.useContext(PageContext);
  const [activeSide, setActiveSide] = React.useState(false);

  return (
    <Container
      style={{ display: "flex", overflow: "none", height: "100vh" }}
      // templateColumns={"17vw 1fr"} width={"100vw"} height={"100vh"}
    >
      <Container height={"100%"} left={"0"}>
        <SideBar
          active={active}
          activeSide={activeSide}
          setActiveSide={setActiveSide}
        />
      </Container>
      <div
        style={{
          gridTemplateRows: "11vh 1fr",
          display: "grid",
          width: "100%",
          height: "100vh",
          overflow: "scroll",
        }}
      >
        <HeaderDynamic
          setActiveSide={setActiveSide}
          active={active}
          toggleTheme={toggleTheme}
        />
        {active && active.section === "dashboard" ? <Dashboard /> : null}
        {/* {active && active.section === "servidores" ? <Servidores /> : null} */}
        {active && active.section === "escola" ? <School /> : null}
        {active && active.section === "parceiros" ? <Parceiros /> : null}
        {active && active.section === "configuraçoes" ? (
          <Configuraçoes />
        ) : null}
        {active && active.section === "relatorios" ? <Relatorio /> : null}
        {window.location.pathname === "/user/notacess" ? <NotAcess /> : null}
      </div>
    </Container>
  );
};

export default DynamicPage;
